import React, { useState, useContext, useEffect, useCallback, useLayoutEffect } from "react";
import StudentNav from "../Navbars/StudentNav";
import AdminNav from "../Navbars/AdminNav";
import TeacherNav from "../Navbars/TeacherNav";
import GuestNav from "../Navbars/GuestNav";
import { UserContext } from "../UserContext";
import Footer from "../Footer";
import { AiOutlineCloudUpload } from "react-icons/ai";
import "./ActivitiesAndProjects.css";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom/dist/umd/react-router-dom.development';

import puzzleicon from "./ActivityIcons/AP_ModuleProjectLesson.png";
import videoicon from "./ActivityIcons/AP_VideoIcon.png";
import brainicon from "./ActivityIcons/AP_Brainicon.png";
import SpeedQuiz from "./ActivityIcons/Speedicon.png";
import oralicon from "./ActivityIcons/AP_Oralicon.png";
import writtenicon from "./ActivityIcons/AP_writtenicon.png";
import axiosInstance from "../ManageContent/managecontentforms/axiosInstance";
import { useNavigate } from "react-router-dom";
import ModuleProjects from "./ModuleProjects";
import LessonProjects from "./LessonProjects";
import { toast } from "react-toastify";
import ActivityList from "./ActivityList";

const isDataEqual = (oldData, newData) => {
  // Compare projects
  const projectsEqual = JSON.stringify(oldData.projects) === JSON.stringify(newData.projects);
  // Compare lesson projects
  const lessonProjectsEqual = JSON.stringify(oldData.lessonProjects) === JSON.stringify(newData.lessonProjects);
  // Compare activities
  const activitiesEqual = JSON.stringify(oldData.activities) === JSON.stringify(newData.activities);

  return projectsEqual && lessonProjectsEqual && activitiesEqual;
};

const ActivitiesAndProjects = () => {
  const [activityId, setActivityId] = useState(null);
  const [courseID, setCourseID] = useState(null);
  const [studentId, setStudentId] = useState(null);
  const [projects, setProjects] = useState([]);
  const [lessonProjects, setLessonProjects] = useState([]);
  const [activities, setActivities] = useState({});
  const [profileData, setProfileData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [polling, setPolling] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const { courseId } = useParams();
  const navigate = useNavigate();

  const {
    isAdmin,
    isTeacher,
    isStudent,
    isGuest,
    setUserRole,
    setUserInfo,
    userInfo,
  } = useContext(UserContext);

  const fetchProfileData = useCallback(async (studentId) => {
    try {
      const response = await axiosInstance.get(
        `/getLessonActivityProfileData/${studentId}/${courseId}`,
        { withCredentials: true }
      );
      setProfileData(response.data?.data || {});
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  }, [courseId]);

  const fetchUserData = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/", { withCredentials: true });
      const user = response.data;
      setUserInfo(user);

      const studentResponse = await axiosInstance.post("/getStudentIDfromUser", {
        UserId: user.User_id,
      });
      const studentId = studentResponse.data[0]?.Student_id || null;
      setStudentId(studentId);

      if (studentId) {
        await fetchProfileData(studentId);

        const activityResponse = await axiosInstance.post("/getActivityIDfromStudent", { StudentId: studentId });
        const activityId = activityResponse.data[0]?.Activity_id || null;
        setActivityId(activityId);

        if (activityId) {
          const courseResponse = await axiosInstance.post("/getCourseIDfromActivityID", { ActivityID: activityId });
          setCourseID(courseResponse.data[0]?.Course_id);
        }

        const [projectsData, lessonProjectsData, activityData] = await Promise.all([
          axiosInstance.post("/getStudentModuleProjectAssignment", { studentId, CourseID: courseId }),
          axiosInstance.post("/getStudentLessonProjectAssignment", { studentId, CourseID: courseId }),
          axiosInstance.post("/getStudentLessonActivity", { studentId, CourseID: courseId }),
        ]);

        setProjects(projectsData.data?.data || []);
        setLessonProjects(lessonProjectsData.data || []);
        organizeActivities(activityData.data?.data || []);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }, [courseId, fetchProfileData]);

  const organizeActivities = (data) => {
    const organized = {
      Video: [],
      Game: [],
      Mental: [],
      Oral: [],
      Written: [],
          "Speed Quiz": [],
      MCQ: [],
    };
    data.forEach((activity) => {
      if (organized[activity.ActivityType]) {
        organized[activity.ActivityType].push({ activity });
      }
    });
    setActivities(organized);
  };

  const handleLogout = async () => {
    try {
      await axiosInstance.post("/logout", {}, { withCredentials: true });
      setUserInfo(null);
      setUserRole("guest");
      navigate("/");
      } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const fetchLatestData = useCallback(async () => {
    setIsRefreshing(true);
    try {
      const [projectsData, lessonProjectsData, activityData] = await Promise.all([
        axiosInstance.post("/getStudentModuleProjectAssignment", { studentId, CourseID: courseId }),
        axiosInstance.post("/getStudentLessonProjectAssignment", { studentId, CourseID: courseId }),
        axiosInstance.post("/getStudentLessonActivity", { studentId, CourseID: courseId }),
      ]);

      // Create new data object
      const newData = {
        projects: projectsData.data?.data || [],
        lessonProjects: lessonProjectsData.data || [],
        activities: activityData.data?.data || []
      };

      // Create current data object
      const currentData = {
        projects,
        lessonProjects,
        activities: Object.values(activities).flat().map(item => item.activity)
      };

      // Only update if data has changed
      if (!isDataEqual(currentData, newData)) {
        setProjects(newData.projects);
        setLessonProjects(newData.lessonProjects);
        organizeActivities(newData.activities);
      }
    } catch (error) {
      console.error("Error fetching latest data:", error);
    } finally {
      setIsRefreshing(false);
    }
  }, [studentId, courseId, projects, lessonProjects, activities]);

  useEffect(() => {
    // Only set up polling if we have required data
    if (!studentId || !courseId) {
      return;
    }

    const handleMessage = (event) => {
      if (event.data === 'activityCompleted') {
        fetchLatestData();
      }
    };

    window.addEventListener('message', handleMessage);

    // Clear any existing interval before setting a new one
    if (polling) {
      clearInterval(polling);
    }

    // Set up new polling interval
    const newPollInterval = setInterval(fetchLatestData, 10000);
    setPolling(newPollInterval);

    // Cleanup function
    return () => {
      window.removeEventListener('message', handleMessage);
      if (newPollInterval) {
        clearInterval(newPollInterval);
      }
    };
  }, [studentId, courseId]); // Only re-run when studentId or courseId changes

  useLayoutEffect(() => {
    fetchUserData();
  }, [fetchUserData, refresh]);

  const renderNavBar = () => {
    if (isAdmin) return <AdminNav username={userInfo?.username} avatar={userInfo?.avatar} onLogout={handleLogout} />;
    if (isTeacher) return <TeacherNav username={userInfo?.username} avatar={userInfo?.avatar} onLogout={handleLogout} />;
    if (isStudent) return <StudentNav username={userInfo?.username} avatar={userInfo?.avatar} onLogout={handleLogout} />;
    if (isGuest) return <GuestNav onLogin={() => setUserRole("guest")} />;
    return null;
  };

  return (
    <>
      {/* {renderNavBar()} */}
      {
        userInfo.usertype == "Student" ? <StudentNav username={userInfo?.username} avatar={userInfo?.avatar} onLogout={handleLogout} /> :
          userInfo.usertype == "Teacher" ? <TeacherNav username={userInfo?.username} avatar={userInfo?.avatar} onLogout={handleLogout} /> :
            userInfo.usertype == "Admin" ? <AdminNav username={userInfo?.username} avatar={userInfo?.avatar} onLogout={handleLogout} /> :
              <GuestNav onLogin={() => setUserRole("guest")} />
      }
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <div className="AP_container">
          {/* <div style={{ display: "flex", alignItems: "center", padding: "30px" }}>
            <img src={userInfo?.avatar} alt="Avatar" style={{ width: "50px", height: "50px", borderRadius: "50%", marginRight: "10px" }} />
            <div>
              {`${userInfo?.firstname} ${userInfo?.lastname} is on ${profileData?.coursename} > ${profileData?.modulename} > ${profileData?.lessonname}`}
            </div>
          </div> */}
          <div style={{ justifyContent: "left", width: "100%" }}>
            <div style={{ fontSize: "16px", fontWeight: "bold", display: "flex", justifyContent: "start", alignItems: "center", height: "auto", color: "rgb(172, 77, 203)", width: "400px", padding: "30px 0 30px 30px" }}>
              <div style={{ marginRight: "10px" }}>
                <img src={userInfo?.avatar} alt="Avatar" style={{ width: "50px", height: "50px", borderRadius: "50%" }} />
              </div>
              <div>{`${userInfo?.firstname} ${userInfo?.lastname} you are on  ${profileData?.coursename}  > ${profileData?.modulename} > ${profileData?.lessonname}`}
              </div>
              
            </div>
          </div>
          {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}> */}
          {projects.length > 0 && (
            <ModuleProjects
              title="Module Project"
              projects={projects}
              textColor="#5665e9"
              backgroundColor="#dae8ff"
              iconBackground="#aec7f0"
              image={puzzleicon}
              activityID={activityId}
              courseID={courseID}
              uploadApiRoute="/api/activitiesandprojects/uploadStudentModuleProject"
              studentid={studentId}
            />
          )}
          {lessonProjects.length > 0 && (
            <LessonProjects
              title="Lesson Project"
              projects={lessonProjects}
              textColor="#5665e9"
              backgroundColor="#dae8ff"
              iconBackground="#aec7f0"
              image={puzzleicon}
              activityID={activityId}
              courseID={courseID}
              studentid={studentId}
              uploadApiRoute="/api/activitiesandprojects/uploadStudentLessonProject"
            />
          )}
          {/* </div> */}
        </div>

        {Object.entries(activities).map(([title, projectList]) =>
          projectList.length > 0 ? (
            <div className="AP_container" key={title}>
            <ActivityList
                title={title}
                projects={projectList}
                textColor={getTextColor(title)}
                backgroundColor={getBackgroundColor(title)}
                iconBackground={getIconBackground(title)}
                image={getImageForTitle(title)}
                activityID={activityId}
                courseID={courseID}
                studentId={studentId}
                setRefresh={setRefresh}
              uploadApiRoute="/api/activitiesandprojects/uploadStudentGameActivity"
                profileData={profileData}
            />
        </div>
          ) : null
        )}
      </div>
      <Footer />
      {/* {isRefreshing && (
        <div style={{
          position: 'fixed',
          top: '20px',
          right: '20px',
          backgroundColor: '#4CAF50',
          color: 'white',
          padding: '8px 16px',
          borderRadius: '4px',
          zIndex: 1000,
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
          animation: 'fadeInOut 0.5s ease-in-out'
        }}>
          <div style={{
            width: '16px',
            height: '16px',
            border: '2px solid #ffffff',
            borderTop: '2px solid transparent',
            borderRadius: '50%',
            animation: 'spin 1s linear infinite'
          }} />
          Refreshing Activities...
        </div>
      )} */}
    </>
  );
};

const getTextColor = (title) => {
  const colors = {
    Video: "rgb(52, 151, 156)",
    Game: "#d9ce23",
    Mental: "#da831e",
    Oral: "#d83162",
    Written: "#ac4dcb",
    "Speed Quiz": "rgb(50, 192, 133)",
    MCQ: "#ac4dcb",
  };
  return colors[title] || "#000";
};

const getBackgroundColor = (title) => {
  const backgrounds = {
    Video: "rgb(205, 243, 245)",
    Game: "#f7fbdb",
    Mental: "#faf0d6",
    Oral: "#feecf2",
    Written: "#e4ddfb",
    "Speed Quiz": "rgb(200, 248, 228)",
    MCQ: "#e4ddfb",
  };
  return backgrounds[title] || "#fff";
};

const getIconBackground = (title) => {
  const iconBackgrounds = {
    Video: "rgb(205, 243, 245)",
    Game: "#f2fcae",
    Mental: "#fae7b4",
    Oral: "#fccada",
    Written: "#d0c2fc",
    "Speed Quiz": "rgb(200, 248, 228)",
    MCQ: "#d0c2fc",
  };
  return iconBackgrounds[title] || "#ccc";
};

const getImageForTitle = (title) => {
  const images = {
    Video: videoicon,
    Game: puzzleicon,
    Mental: brainicon,
    Oral: oralicon,
    Written: writtenicon,
    "Speed Quiz": SpeedQuiz,
    MCQ: brainicon,
  };
  return images[title] || puzzleicon;
};

export default ActivitiesAndProjects;
