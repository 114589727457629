import "./TeacherAttendClass.css";
import React, { useState, useContext, useEffect } from "react";
import AdminNav from "../Navbars/AdminNav";
import StudentNav from "../Navbars/StudentNav";
import GuestNav from "../Navbars/GuestNav";
import Footer from "../Footer";
import { UserContext } from "../UserContext";
import TeacherNav from "../Navbars/TeacherNav";
import puzzleicon from "./ActivityIcons/AP_ModuleProjectLesson.png";
import WrittenActivityImage from "./ActivityIcons/WrittenActivity.png";
import MCQActivityImage from "./ActivityIcons/MCQActivity.png";
import VideoActivityImage from "./ActivityIcons/AP_VideoIcon.png";
import SpeedQuizActivityImage from "./ActivityIcons/SpeedQuiz.png";
import OralActivityImage from "./ActivityIcons/AP_Oralicon.png";
import MentalActivityImage from "./ActivityIcons/MentalActivity.png";
import { toast } from "react-toastify";

import eyeIcon from "./eyeicon.png";
import mental from "./ActivityIcons/MentalActivity.png";
import oral from "./ActivityIcons/OralActivity.png";
import speedquizz from "./ActivityIcons/SpeedQuiz.png";
import mcq from "./ActivityIcons/MCQActivity.png";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import ViewActivityModal from "./ViewActivityModal";

const TeacherAttendClass = ({ selectedCourse }) => {
  const [selectedLesson, setSelectedLesson] = useState("");
  const [selectedModule, setSelectedModule] = useState("");
  const [selectedStudent, setSelectedStudent] = useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedCourseData = JSON?.parse(queryParams.get("selectedCourseData"));
  var selectedClassLink = queryParams?.get("selectedClass");
  if (selectedClassLink?.startsWith("https://")) {
    selectedClassLink = selectedClassLink?.slice(8);
  }
//////////////////////////////////////
let courseData = null;
const selectedClassId = queryParams?.get('selectedClassId')||null;
try {
  const selectedCourseData = queryParams.get('selectedCourseData');
  

  
  courseData = selectedCourseData
    ? JSON.parse(decodeURIComponent(selectedCourseData))
    : null;
} catch (error) {
  console.error("Error parsing selectedCourseData:", error);
}

// Extract `selectedClass`
const selectedClass = queryParams.get('selectedClass');

// Find the matching class and get its Class_id
let classId=0
if(selectedClassId){
  classId=selectedClassId
}else{
  classId = courseData?.classes?.find(
    (cls) => cls.ClassLink === selectedClass
  )?.Class_id
}

// alert(classId)
  //console.log("Selected Course Data: ", selectedCourseData);

  const [courseLessons, setCourseLessons] = useState("");
  const [courseModules, setCourseModules] = useState("");
  const [lessonActivities, setLessonActivities] = useState("");
  const [lessonProjects, setLessonProjects] = useState("");
  const [moduleProjects, setModuleProjects] = useState("");
  const [courseStudents, setCourseStudents] = useState("");
  const [studentProgress, setStudentProgress] = useState("");
  const [selectedLessonteacher, setSelectedLessonTeacher] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const {
    isAdmin,
    isTeacher,
    isStudent,
    isGuest,
    setUserRole,
    setUserInfo,
    userInfo,
  } = useContext(UserContext);
  // const [detailedStudentProgress, setDetailedStudentProgress] = useState('');

  //console.log("Course Object: ", selectedCourseData);
  function fetchCourses() {
    axios
      .get(
        `${process.env.REACT_APP_LOCAL_SERVER_API}/getUserDetailsFromCourseID/${selectedCourseData.Course_id}/${classId}`
      )
      .then((response) => {
        //console.log("Studdents:", response.data.userDetails);
        setCourseStudents(response.data.userDetails);
      })
      .catch((error) => {
        console.error("Error fetching getStudents:", error);
      });
  }
  async function teacherId(User_id) {
    return await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/teacherFromId/${User_id}`).then((res) => {
      res.json().then((data) => {

        axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getUserDetailsFromCourseIDTeacherId/${selectedCourseData.Course_id}/${data?.teacher_id}/${classId}`)
          .then(response => {
            setCourseStudents(response.data.userDetails);
          })
          .catch(error => {
            console.error('Error fetching Students:', error);
          });
        // setUserId(data?.teacher_id);
      })

    })
  }

  // useEffect(() => {
 
  // }, [isAdmin, isTeacher, isStudent, isGuest]);

  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_LOCAL_SERVER_API}`,
          {
            credentials: "include",
          }
        );
        const userInfo = await response.json();
        setUserInfo(userInfo);
        // teacherId(userInfo?.User_id)
        if (userInfo?.usertype) {
          setUserRole(userInfo.usertype);
          if (userInfo.usertype == "Teacher") {
            teacherId(userInfo?.User_id)
          } else {
            fetchCourses()
          }
        }
        console.log("UserInso in take class Teacher: ", userInfo);
        //console.log("Avatar: ", userInfo?.avatar);
      } catch (err) {
        console.log("Error in fetchData:", err);
      }
    };

    fetchData();

    //console.log("Selected Course ID: ", selectedCourseData.Course_id);
    //console.log("Getting all the Lessons against that Course ID");
    axios
      .get(
        `${process.env.REACT_APP_LOCAL_SERVER_API}/getLessonsFromCourseID/${selectedCourseData.Course_id}`
      )
      .then((response) => {
        //console.log(".......Lesssons:", response.data.lessons);
        setCourseLessons(response.data.lessons);
      })
      .catch((error) => {
        console.error("Error fetching getLessonsFromCourseID:", error);
      });

    //Getting all the Modules against that course ID
    //console.log("Getting all the Modules against that course ID");
    axios
      .get(
        `${process.env.REACT_APP_LOCAL_SERVER_API}/getModulesFromCourseID/${selectedCourseData.Course_id}`
      )
      .then((response) => {
        //console.log("Modddules:", response.data.modules);
        setCourseModules(response.data.modules);
      })
      .catch((error) => {
        console.error("Error fetching getLessonsFromCourseID:", error);
      });


  }, [selectedCourse,location.search,selectedCourseData?.Course_id]);

  const [lessonPlanData, setLessonPlanData] = useState("");

  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const [isDialogOpen, setDialogOpen] = useState(false);

  const [selectedLessonActivityID, setSelectedLessonActivityID] =
    useState(null);

  const handleOpenDialog = (lessonActivityID) => {
    setSelectedLessonActivityID(lessonActivityID);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleLessonChange = (e) => {

    axios
      .get(
        `${process.env.REACT_APP_LOCAL_SERVER_API}/getActivitiesFromLessonID/${e.target.value}`
      )
      .then((response) => {
        //console.log("Activities:", response.data.activities);
        setLessonActivities(response.data.activities);
        // setCourseLessons(response.data.lessons);
      })
      .catch((error) => {
        console.error("Error fetching getLessonsFromCourseID:", error);
      });

    //Lesson Project Details
    //console.log("Calling Lesson Project API...");
    axios
      .get(
        `${process.env.REACT_APP_LOCAL_SERVER_API}/getLessonProjectsFromLessonID/${e.target.value}`
      )
      .then((response) => {
        //console.log("Lesson Projects:", response.data.lessonProjects);
        setLessonProjects(response.data.lessonProjects);
        // setCourseLessons(response.data.lessons);
      })
      .catch((error) => {
        console.error("Error fetching getLessonsFromCourseID:", error);
      });

    setSelectedLesson(e.target.value);

    // Get Lesson Plan

    //console.log("Calling the API to get Lesson Plan...");
    //console.log("e.target.value: ", e.target.value);
    axios
      .get(
        `${process.env.REACT_APP_LOCAL_SERVER_API}/getLessonPlan/${e.target.value}`
      )
      .then((response) => {
        //console.log("Lesson Plan response.data:", response.data);
        //console.log("Lesson Plan:", response.data.lessonPlan);
        setLessonPlanData(response.data.lessonPlan);
        // setCourseLessons(response.data.lessons);
      })
      .catch((error) => {
        console.error("Error fetching getLessonsFromCourseID:", error);
      });
  };
  const handleModuleChange = (e) => {
    //console.log("Calling Module Project API...");
    //console.log("Module Project e.target.value:", e.target.value);
    axios
      .get(
        `${process.env.REACT_APP_LOCAL_SERVER_API}/getModuleProjectsFromModuleID/${e.target.value}`
      )
      .then((response) => {
        //console.log("Module Projects:", response.data);
        //console.log("Module Projects:", response.data.moduleProjects);
        setModuleProjects(response.data.moduleProjects);
        // setCourseLessons(response.data.lessons);
      })
      .catch((error) => {
        console.error("Error fetching getLessonsFromCourseID:", error);
      });

    // setSelectedModule(e.target.value);
  };
  const handleStudentChange = (e) => {
    setSelectedStudent(e.target.value);
    axios
      .get(
        `${process.env.REACT_APP_LOCAL_SERVER_API}/getStudentProgressByCourse/${e.target.value}/${selectedCourseData.Course_id}`
      )
      .then((response) => {
        setStudentProgress(response.data.studentProgress);
      })
      .catch((error) => {
        console.error("Error fetching Student Progress:", error);
      });

    // setSelectedStudent(e.target.value);
  };

  const handleCourseSelect = (course) => {
    //console.log("Selected Course in AttendClass:", course);
    selectedCourse(course);
    // setSelectedCourse(course);
  };

  // const handleActivityResultClick = (StudentId, activityId) => {
  //   let route = "/ActivityResult";

  //   window.location.assign(`${route}?studentId=${StudentId}`);
  // };

  const handleActivityResultClick = (studentId, activityId) => {
    let route = "/ActivityResult";

    const queryParams = new URLSearchParams();
    queryParams.set("studentId", studentId);
    queryParams.set("activityId", activityId);

    const queryString = queryParams.toString();

    const url = `${route}?${queryString}`;

    window.open(url, "_blank");
  };

  const handleActivityClick = (activityType, activityID1,ActivityLink=null) => {
    let route = "";

    if (activityType === "MCQ") {
      route = "/MCQActivity";
    } else if (activityType === "Written") {
      route = "/WrittenActivity";
    } else if (activityType === "Video") {
      route = "/VideoActivity";
    } else if (activityType === "Speed Quiz") {
      route = "/SpeedQuizActivity";
    } else if (activityType === "Mental") {
      route = "/MentalActivity";
    } else if (activityType === "Oral") {
      route = "/OralActivity";
    }
    else if (activityType === "Game") {
      ActivityLink && window.open(ActivityLink, "_blank");
      // setRefresh && setRefresh((val) => !val);
      return;
    }

    //console.log("This is route: ", route);
    window.open(`${route}?activityId=${activityID1}`, '_blank');
  };



  const [myteacherid, setTeacherid] = useState();

  useEffect(() => {
    const fetchTeacherid = async () => {
      if (userInfo && userInfo.User_id) {
        try {
          const teacherIdResponse = await axios.get(
            `${process.env.REACT_APP_LOCAL_SERVER_API}/api/teacherlessonmaterial/getTeacherId/${userInfo.User_id}`
          );
          setTeacherid(teacherIdResponse.data.teacher_id);
          console.log("Teacher ID:", teacherIdResponse.data.teacher_id);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchTeacherid();
  }, [userInfo]);

  const determineUserRole = (usertype) => {
    //console.log("In home.js:", usertype);
    if (usertype === "Student") return "Student";
    else if (usertype === "Teacher") return "Teacher";
    else if (usertype === "Admin") return "Admin";
    else return "guest";
  };

  const navigate = useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    //  console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  async function onLogout() {
    //console.log("inside Onlogout.");
    await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
      credentials: "include",
      method: "POST",
    });
    setUserInfo(null);
    setUserRole("guest");
    navigate("/");
  }
  const username = userInfo?.username;
  const _avatar_ = userInfo?.avatar;

  const handleTeacherLessonChange = (event) => {
    setSelectedLessonTeacher(event.target.value);
    console.log("My Lesson", selectedLessonteacher);
  };

  const handleFileChange = (event) => {
    setUploadedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedLessonteacher || !uploadedFile || !myteacherid) {
      toast.error(
        "Please select a lesson, upload a file, and ensure teacher ID is set."
      );
      return;
    }

    const formData = new FormData();
    formData.append("Lesson_id", selectedLessonteacher);
    formData.append("file", uploadedFile);
    formData.append("teacher_id", myteacherid);
    // for (let [key, value] of formData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_LOCAL_SERVER_API}/api/teacherlessonmaterial/addlessonmaterial`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Material Upload Successfully");
      setSelectedLesson("");
      setUploadedFile(null);

      document.getElementById("fileinput").value = "";
    } catch (error) {
      toast.error("Failed to upload material");
      console.error(error);
    }
  };

  return (
    <>
      {/* Navbar */}
      {username && (
        <>
          {isAdmin && (
            <AdminNav
              username={username}
              avatar={_avatar_}
              onLogout={onLogout}
            />
          )}
          {isTeacher && (
            <TeacherNav
              onCourseSelect={handleCourseSelect}
              username={username}
              avatar={_avatar_}
              onLogout={onLogout}
            />
          )}
          {isStudent && (
            <StudentNav
              username={username}
              avatar={_avatar_}
              onLogout={onLogout}
            />
          )}
        </>
      )}
      {!username && <>{isGuest && <GuestNav onLogin={onLogin} />}</>}
      <div className="joinClass">
        <h2
          className="Testimonal_Heading"
          style={{ color: "#03b4c6", fontSize: "25px", margin: 0 }}
        >
          {selectedCourseData.coursename}
        </h2>
        {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <a href={selectedClassLink} className="joinBtn">Join Class</a>
        </div> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            className="joinBtn"
            onClick={() =>
              window.open(`https://${selectedClassLink}`, "_blank")
            }
          >
            Join Class
          </button>
        </div>

        <div style={{ display: "flex" }}>
          <div className="lessonPlan">
            <h3>Lesson Plan</h3>
            {courseLessons ? (
              <select
                name=""
                id=""
                className="lessonOption"
                onChange={handleLessonChange}
              >
                <option value="">Select Lesson</option>
                {courseLessons.map((lesson, index) => (
                  <option key={index} value={lesson.Lesson_id}>
                    {lesson.lessonName}
                  </option>
                ))}
              </select>
            ) : (
              <select
                className="MAdropdown-menu MAdropdown-fixed-width"
                name="course"
              >
                <option value="">No Lessons available</option>
              </select>
            )}

            {/* Lesson Plan */}

            {/* <div>
              {faqData.map((faq, index) => (
                <div key={index}>
                  <h6 onClick={() => handleClick(index)}>{faq.question} <span></span></h6>
                  {activeIndex === index && <p>{faq.answer}</p>}
                </div>
              ))}
            </div> */}

            {/* <div>
      {faqData.map((faq, index) => (
        <div key={index}>
          <h6
            onClick={() => handleClick(index)}
            style={{
              cursor: "pointer",
              fontWeight: activeIndex === index ? "bold" : "bold",
            }}
          >
            {faq.question} {activeIndex === index ? "^" : "▼"}
          </h6>
          {activeIndex === index && <p>{faq.answer}</p>}
        </div>
      ))}
    </div> */}

            {/* <div style={{marginTop: "20px"}}> 
        {faqData.map((faq, index) => (
          <div key={index}>
            <h6
              onClick={() => handleClick(index)}
              style={{
                cursor: "pointer",
                fontWeight: activeIndex === index ? "bold" : "bold",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>{faq.question}</span>
              <span style={{ marginLeft: "10px" }}>
                {activeIndex === index ? "^" : "▼"}
              </span>
            </h6>
            {activeIndex === index && <p>{faq.answer}</p>}
          </div>
        ))}
      </div> */}

            {lessonPlanData && (
              <div style={{ marginTop: "20px" }}>
                {lessonPlanData.map((faq, index) => (
                  <div key={index}>
                    <h6
                      onClick={() => handleClick(index)}
                      style={{
                        cursor: "pointer",
                        fontWeight: activeIndex === index ? "bold" : "bold",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        {faq.itemname} ({faq.itemtime} minutes)
                      </span>
                      <span style={{ marginLeft: "10px" }}>
                        {activeIndex === index ? "^" : "▼"}
                      </span>
                    </h6>
                    {activeIndex === index && <p>{faq.itemdetails}</p>}
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* {selectedLesson && (
            <>
              <div className="lessonPlan">
                <h3>Lesson Activities</h3>
                <ul>
                  {lessonData
                    .find((lesson) => lesson.lesson === selectedLesson)
                    .activities.map((activity, index) => (
                      <a style={{ color:"#03b4c6 "}} className='LessonActivities' key={index}>
                      <img src={written} alt="WrittenLogo" className='ActivitiesLogo' />
                        {activity} <br /> <hr />
                      
                      </a>
                     
                    ))}
                </ul>
              </div>
              <div className="lessonPlan">
                <h3>Lesson Projects</h3>
                <ul>
                  {lessonData
                    .find((lesson) => lesson.lesson === selectedLesson)
                    .projects.map((project, index) => (
                      <a style={{ color:"#03b4c6 "}} className='LessonActivities' key={index}>{project} <br /> <hr /></a>
                    ))}
                </ul>
              </div>

          
             
            </>
          )}

          {!selectedLesson && (
            <div className="lessonPlan">
              <h3>No Lesson Selected</h3>
            </div>
          )} */}

          {lessonActivities && (
            <>
              <div className="lessonPlan">
                <h3>Lesson Activities</h3>
                {/* <ul>
                  {lessonActivities.map((activity, index) => (
                      <a style={{ color:"#03b4c6 "}} className='LessonActivities' key={index}>
                      <img src={written} alt="WrittenLogo" className='ActivitiesLogo' />
                        {activity.ActivityName} <span style={{ color: 'black', marginLeft: '10px' }} onClick={() => handleOpenDialog(activity.Lesson_Activity_ID)}> view </span><br /> <hr />
                        
                      </a>
                      
                     
                    ))}
                    
                </ul> */}

                {/* <ul>
                  {lessonActivities.map((activity, index) => (
                    <a style={{ color: "#03b4c6" }} className='LessonActivities' key={index}>
                      <img src={written} alt="WrittenLogo" className='ActivitiesLogo' />
                      {activity.ActivityName} 
                      <img
                        src={eyeIcon}
                        alt="YourImage"
                        style={{ width: '20px', height: '20px', marginLeft: '20px' }}
                        onClick={() => handleOpenDialog(activity.Lesson_Activity_ID)}
                      />
                      <hr />
                    </a>
                  ))}
                </ul> */}

                <ul>
                  {lessonActivities.map((activity, index) => (
                    <a
                      style={{ color: "#03b4c6" }}
                      className="LessonActivities"
                      key={index}
                    >
                      {activity.ActivityType == "Written" && (
                        <>
                          <img
                            src={WrittenActivityImage}
                            alt="WrittenLogo"
                            className="ActivitiesLogo"
                          />
                          {/* {activity.ActivityName} */}
                          <a
                            onClick={() =>
                              handleActivityClick(
                                activity.ActivityType,
                                activity.Lesson_Activity_ID
                              )
                            }
                          >
                            {activity.ActivityName}
                          </a>

                          <img
                            src={eyeIcon}
                            alt="YourImage"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginLeft: "20px",
                            }}
                            onClick={() =>
                              handleOpenDialog(activity.Lesson_Activity_ID)
                            }
                          />
                        </>
                      )}
                      {activity.ActivityType == "Video" && (
                        <>
                          <img
                            src={VideoActivityImage}
                            alt="VideoLogo"
                            className="ActivitiesLogo"
                          />
                          <a
                            onClick={() =>
                              handleActivityClick(
                                activity.ActivityType,
                                activity.Lesson_Activity_ID
                              )
                            }
                          >
                            {activity.ActivityName}
                          </a>
                        </>
                      )}
                      {activity.ActivityType == "MCQ" && (
                        <>
                          <img
                            src={MCQActivityImage}
                            alt="AudioLogo"
                            className="ActivitiesLogo"
                          />
                          <a
                            onClick={() =>
                              handleActivityClick(
                                activity.ActivityType,
                                activity.Lesson_Activity_ID
                              )
                            }
                          >
                            {activity.ActivityName}
                          </a>

                          <img
                            src={eyeIcon}
                            alt="YourImage"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginLeft: "20px",
                            }}
                            onClick={() =>
                              handleOpenDialog(activity.Lesson_Activity_ID)
                            }
                          />
                        </>
                      )}
                      {activity.ActivityType == "Speed Quiz" && (
                        <>
                          <img
                            src={SpeedQuizActivityImage}
                            alt="WrittenLogo"
                            className="ActivitiesLogo"
                          />

                          {/* {activity.ActivityName} */}
                          <a
                            onClick={() =>
                              handleActivityClick(
                                activity.ActivityType,
                                activity.Lesson_Activity_ID
                              )
                            }
                          >
                            {activity.ActivityName}
                          </a>

                          <img
                            src={eyeIcon}
                            alt="YourImage"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginLeft: "20px",
                            }}
                            onClick={() =>
                              handleOpenDialog(activity.Lesson_Activity_ID)
                            }
                          />
                        </>
                      )}
                      {activity.ActivityType == "Mental" && (
                        <>
                          <img
                            src={MentalActivityImage}
                            alt="VideoLogo"
                            className="ActivitiesLogo"
                          />
                          <a
                            onClick={() =>
                              handleActivityClick(
                                activity.ActivityType,
                                activity.Lesson_Activity_ID
                              )
                            }
                          >
                            {activity.ActivityName}
                          </a>

                          <img
                            src={eyeIcon}
                            alt="YourImage"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginLeft: "20px",
                            }}
                            onClick={() =>
                              handleOpenDialog(activity.Lesson_Activity_ID)
                            }
                          />
                        </>
                      )}
                      {activity.ActivityType == "Oral" && (
                        <>
                          <img
                            src={OralActivityImage}
                            alt="AudioLogo"
                            className="ActivitiesLogo"
                          />
                          <a
                            onClick={() =>
                              handleActivityClick(
                                activity.ActivityType,
                                activity.Lesson_Activity_ID
                              )
                            }
                          >
                            {activity.ActivityName}
                          </a>
                          <img
                            src={eyeIcon}
                            alt="YourImage"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginLeft: "20px",
                            }}
                            onClick={() =>
                              handleOpenDialog(activity.Lesson_Activity_ID)
                            }
                          />
                        </>
                      )}
                      {activity.ActivityType == "Game" && (
                        <>
                          <img
                            src={puzzleicon}
                            alt="AudioLogo"
                            className="ActivitiesLogo"
                          />
                          <a
                            onClick={() =>
                              handleActivityClick(
                                activity.ActivityType,
                                activity.Lesson_Activity_ID,
                                activity.ActivityLink
                              )
                            }
                          >
                            {activity.ActivityName}
                          </a>
                          {/* <img
                            src={eyeIcon}
                            alt="YourImage"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginLeft: "20px",
                            }}
                            onClick={() =>
                              handleOpenDialog(activity.Lesson_Activity_ID)
                            }
                          /> */}
                        </>
                      )}

                      {/* <img
                      src={eyeIcon}
                      alt="YourImage"
                      style={{ width: '20px', height: '20px', marginLeft: '20px' }}
                      onClick={() => handleOpenDialog(activity.Lesson_Activity_ID)}
                    /> */}
                      <hr />
                    </a>
                  ))}
                </ul>

                {isDialogOpen && (
                  <ViewActivityModal
                    isOpen={isDialogOpen}
                    onClose={handleCloseDialog}
                    lessonActivityID={selectedLessonActivityID}
                  />
                )}

                {/* {isDialogOpen && (
                  <ViewActivityModal isOpen={isDialogOpen} onClose={handleCloseDialog} 
                  lessonActivityID={1}/>
                )} */}

                {/* {isDialogOpen && (
                <ViewActivityModal isOpen={isDialogOpen} onClose={handleCloseDialog} 
                lessonActivityID={activity.Lesson_Activity_ID}/>
              )} */}
              </div>
              <div className="lessonPlan">
                <h3>Lesson Projects</h3>
                <ul>
                  {lessonProjects && (
                    <>
                      {lessonProjects.map((project, index) => (
                        <a
                          style={{ color: "#03b4c6" }}
                          className="LessonActivities"
                          key={index}
                          href="http://www.google.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {project.projectassignmentname}
                          <br />
                          <hr />
                        </a>
                      ))}
                    </>
                  )}
                </ul>
              </div>
            </>
          )}

          {!lessonActivities && (
            <div className="lessonPlan">
              <h3>No Lessons </h3>
            </div>
          )}
        </div>
      </div>

      <div style={{ display: "flex", margin: "2vh 0 0 0" }}>
        <div className="lessonPlan">
          <h3>Module Projects</h3>
          <select
            name=""
            id=""
            className="lessonOption"
            onChange={handleModuleChange}
          >
            <option value="">Select Module</option>
            {courseModules && (
              <>
                {courseModules.map((module, index) => (
                  <option key={index} value={module.Module_id}>
                    {module.moduleName}
                  </option>
                ))}
              </>
            )}
          </select>

          {moduleProjects && (
            <>
              {/* <div style={{marginTop: "20px"}}>
                  <ul>
                    {moduleProjects.map((project, index) => (
                        <a style={{ color:"#03b4c6 "}} className='LessonActivities' key={index}>{project.projectassignmentname} <hr /></a>
                      ))}
                  </ul>
                </div> */}

              <div style={{ marginTop: "20px" }}>
                <ul>
                  {moduleProjects.map((project, index) => (
                    <a
                      style={{ color: "#03b4c6" }}
                      className="LessonActivities"
                      key={index}
                      href="http://www.google.com/"
                      target="_blank"
                    >
                      {project.projectassignmentname}
                      <hr />
                    </a>
                  ))}
                </ul>
              </div>
            </>
          )}
          {!moduleProjects && (
            <div className="lessonPlan">
              <h3>No Modules </h3>
            </div>
          )}
        </div>

        {/* {moduleProjects && (
                    <>
                <div className="lessonPlan">
                        <h3>Module Projects</h3>
                        <ul>
                          {moduleProjects.map((project, index) => (
                              <a style={{ color:"#03b4c6 "}} className='LessonActivities' key={index}>{project.projectassignmentname} <br /> <hr /></a>
                            ))}
                        </ul>
                      </div>
                
                    
                    </>
                  )}
              {!moduleProjects && (
            <div className="lessonPlan">
              <h3>No Modules </h3>
            </div>
          )} */}
        <div className="lessonPlan" style={{ width: "80%" }}>
          <h3>Student Progress</h3>
          <select
            name=""
            id=""
            className="lessonOption"
            onChange={handleStudentChange}
          >
            <option value="">Select Student</option>
            {courseStudents && (
              <>
                {courseStudents.map((student, index) => (
                  <option key={index} value={student.Student_id}>
                    {student.firstname + " " + student.lastname}

                  </option>
                ))}
              </>
            )}
          </select>
          <br />
          <br />
          <input
            style={{ padding: "2%", border: "1px solid gray" }}
            type="text"
            placeholder="Type message"
          />
          <button
            style={{
              border: "0",
              borderRadius: "2vh",
              backgroundColor: "#03b4c6",
              color: "white",
              margin: "1vh",
              padding: "2% 5% 2% 5%",
            }}
          >
            Send
          </button>

          {studentProgress && (
            <>
              {/* <div className="lessonPlan"> */}
              {/* <h3>Module Projects</h3> */}
              {/* <ul>
                          {
                            studentProgress.map((progress,index) => (
                              lessonActivities.find(
                                (activity) => activity.Activity_id === progress.Activity_id)
                                .progress.map((progres, index) => (
                                  <a style={{ color:"#03b4c6 "}} className='LessonActivities' key={index}>{activity.ActivityName} <br /> <hr /></a>
                                ))
                            ))
                          }
                          </ul> */}

              {/* <ul>

                {studentProgress.map((progress, index) => (
                  
                  <a style={{ color:"#03b4c6 "}} className='LessonActivities' key={index}>{progress.ActivityName} <br /> <hr /></a>
                ))}
                

                </ul> */}

              {/* <ul>
                  {studentProgress.map((progress, index) => (
                    <a style={{ color: "#03b4c6" }} className="LessonActivities" key={index}>
                      {progress.ActivityName}
                      {progress.MarksObtained !== null && <span style={{ margin: "5px", color:"black" }}>{progress.MarksObtained}/{progress.TotalMarks} {progress.MarksObtainedPercentage}%</span>}
                      {progress.MarksObtained == null && <span style={{ margin: "5px", color:"black" }}>No Progress</span>}
                      <br />
                      <hr />
                    </a>
                  ))}
                </ul> */}

              <ul>
                {studentProgress.map((progress, index) => (
                  <a
                    style={{ color: "#03b4c6" }}
                    className="LessonActivities"
                    key={index}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {progress.MarksObtained !== null && (
                          <div
                            style={{
                              height: "3vh",
                              aspectRatio: "1/1",
                              borderRadius: "50%",
                              backgroundColor:
                                progress.MarksObtainedPercentage >= 70
                                  ? "green"
                                  :progress.Complete &&progress.ActivityType=="Video" ? "green" : "gray",
                              marginRight: "5px",
                            }}
                          ></div>
                        )}
                        {progress.MarksObtained == null && (
                          <div
                            style={{
                              height: "3vh",
                              aspectRatio: "1/1",
                              borderRadius: "50%",
                              backgroundColor: "gray",
                              marginRight: "5px",
                            }}
                          ></div>
                        )}
                        <div>{progress.ActivityName}</div>
                      </div>
                      <div style={{ textAlign: "right" }}>
                        {/* {progress.MarksObtained !== null && (
            <span style={{ marginLeft: "5px", color: "black" }}>{selectedStudent}. {progress.Activity_id}. {progress.MarksObtained}/{progress.TotalMarks} ({progress.MarksObtainedPercentage.toFixed(1)}%)</span>
          )} */}

                        {(progress.MarksObtained == null ||
                          progress.MarksObtained <= 0 
                          // && !progress?.TimeTaken
                        ) && (
                            <span style={{ marginLeft: "5px", color: "black" }}>
                              /(%)
                            </span>
                          )}

                        {progress.MarksObtained != null &&
                          progress.MarksObtained > 0 && (
                            <span
                              style={{
                                marginLeft: "5px",
                                color: "black",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleActivityResultClick(
                                  selectedStudent,
                                  progress.Activity_id
                                )
                              }
                            >
                              {progress.MarksObtained}/{progress.TotalMarks} (
                              {Math.round((progress.MarksObtained / progress.TotalMarks) * 100)}%)
                            </span>
                          )}
                        {/* <span
            style={{ marginLeft: "5px", color: "black", cursor: "pointer" }}
            onClick={() => handleActivityResultClick(selectedStudent, progress.Activity_id)}
          >
            {selectedStudent}. {progress.Activity_id}. {progress.MarksObtained}/{progress.TotalMarks} ({progress.MarksObtainedPercentage.toFixed(1)}%)
          </span> */}
                      </div>
                    </div>
                    <hr />
                  </a>
                ))}
              </ul>

              {/* {studentData
                            .find((student) => student.name === selectedStudent)
                            .progres.map((progres, index) => (
                              <a style={{ color:"#03b4c6 "}} className='LessonActivities' key={index}>{progres} <br /> <hr /></a>
                            ))} */}
              {/* </ul> */}
              {/* </div> */}
            </>
          )}
          {!studentProgress && (
            <div className="lessonPlan">
              <h3>No Student Selected</h3>
            </div>
          )}
        </div>
      </div>

      {/* Teacher Lesson Material Sharing */}
      <div style={{ display: "flex", margin: "2vh 0 0 0" }}>
        <div className="lessonPlan">
          <h3>Teacher Material Sharing</h3>
          {courseLessons ? (
            <select
              name=""
              id=""
              className="lessonOption"
              onChange={handleTeacherLessonChange}
            >
              <option value="">Select Lesson</option>
              {courseLessons.map((lesson, index) => (
                <option key={index} value={lesson.Lesson_id}>
                  {lesson.lessonName}
                </option>
              ))}
            </select>
          ) : (
            <select
              className="MAdropdown-menu MAdropdown-fixed-width"
              name="course"
            >
              <option value="">No Lessons available</option>
            </select>
          )}
          <input type="file" id="fileinput" onChange={handleFileChange} />
          <button style={{
            border: "0",
            borderRadius: "2vh",
            backgroundColor: "#03b4c6",
            color: "white",
            margin: "1vh",
            padding: "2% 5% 2% 5%",
          }} onClick={handleUpload}>Upload</button>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
};

export default TeacherAttendClass;
