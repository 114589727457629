import React, { useState } from "react";
import { AiOutlineCloudUpload, AiOutlineCheckCircle } from "react-icons/ai";
import "./ActivitiesAndProjects.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const LessonProjects = ({
  title,
  projects,
  backgroundColor,
  textColor,
  iconBackground,
  image,
  courseID,
  activityID,
  uploadApiRoute,
  studentid,
}) => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const handleFileSelect = (e, projectid) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    console.log("File Selected");
    handleUpload(file,projectid);
  };

  const handleUpload = async (file,projectID) => {
    setUploading(true);
    console.log("File Uploading");

    if (!file) {
      console.error("No file selected");
      setUploading(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("Student_id", studentid);
    formData.append("Lesson_Project_id", projectID);

    // console.log("Form Data:");
    // for (const [key, value] of formData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }

    const response = await fetch(
      `${process.env.REACT_APP_LOCAL_SERVER_API}${uploadApiRoute}`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (response.ok) {
      toast.success("Lesson Project Uploaded", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setSelectedFile(null);
    } else {
      console.error("Error uploading file");
    }

    setUploading(false);
  };

  const handleIconClick = (e, projectID) => {
    e.preventDefault();
    document.getElementById(`file-upload-${projectID}`).click();
  };

  const handleProjectClick = (projectType, projectID) => {
    // let route = "";
    // window.open(`${route}=${projectID}&studentid=${studentid}`, "_blank");
  };

  return (
    <div className="AP_ModuleProjectLesson" style={{ backgroundColor }}>
      <h2 className="AP_title" style={{ color: textColor }}>
        <img src={image} alt="" style={{ width: "7vh", height: "7vh" }} />{" "}
        {title}
      </h2>
      <ul>
        {projects.map((project, index) => (
          <li key={index} className="AP_Projects">
            <div className="AP_ProjectItem">
              <div style={{maxWidth:"100%", marginBottom:"5px"}}>
                <Link
                  to=""
                  onClick={() => handleProjectClick(title, project.Lesson_Project_id)}
                  style={{ marginRight: "7%", color: textColor }}
                >
                  {project.projectassignmentname}
                </Link>
              </div>

              <div style={{display:"flex"}}>
                <div style={{ position: 'relative' }}>
                  <label htmlFor={`file-upload-${project.Lesson_Project_id}`}>
                    <span
                      style={{
                        color: textColor,
                        backgroundColor: iconBackground,
                      }}
                      className="AP_Icon"
                      onClick={(e) =>
                        !uploading && handleIconClick(e, project.Lesson_Project_id)
                      }
                    >
                      <AiOutlineCloudUpload size={40} />
                    </span>
                    <input
                      id={`file-upload-${project.Lesson_Project_id}`}
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => handleFileSelect(e, project.Lesson_Project_id)}
                      disabled={uploading}
                    />
                    <h6 style={{color:"green",fontWeight:"bold"}}>Uploaded</h6>
                  </label>
                  {project.MarksObtained > 0 && (
                    <span
                      style={{
                        position: 'absolute',
                        top: '-8px',
                        right: '-8px',
                        backgroundColor: '#4CAF50',
                        borderRadius: '50%',
                        padding: '2px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: '2px solid white'
                      }}
                    >
                      <AiOutlineCheckCircle 
                        size={16} 
                        color="white"
                      />
                    </span>
                  )}
                </div>

                <span
                  style={{
                    color: textColor,
                    backgroundColor: iconBackground,
                    width:"125px",
                    height:"40px",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center"
                  }}
                  className="AP_Score"
                >
                  {project.MarksObtained}/{project.TotalMarks} (
                  {((project.MarksObtained / project.TotalMarks) * 100).toFixed(1)}
                  %)
                </span>
              </div>
            </div>
            <hr />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LessonProjects;
