import React, { useState, useContext, useEffect } from 'react';
import StudentNav from '../Navbars/StudentNav';
import AdminNav from '../Navbars/AdminNav';
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import Footer from '../Footer';
import './AdminRegisterInCourse.css';
import { UserContext } from '../UserContext';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

const AdminRegisterInCourse = () => {
  const [discount, setDiscount] = useState(['10% Off', '20% Off', '30% Off']);

  function Registration({ RegistrationCourse, classIDs, assignedClass_id, assignedClassID }) {
    // console.log("RegistrationCourse:",RegistrationCourse)
    // console.log("assignedClass_id:",assignedClass_id)
    // console.log("assignedClassID:",assignedClassID)
    const uniqueClassIDs = [];
    const filtered_ClassIDs = classIDs.filter((CID) => {
      if (!uniqueClassIDs.hasOwnProperty(CID.Class_id)) {
        uniqueClassIDs[CID.Class_id] = CID;
      }
    });
    // console.log(uniqueClassIDs.CL)
    const filteredClassID = classIDs.filter((CID) => uniqueClassIDs[CID.Class_id].Class_id === CID.Class_id);

 

    const [assignedClass, setAssignedClass] = useState(assignedClass_id);
    const [assignedTeacherNames, setAssignedTeacherNames] = useState(new Set());
    const [CS, setCS] = useState('');

    var assignedClassObject = classIDs.find((CID) => CID.Class_id === assignedClass);

    // console.log("assignedClass:",assignedClass)
    useEffect(() => {

      if (assignedClass) {
        console.log("classIDs:",classIDs)
        const matchingClass = classIDs.find((CID) => CID.Class_id == assignedClass);
        console.log("matchingClass:",matchingClass)

        if (matchingClass) {
          const classID = matchingClass.ClassID;

          // Regular expression pattern to match the schedule format (day-time)
          const scheduleRegex = /(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday)-\d{1,2}:?\d{2}\s*[AaPpMm]{2}/g;

          // Extracting schedule from ClassID using regex
          const extractedSchedule = classID.match(scheduleRegex);

          if (extractedSchedule && extractedSchedule.length > 0) {
            // Extracted schedule will be something like ["Tuesday-6:00 pm"]
            const schedule = extractedSchedule; // Take the first match

            // Now you can set this schedule to state or use it as needed
            console.log("Extracted schedule:", schedule);
            setCS(schedule);
          } else {
            console.log("Schedule not found in ClassID:", classID);
            // Handle case where schedule pattern is not found
          }

          // Example: Extracting teacher names (assuming firstname and lastname exist in matchingClass)
          const teacherName = `${matchingClass.firstname} ${matchingClass.lastname}`;
          setAssignedTeacherNames(new Set([teacherName])); // Using Set to ensure uniqueness
        } else {
          console.log("Matching class not found for assignedClass:", assignedClass);
          // Handle case where matching class is not found in classIDs
        }
      }

    }, [assignedClass, classIDs]);

    const handleClassAssign = (event) => {
      const selectedIndex = event.target.selectedIndex;
      const selectedOption = event.target.options[selectedIndex];
      const cidValue = selectedOption.dataset.cid;
      // console.log(cidValue)

      const newClassType = event.target.value;
      updateAssignedClass(newClassType);
      setAssignedClass(newClassType);
      const matchingClasses = classIDs.filter((CID) => CID.Class_id == newClassType);

      // Extract teacher names from matching classes
      const teacherNames = matchingClasses.map((classObj) => `${classObj.firstname} ${classObj.lastname}`);

      // Update the assigned teacher names state variable
      setAssignedTeacherNames(teacherNames);
      // console.log("ClassIDs",classIDs)
      // assignedClassObject = classIDs.filter((CID) => CID.Class_id == newClassType);
      // console.log("Clid",newClassType)
      // console.log('assignedClassObject:',assignedClassObject)
    };

    const updateAssignedClass = (newClassType) => {
      const User_id = queryParams.get('User_id');
      // console.log("ParamUserId:",ParamUserId)
      // console.log("assignedClass_id:",assignedClass_id)
      axios.put(`${process.env.REACT_APP_LOCAL_SERVER_API}/updateStdAssignedClass`, { User_id, assignedClass_id, newClassType })
        .then((response) => {
          // setClassData(response.data.updatedClassData);
          toast.success('Class Successfully Updated.', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 500,
          });
          window.location.reload();
        })
        .catch((error) => {
          console.error('Error updating class data:', error);
        });
    };

    return (
      <>
        {
          RegistrationCourse.StatusComplete == 1 &&

          <tbody id="table-rows">
            <tr className='CourseRegistrationTable-tr'>
              <td className='CourseRegistrationTable-td'>
                {RegistrationCourse.firstname} {RegistrationCourse.lastname}
              </td>
              <td className='CourseRegistrationTable-td'>{RegistrationCourse.coursename}</td>
              <td className='CourseRegistrationTable-td'>{RegistrationCourse.RegistrationDate}</td>
              <td className='CourseRegistrationTable-td'> <b>{RegistrationCourse.Currency}</b></td>
              <td className='CourseRegistrationTable-td'>
                {RegistrationCourse.Discount_id}
              </td>

              <td class="CourseRegistrationRadioType CourseRegistrationTable-td">
                {RegistrationCourse.PaymentType}
              </td>

              <td class="CourseRegistrationRadioType CourseRegistrationTable-td">
                {RegistrationCourse.ClassType}
              </td>

              <td className='CourseRegistrationTable-td'>{RegistrationCourse.SchedulePreferenceDay}
                - {RegistrationCourse.SchedulePreferenceTime}</td>
              <td className='CourseRegistrationTable-td'>
                <select
                  name="discount"
                  id="discount-code"
                  className='CourseRegistrationSelect'
                  style={{ width: "20vw" }}
                  value={assignedClass} /*{integer value}*/
                  onChange={handleClassAssign}
                >
                  <option value={''} >Select Class</option>
                  {uniqueClassIDs.map((CID) => {
                    // Check if RegistrationCourse.Course_id matches with the courseIDs in the array
                    if (RegistrationCourse.Course_id === CID.Course_id) {
                      return (
                        <option key={CID.Class_id} value={CID.Class_id} data-cid={CID.ClassID}
                          style={{ fontSize: "2vh" }}
                        >
                          {CID.coursename}  {CID.ClassID} {CID.ClassDay} {CID.ClassTime}
                        </option>
                      );
                    }
                    return null; // Render nothing if there is no match
                  })}
                </select>
              </td>

              <td className='CourseRegistrationTable-td' key={filteredClassID?.Class_id} value={filteredClassID?.Class_id}>

                {assignedTeacherNames.size > 0 ? (
                  <p>{Array.from(assignedTeacherNames).join(', ')}</p> // Convert Set to array for rendering
                ) : (
                  <p>No teachers assigned for this class</p>
                )}
              </td>

              <td className='CourseRegistrationTable-td' style={{ maxHeight: '200px', overflowY: 'auto', border: '1px solid #ccc' }}>
                {CS}
              </td>

              {/* <td className='CourseRegistrationTable-td'>{studentRegistration.classSchedule}</td> */}
            </tr>
          </tbody>
        }
      </>
    );
  };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ParamUsername = queryParams.get('username');
  const ParamUserId = queryParams.get('User_id');

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [courseType, setCourseType] = useState('One-on-One');
  const [paymentType, setPaymentType] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [fee, setFee] = useState('');
  const [discount_, setDiscount_] = useState('');
  const [registrations, setRegistrations] = useState([]);

  const [discounts, setDiscounts] = useState([]);


  const courseTypes = ['One-on-One', 'Group'];

  const paymentTypes = ['Monthly', 'Upfront'];

  const [courses, setCourses] = useState([]);
  const [classes, setClasses] = useState([]);
  const [assignedClasses, setAssignedClasses] = useState([]);
  const [studentRegisteredCourses, setStudentRegisteredCourses] = useState([]);
  const [loadingCourses, setLoadingCourses] = useState(true);




  const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo, userInfo } = useContext(UserContext);
  async function fetchDiscounts() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getDiscounts`);
      const discount = response.data.discounts;
      setDiscounts(discount);
      // return discounts;
    } catch (error) {
      console.error('Error fetching data from the API:', error);
      return [];
    }
  }
  const fetchStudentCourses = () => {

    axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getStudentRegisteredCourses/${ParamUserId}`)
      .then(response => {
        const data = response.data;
        // console.log(userData);
        const formattedData = data.StudenRsgCourses.map(courseData => ({
          ...courseData,
          RegistrationDate: new Date(courseData.RegistrationDate).toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
          })
        }));
        setStudentRegisteredCourses(formattedData);
        setCourseIDs(data.uniqueCourseIDs);

      })
      .catch(error => {
        console.log('Error fetching user:', error);
        // Handle the error as needed
      });
  }
  useEffect(() => {
    const fetchData = async () => {
      await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
        credentials: 'include',
      }).then(response => {
        //  console.log("Response:",response);
        response.json().then(userInfo => {
          //  console.log("userinfo:",userInfo);
          setUserInfo(userInfo);
          //  fetchAllCourses();
          loadCourses();
          fetchAllClasses();
          fetchStudentAssignedClasses();
          //  console.log("assignedClasses:",assignedClasses)
          //  fetchStudentCourses();
          //  console.log(courses);

          if (userInfo?.usertype) {
            const uType = userInfo?.usertype;
            setUserRole(uType);
          }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          //  console.log("Uname Admin Register", uname);
          //  console.log("Avatar in: ", avatar);

        })
          .catch(err => console.log("Error in useeffect:", err))
      });
    }
    fetchStudentCourses();
    fetchDiscounts();
    fetchData();
  }, [isAdmin, isTeacher, isStudent, isGuest]);


  const [CourseIDs, setCourseIDs] = useState([]);
  const [ClassIds, setClassIDs] = useState([]);

  //  useEffect(() => {
  //     fetchStudentCourses(); 
  //       fetchDiscounts();
  //   }, []);
  const loadCourses = async () => {
    await fetchAllCourses();
    setLoadingCourses(false);
  };


  // useEffect(() => {
  //   // Simulate loading time

  //   // loadCourses();
  // }, [courses]);

  useEffect(() => {
    fetchClassIDs(CourseIDs);

  }, [studentRegisteredCourses]);
  const onLogin = (usertype) => {

    setUserRole(usertype);
  };
  const navigate = useNavigate();

  async function onLogout() {
    console.log("inside Onlogout.");
    await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
      credentials: 'include',
      method: 'POST',
    });
    setUserInfo(null);
    console.log("navigating");
    navigate('/');
    // console.log("navigated");
    setUserRole('guest');
  }


  const fetchAllCourses = () => {
    // Fetch all courses
    // axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getStudentAndClasses?UserId=${ParamUserId}`)
    axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getStdExcludedCourses?User_id=${ParamUserId}`)
      .then(response => {
        const coursesData = response.data.courses;
        setCourses(coursesData);
      })
      .catch(error => {
        console.log('Error fetching courses:', error);
        // Handle the error as needed
      });
  }
  const fetchAllClasses = () => {
    // Fetch all courses
    axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getAllClasses`)
      .then(response => {
        const classesData = response.data.classes;
        setClasses(classesData);
      })
      .catch(error => {
        console.log('Error fetching courses:', error);
        // Handle the error as needed
      });
  }

  const fetchStudentAssignedClasses = () => {
    console.log('fetchStudentAssignedClasses():')
    axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getStudentAndClasses?UserId=${ParamUserId}`)
      .then(response => {
        const classesData = response.data.classes;
        console.log('classesData:', classesData)
        setAssignedClasses(classesData);
      })
      .catch(error => {
        console.log('Error fetching student and classes:', error);
      });
  };

  const fetchClassIDs = async (courseIds) => {
    try {
      // console.log("in fetchClassIDs,",courseIds)
      const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getClassIDs/${courseIds}`);
      const data = response.data;
      console.log('dataaaa->classes:', data)
      setClassIDs(data.classIDs);
    } catch (error) {
      console.log(error);
    }
  };


  const getClassById = (classId) => {
    console.log("classId,classes:", classId, classes)
    const foundClass = classes.find((cls) => cls.Class_id === parseInt(classId));
    console.log("foundClass", foundClass)
    return foundClass ? foundClass.Class_id : '';
  };

  const handleCourseChange = (event) => {
    const { value } = event.target;
    const selectedCourse = courses.find((course) => course.Course_id === parseInt(value));
    setSelectedCourse(selectedCourse);
  };

  const handleCourseTypeChange = (event) => {
    const { value } = event.target;
    setCourseType(value);
  };

  const handlePaymentTypeChange = (event) => {
    const { value } = event.target;
    setPaymentType(value);
  };

  const handleClassChange = (event) => {
    const { value } = event.target;
    setSelectedClass(value);
  };

  const handleFeeChange = (event) => {
    const { value } = event.target;
    setFee(value);
  };

  const handleDiscountChange = (event) => {
    const { value } = event.target;
    const selectedDiscount = discounts.find((discount) => discount.Discount_id === parseInt(value));
    setDiscount_(selectedDiscount);
  };

  const handleRegistration = () => {
    const now = new Date();
    const newRegistration = {
      Course_id: selectedCourse ? selectedCourse.Course_id : '',
      courseType,
      paymentType,
      fee,
      discountID: discount_ ? discount_.Discount_id : null, // This will handle empty discount
      Class: getClassById(selectedClass),
      registrationDate: now.toISOString().slice(0, 19).replace('T', ' '),
      SchedulePreferenceDay: '',
      SchedulePreferenceTime: '',
      StatusComplete: 1,
    };


    // Check for empty fields
    const requiredFields = ['Course_id', 'courseType', 'paymentType', 'fee', 'Class'];
    const emptyField = requiredFields.find(field => !newRegistration[field]);

    if (emptyField) {
      toast.error(`Please fill in the ${emptyField} field`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
      return;
    }

    setRegistrations([...registrations, newRegistration]);

    // Make an Axios POST request to the API
    axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/saveStudent_Studentcourses/${ParamUserId}`, newRegistration)
      .then(response => {
        fetchAllCourses();
        fetchStudentAssignedClasses();
        fetchStudentCourses();
        // Show success message
        toast.success('Registered Successfully!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      })
      .catch(error => {
        // Handle errors here
        console.error('API Error:', error);
      });

    setSelectedCourse('');
    setCourseType('');
    setPaymentType('');
    setSelectedClass('');
    setFee('');
    setDiscount('');
  };

  const username = userInfo?.username;
  const _avatar_ = userInfo?.avatar;

  return (
    <>
      {/* Navbar */}
      {username && (
        <>
          {isAdmin && <AdminNav username={username} avatar={_avatar_} onLogout={onLogout} />}
          {isTeacher && <TeacherNav username={username} avatar={_avatar_} onLogout={onLogout} />}
          {isStudent && <StudentNav username={username} avatar={_avatar_} onLogout={onLogout} />}
        </>
      )}
      {!username && (
        <>
          {isGuest && <GuestNav onLogin={onLogin} />}
        </>
      )}
      <div className='edit-bar'>
        <h3 className='edit-heading' style={{ marginLeft: '0', display: 'flex', justifyContent: "space-evenly" }}>
          <span>
            <span style={{ fontSize: "5vh", marginRight: '25%', marginLeft: '2%' }}>
              {ParamUsername}
            </span>
          </span>
          Register in Course
        </h3>
      </div>

      <div className='CourseRegistrationInputs'>
        <div className="course-dropdown">
          <select
            className='ManageClassDropdown'
            required
            value={selectedCourse ? selectedCourse.Course_id.toString() : ''}
            onChange={handleCourseChange}
            disabled={loadingCourses}
          >
            {loadingCourses ? (
              <option value=''>Fetching courses...</option>
            ) : (
              <>
                <option value=''>Select Course</option>
                {courses
                  .slice() // Make a copy of the courses array
                  .sort((a, b) => a.coursename.localeCompare(b.coursename)) // Sort the copied array alphabetically
                  .map((course) => (
                    <option key={course.Course_id} value={course.Course_id}>
                      {course.coursename}
                    </option>
                  ))
                }
              </>
            )}
          </select>
        </div>

        <select className='ManageClassDropdown'
          required
          value={courseType}
          onChange={handleCourseTypeChange}
        >
          {/* <option value=''>Select Course Type</option> */}
          {courseTypes.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </select>

        <select className='ManageClassDropdown'
          required
          value={paymentType}
          onChange={handlePaymentTypeChange}>
          <option value=''>Select Payment Type</option>
          {paymentTypes.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </select>

        <select className='ManageClassDropdown'
          required
          value={selectedClass}
          onChange={handleClassChange}
        >
          <option value=''>Select Class</option>
          {selectedCourse &&
            classes
              .filter((cls) => (cls.Course_id === parseInt(selectedCourse.Course_id) && courseType == cls.ClassType))
              .map((cls) => (
                <option key={cls.Class_id} value={cls.Class_id}>
                  {cls.ClassID}
                </option>
              ))}
        </select>

        <input className='RegisterInCourseFeeTextField' type='text' required value={fee}
          onChange={handleFeeChange} placeholder='Fee (USD 500/PKR 24500 )' />

        <select className='ManageClassDropdown'
          value={discount_ ? discount_.Discount_id.toString() : ''}
          onChange={handleDiscountChange}>
          <option value=''>Select Discount</option>
          {discounts.map((discount) => (
            <option key={discount.Discount_id} value={discount.Discount_id}>
              {discount.discountpercentage}%
            </option>
          ))}
        </select>

        <div className='RegisterButtonDiv'>
          <button className='RegisterButton' type='submit' onClick={handleRegistration}>
            <b>
              Register {ParamUsername}
            </b>
          </button>
        </div>
      </div>

      {/* </form> */}
      <table className='CourseRegistrationTable'>
        <thead>
          <tr className='CourseRegistrationTable-tr'>
            <th className='CourseRegistrationTable-th'>Student</th>
            <th className='CourseRegistrationTable-th'>Course</th>
            <th className='CourseRegistrationTable-th'>Registration Date</th>
            {/* <th className='CourseRegistrationTable-th'>School</th> */}
            <th className='CourseRegistrationTable-th'>Fee</th>
            <th className='CourseRegistrationTable-th'>DiscountID</th>
            <th className='CourseRegistrationTable-th'>Payment Type</th>
            <th className='CourseRegistrationTable-th'>Class Type</th>
            <th className='CourseRegistrationTable-th'>SchedulePreference Day & Time</th>
            {/* <th className='CourseRegistrationTable-th'>SchedulePreferenceTime</th> */}
            <th className='CourseRegistrationTable-th'>Class ID</th>
            <th className='CourseRegistrationTable-th'>Teacher</th>
            <th className='CourseRegistrationTable-th'>Class Schedule</th>
          </tr>
        </thead>

        {studentRegisteredCourses.map((RegistrationCourse, index) => {
          // Find the class with the matching course_id
          const matchingClass = assignedClasses.find(cls => cls.Course_id === RegistrationCourse.Course_id);

          // Determine whether a matching class is found
          const matched_class_id = matchingClass ? matchingClass.Class_id : null;
          const matched_classID = matchingClass ? matchingClass.ClassID : null;
          console.log("matchingClass", matchingClass)
          return (
            <Registration key={index} RegistrationCourse={RegistrationCourse}
              classIDs={ClassIds}
              assignedClass_id={matched_class_id}
              assignedClassID={matched_classID}
            />
          );
        })}


      </table>
      <Footer />
    </>
  );
};

export default AdminRegisterInCourse;
