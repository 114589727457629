import React,{useState} from "react";
import "./Home.css";
import footer from "./CourseImages/footer.png";
import Logo from "./CourseImages/Logo.png";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Badge from "./CourseImages/badge.png";
import axios from 'axios';

const Footer = () => {
  const [email, setEmail] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = async () => {
    try {
      const response = await addNewsLetterEmail(email);
      // Handle the API response as needed
      // console.log(response.data); // Log the response for debugging
      toast.success('You have been Subscribed successfully!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setEmail("");
    } catch (error) {
      // console.error(error);
      toast.error('Email might already been subscribed!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setEmail("");

    }
  };
  

  const addNewsLetterEmail = async (email) => {
    try {
      // Make an Axios POST request to your API
      const response = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/addNewsletterEmail`, { email });
      return response;
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <div style={{ marginTop: "2%" }}>
        <footer className="footer" style={{ position: "relative" }}>
          <div className="footerContent">
            <div className="footerText">
              <img
                src={Logo}
                alt="Logo"
                style={{ width: "50%", height: "5%" }}
                className="py-3 my-3"
              />
              <br></br>
              <p>
                Polymath-Kids.com is market leader in grooming children's
                cognitive, leadership, social and people skills through our
                structured courses designed by our team of subject matter
                experts. We help your kids become a Polymath!
              </p>
            </div>
            <div className="footerLinksSection" style={{ marginTop: "5%" }}>
              <div className="footerLinks">
                <ul itemType="none">
                  <p
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "3vh",
                    }}
                  >
                    Our Company
                  </p>
                  <Link
                    to={"/polymatch"}
                    style={{
                      color: "#aee6da",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    PolyMatch
                  </Link>
                  <br></br>
                  <Link
                    to={"/our_vision"}
                    style={{
                      color: "#aee6da",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    Our Vision
                  </Link>
                  <br />
                  <Link
                    to={"/polymathkids_at_schools"}
                    style={{
                      color: "#aee6da",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    Polymath-kids@school
                  </Link>
                  <br />
                  <Link
                    to={"/our_team"}
                    style={{
                      color: "#aee6da",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    About us
                  </Link>
                  <br />
                  <Link
                    to={"/terms_of_use"}
                    style={{
                      color: "#aee6da",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    Terms of use
                  </Link>
                  <br />
                  <Link
                    to={"/privacy_policy"}
                    style={{
                      color: "#aee6da",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    Privacy policy
                  </Link>
                  <br />
                  <Link
                    to={"/cancelation_refund_policy"}
                    style={{
                      color: "#aee6da",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    Cancellation and refund policy
                  </Link>
                  <br />
                </ul>
              </div>
             
              <div className="footerLinks2">
                  <ul itemType="none">
                    <p
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "3vh",
                      }}
                    >
                      Our Newsletter
                    </p>
                    <p style={{ width: "90%", color: "#aee6da" }}>
                      Subscribe to our newsletter and stay up to date with our events.
                    </p>
                    <input
                      style={{
                        border: 0,
                        borderRadius: "1vh",
                        width: "100%",
                        height: "5vh",
                      }}
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Enter Email"
                      value={email}
                      onChange={handleEmailChange}
                    />
                    <br />
                    <br />
                    <button
                      style={{
                        backgroundColor: "#a1e8f0",
                        border: 0,
                        borderRadius: "1vh",
                        padding:"5%"
                      }}
                      onClick={handleSubscribe} // Call handleSubscribe when the button is clicked
                    >
                      Submit
                    </button>
                  </ul>
              </div>

              <div className="footerLinks1">
                <ul itemType="none">
                  <p
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "3vh",
                    }}
                  >
                    Get in touch
                  </p>
                  <a style={{ color: "#aee6da" }}>info@polymath-kids.com</a>
                  <br />
                  <a style={{ color: "#aee6da" }}>+923266699907</a>
                  <a style={{ color: "#aee6da" }}>+4747650619</a>
                  <br />
                  <Link to="/contact_us" style={{ color: "#aee6da" }}>
                    Send a message
                  </Link>
                  <br />
                  {/* Social media icons */}
                  <div style={{ display: "flex", justifyContent: "flex-start", marginTop: "10px" }}>
      <a
        href="https://www.facebook.com/polymathkidz"
        target="_blank"
        rel="noopener noreferrer"
        className="fa-brands fa-square-facebook"
        style={{ fontSize: "4.5vh", marginRight: "10px", color: "#4267B2" }}  // Facebook original color
      ></a>
      <a
        href="https://twitter.com/polymathkidz"
        target="_blank"
        rel="noopener noreferrer"
        className="fa-brands fa-twitter"
        style={{ fontSize: "4.5vh", marginRight: "10px", color: "#1DA1F2" }}  // Twitter original color
      ></a>
      <a
        href="https://www.youtube.com/@polymath-kids"
        target="_blank"
        rel="noopener noreferrer"
        className="fa-brands fa-youtube"
        style={{ fontSize: "4.5vh", marginRight: "10px", color: "#FF0000" }}  // YouTube original color
      ></a>
      <a
        href="https://www.instagram.com/polymathkidz/"
        target="_blank"
        rel="noopener noreferrer"
        className="fa-brands fa-instagram"
        style={{ fontSize: "4.5vh", marginRight: "10px", color: "#E4405F" }}  // Instagram original color
      ></a>
      <a
        href="https://www.pinterest.com/polymathkidz/"
        target="_blank"
        rel="noopener noreferrer"
        className="fa-brands fa-pinterest"
        style={{ fontSize: "4.5vh", marginRight: "10px", color: "#E60023" }}  // Pinterest original color
      ></a>
    </div>
                </ul>
              </div>
            </div>
          </div>
          <img
            src={Badge}
            alt="STEM Badge"
            style={{
              position: "absolute",
              right: "2%",
              bottom: "30%",
              width: "100px",  // Adjust the size as needed
            }}
          />
        </footer>
      </div>
    </>
  );
};

export default Footer;
