import React, { useContext } from 'react'
import { useRef, useState, useEffect } from 'react'

import axios from 'axios';

import StudentNav from '../../Navbars/StudentNav'
import AdminNav from '../../Navbars/AdminNav'
import TeacherNav from '../../Navbars/TeacherNav';
import GuestNav from '../../Navbars/GuestNav';
import { UserContext } from '../../UserContext';
import Footer from '../../Footer'

import { fetchActivitiesImage } from '../fetchActivitiesImage';

import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import { useLocation } from "react-router-dom";


import './MentalActivity.css'
import MentalImage from './mental-activity.png'
import PlayImage from './play.png'
import GoodImage from './good.png'
import CrossImage from './cross.png'
import FinishedImage from './finished.jpeg'
// import AdminNav from '../../Navbars/AdminNav'

const MentalActivity = () => {

  const selectedCourse = ""
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activityId = searchParams.get("activityId");
  console.log("activityId:", activityId)

  const [actualQuestions, setActualQuestions] = useState([]);
  const [currQuestion, setCurrQuestion] = useState([]);

  const [count, setCount] = useState();
  const countRef = useRef();
  const [totalQuestions, setTotalQuestions] = useState();

  const [imageData, setImageData] = useState(null);

  const totalQuestionsRef = useRef(0);


  const progressIncrement = useRef();
  useEffect(() => {
    // Define an async function to fetch the questions from the API
    async function fetchQuestions() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getActivityQuestions/${activityId}`);
        setActualQuestions(response.data);
        console.log(response.data[0]);
        setCurrQuestion(response.data[0]);

        setCount(response.data[0].timealloted);
        countRef.current = response.data[0].timealloted;
        setMarksPerQuestion(response.data[0].marks);

        console.log("..........Calling Fetch Image 01..........")
        console.log("Question Picture:", response.data[0].questionpicture)
        fetchActivitiesImage(setImageData, response.data[0].questionpicture);

        var total_marks = 0;
        var total_questions = 0;
        response.data.forEach(data => {
          total_marks += data.marks;
          total_questions++;
        });

        setTotalMarks(total_marks);
        setTotalQuestions(total_questions);
        totalQuestionsRef.current = total_questions;

        progressIncrement.current = (100 / (totalQuestionsRef.current)) * 1.3;

      } catch (error) {
        console.error(error);
      }

      try {
        const response = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/saveActivity`, {
          Activity_id: activityId,
          Student_id: student_ID,
          MarksObtained: gainedMarksRef.current,
          MarksObtainedPercentage: gainedMarksRef.current / totalMarks * 100,
          Remarks: (gainedMarksRef.current / totalMarks * 100) > 90 ? 'Excellent Work' : (gainedMarksRef.current / totalMarks * 100) > 70 ? "Good Job" : "Try Again",
          SubmitActivityFile: '',
          TimeTaken: totalTimeTakenOfAllQuestions.current,
          TotalMarks: totalMarks,
          TotalTime: totalTimeOfAllQuestions.current < 0 ? 0 : totalTimeOfAllQuestions.current,
          Complete: 0,//completeActivityRef.current,
        });
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
      try {
        const response = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/deleteActivityQuestions`, {
          Lesson_Activity_id: activityId,
          Student_id: student_ID,

        });
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    // Call the async function to fetch the questions
    fetchQuestions();
  }, [activityId]);

  const questions = [
    ["2", "+2", "*3"],
    ["3", "-1", "*3"],
    ["9", "+9", "-1"]
  ];
  const answers = [
    "12",
    "15",
    "24",
  ];

  const submitButton = useRef(null);
  const GoodImageRef = useRef(null);
  const CrossImageRef = useRef(null);
  const FinishedImageRef = useRef(null);

  const VolumeRangeRef = useRef(null);
  const ProgressRef = useRef(null);
  const InputRef = useRef(null);

  const [items, setItems] = useState([]);
  const [AnzanQuestionPart, setAnzanQuestionPart] = useState();
  const [currentQuestion, setCurrentQuestion] = useState(questions[0]);
  const [gainedMarks, setGainedMarks] = useState(0);
  const [questionNumber, setQuestionNumber] = useState(1);
  const [studentAnswer, setStudentAnswer] = useState("");

  const [marksPerQuestion, setMarksPerQuestion] = useState(5);
  const [totalMarks, setTotalMarks] = useState(15);
  const questionNumberRef = useRef(0);
  const progressCount = useRef(0);
  // const progressIncrement = useRef((100/(totalQuestionsRef.current))*1.3);

  console.log("progress Increment is: ", progressIncrement.current);


  const [student_ID, setStudent_ID] = useState("2");
  const studentCurrAnswerRef = useRef(0);
  const marksObtainedCurrQuestionRef = useRef(0);
  const isCorrectRef = useRef(0);

  const gainedMarksRef = useRef(0);
  const [playButtonVisibility,setPlayButtonVisibility] = useState(true)

  var intervalId;
  useEffect(() => {

    intervalId = setInterval(() => {
      countRef.current = countRef.current - 1;

      if (countRef.current == -1) {

        studentCurrAnswerRef.current = "";
        marksObtainedCurrQuestionRef.current = marksPerQuestion;
        isCorrectRef.current = 0;

        submitStudentQuestionResponse();

        questionNumberRef.current = questionNumberRef.current + 1;
        setQuestionNumber(questionNumber => questionNumber + 1);

        setCurrQuestion(actualQuestions[questionNumberRef.current]);

        console.log("..........Calling Fetch Image..........")
        fetchActivitiesImage(setImageData, actualQuestions[questionNumberRef.current].questionpicture);
        setMarksPerQuestion(actualQuestions[questionNumberRef.current].marks);
        setCount(actualQuestions[questionNumberRef.current].timealloted);
        setItems([...items, 0]);
        countRef.current = actualQuestions[questionNumberRef.current].timealloted;

        GoodImageRef.current.style.display = 'none';
        CrossImageRef.current.style.display = "inline-block";

        progressCount.current += progressIncrement.current;
        ProgressRef.current.style.width = `${progressCount.current}px`;

      } else {
        setCount(countRef.current);
      }
    }, 3000);

    // Return a cleanup function to clear the interval
    return () => clearInterval(intervalId);
  }, [questionNumber, count]);

  useEffect(() => {
    if (questionNumber == totalQuestions && count == 0) {


      studentCurrAnswerRef.current = "";
      marksObtainedCurrQuestionRef.current = marksPerQuestion;
      isCorrectRef.current = 0;
      submitStudentQuestionResponse();

      setItems([...items, 0]);
      clearInterval(intervalId);

      progressCount.current += progressIncrement.current;
      ProgressRef.current.style.width = `${progressCount.current}px`;

      GoodImageRef.current.style.display = 'none';
      CrossImageRef.current.style.display = "inline-block";
      submitButton.current.style.display = "none";
      InputRef.current.style.display = "none";
      FinishedImageRef.current.style.display = "inline-block";
    }
  }, [questionNumber, count]);






  const totalTimeTakenOfAllQuestions = useRef(0);
  const totalTimeOfAllQuestions = useRef(0);

  const submitStudentQuestionResponse = async () => {
    // event.preventDefault();

    totalTimeTakenOfAllQuestions.current += currQuestion?.timealloted - countRef.current - 1;
    totalTimeOfAllQuestions.current += currQuestion?.timealloted;

    try {
      // console.log("I am in submitting response...")
      const response = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/saveActivityQuestion`, {
        Question_id: currQuestion?.Question_id,
        Student_id: student_ID,
        timetaken: currQuestion?.timealloted - countRef.current - 1,
        StudentAnswer: studentCurrAnswerRef.current,
        MarksObtained: marksObtainedCurrQuestionRef.current,
        TotalTime: currQuestion?.timealloted,
        correct: isCorrectRef.current
      });
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }

    try {
      console.log("I am in submitting  response...")
      const response = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/saveActivity`, {
        Activity_id: activityId,
        Student_id: student_ID,
        MarksObtained: gainedMarksRef.current,
        MarksObtainedPercentage: gainedMarksRef.current / totalMarks * 100,
        Remarks: (gainedMarksRef.current / totalMarks * 100) > 90 ? 'Excellent Work' : (gainedMarksRef.current / totalMarks * 100) > 70 ? "Good Job" : "Try Again",
        SubmitActivityFile: '',
        TimeTaken: totalTimeTakenOfAllQuestions.current,
        TotalMarks: totalMarks,
        TotalTime: totalTimeOfAllQuestions.current < 0 ? 0 : totalTimeOfAllQuestions.current,
        Complete: 0,//completeActivityRef.current,
      });
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const completeActivityRef = useRef();

  const submitCompleteActivityResponse = async () => {
    // e.preventDefault();

    try {
      console.log("I am in submitting Complete response...")
      const response = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/saveActivity`, {
        Activity_id: activityId,
        Student_id: student_ID,
        MarksObtained: gainedMarksRef.current,
        MarksObtainedPercentage: gainedMarksRef.current / totalMarks * 100,
        Remarks: (gainedMarksRef.current / totalMarks * 100) > 90 ? 'Excellent Work' : (gainedMarksRef.current / totalMarks * 100) > 70 ? "Good Job" : "Try Again",
        SubmitActivityFile: '',
        TimeTaken: totalTimeTakenOfAllQuestions.current,
        TotalMarks: totalMarks,
        TotalTime: totalTimeOfAllQuestions.current,
        Complete: completeActivityRef.current,
      });
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleStudentAnswer = (event) => {
    setStudentAnswer(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(studentAnswer);

    if (questionNumber == totalQuestions) {

      if (studentAnswer === currQuestion?.correctanswer) {
        setItems([...items, 1]);
        gainedMarksRef.current = gainedMarksRef.current + marksPerQuestion;
        setGainedMarks(gainedMarks => gainedMarks + marksPerQuestion);
        CrossImageRef.current.style.display = 'none';
        GoodImageRef.current.style.display = "inline-block";

        studentCurrAnswerRef.current = studentAnswer;
        marksObtainedCurrQuestionRef.current = marksPerQuestion;
        isCorrectRef.current = 1;


        submitStudentQuestionResponse();
      }
      else {
        setItems([...items, 0]);
        GoodImageRef.current.style.display = 'none';
        CrossImageRef.current.style.display = "inline-block";

        studentCurrAnswerRef.current = studentAnswer;
        marksObtainedCurrQuestionRef.current = 0;
        isCorrectRef.current = 0;

        submitStudentQuestionResponse();
      }
      clearInterval(intervalId);
      submitButton.current.style.display = "none";
      FinishedImageRef.current.style.display = "inline-block";

      progressCount.current += progressIncrement.current;
      ProgressRef.current.style.width = `${progressCount.current}px`;

      completeActivityRef.current = 1;
      submitCompleteActivityResponse();

    }
    else {
      if (studentAnswer == currQuestion?.correctanswer) {
        items.push(1);
        gainedMarksRef.current = gainedMarksRef.current + marksPerQuestion;
        setGainedMarks(gainedMarks => gainedMarks + marksPerQuestion);
        CrossImageRef.current.style.display = 'none';
        GoodImageRef.current.style.display = "inline-block";


        studentCurrAnswerRef.current = studentAnswer;
        marksObtainedCurrQuestionRef.current = marksPerQuestion;
        isCorrectRef.current = 1;


        submitStudentQuestionResponse();
      }
      else {
        items.push(0);
        GoodImageRef.current.style.display = 'none';
        CrossImageRef.current.style.display = "inline-block";

        studentCurrAnswerRef.current = studentAnswer;
        marksObtainedCurrQuestionRef.current = 0;
        isCorrectRef.current = 0;

        submitStudentQuestionResponse();
      }

      questionNumberRef.current = questionNumberRef.current + 1;
      setQuestionNumber(questionNumber => questionNumber + 1);
      // setCurrentQuestion(questions[questionNumberRef.current]);
      setCurrQuestion(actualQuestions[questionNumberRef.current])

      console.log("..........Calling Fetch Image..........")
      fetchActivitiesImage(setImageData, actualQuestions[questionNumberRef.current].questionpicture);
      setMarksPerQuestion(actualQuestions[questionNumberRef.current].marks);

      setCount(actualQuestions[questionNumberRef.current].timealloted);
      countRef.current = actualQuestions[questionNumberRef.current].timealloted;

      progressCount.current += progressIncrement.current;
      ProgressRef.current.style.width = `${progressCount.current}px`;

    }
    setStudentAnswer("")
  }


  // const timerInterval = parseInt(process.env.REACT_APP_TIMER_INTERVAL, 1500);
  var playInterval;
  const [remainingBodyBgColor, setRemainingBodyBgColor] = useState('transparent');

  const getRandomPastelColor = () => {
    const darkColors = [
      '#1a237e', // Dark Blue
      '#1b5e20', // Dark Forest Green
      '#b71c1c', // Dark Red
      '#4a148c', // Dark Purple
      '#004d40', // Dark Teal
      '#3e2723', // Dark Brown
      '#263238', // Dark Blue Grey
      '#880e4f', // Dark Pink
      '#311b92', // Dark Indigo
      '#bf360c', // Dark Orange
      '#006064', // Dark Cyan
      '#827717', // Dark Olive
      '#ff6f00', // Dark Amber
      '#4a148c', // Dark Violet
      '#01579b', // Dark Ocean Blue
      '#33691e', // Dark Lime
      '#780116', // Dark Crimson
      '#4a1259', // Dark Magenta
      '#1a472a', // Dark British Racing Green
      '#2d132c', // Dark Plum
      '#631111', // Dark Burgundy
      '#0d47a1', // Dark Royal Blue
      '#194d19', // Dark Hunter Green
      '#4d1919', // Dark Wine
      '#2d004d'  // Dark Deep Purple
    ];
    return darkColors[Math.floor(Math.random() * darkColors.length)];
  };

  const playQuestion = () => {
    setPlayButtonVisibility(false);
    
    var index = 0;
    const strArray = currQuestion?.question?.split(" ");
    console.log("StrArray is : ", strArray);
    setAnzanQuestionPart(strArray[index]);
    setRemainingBodyBgColor(getRandomPastelColor()); // Initial color change

    playInterval = setInterval(() => {
      setAnzanQuestionPart(strArray[index + 1]);
      setRemainingBodyBgColor(getRandomPastelColor()); // New color with each number

      index++;
      if (index >= strArray.length  ) {
        stopInterval();
      }
    }, 3000);

    const stopInterval = () => {
      clearInterval(playInterval);
      setPlayButtonVisibility(true);
      setRemainingBodyBgColor('transparent'); // Reset to original color
    }
  }

  //   const stopInterval = () => {
  //     clearInterval(playInterval);
  //   }







  const handleCourseSelect = (course) => {
    console.log("Selected Course in Home:", course);
    selectedCourse(course);
  };

  const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo, userInfo } = useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
      await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
        credentials: 'include',
      }).then(response => {
        console.log("Response:", response);
        response.json().then(userInfo => {
          console.log("userinfo:", userInfo);
          setUserInfo(userInfo);

          if (userInfo?.usertype) {
            const uType = userInfo?.usertype;
            setUserRole(uType);
          }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

        })
          .catch(err => console.log("Error in useeffect:", err))
      });
    }
    fetchData();
  }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
    console.log("In home.js:", usertype)
    if (usertype === 'Student')
      return "Student";
    else if (usertype === 'Teacher')
      return "Teacher";
    else if (usertype === 'Admin')
      return "Admin";
    else
      return "guest";
  };


  const navigate = useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
    console.log("inside Onlogout.");
    fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
      credentials: 'include',
      method: 'POST',
    });
    setUserInfo(null);
    setUserRole('guest');
    navigate("/");

  }

  const username = userInfo?.username;
  const _avatar_ = userInfo?.avatar;
  const usertype = userInfo?.usertype;


  const [instructions, setInstructions] = useState(["1", "2", "3"])

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  }
  return (
    <div>



      {username && (
        <>
          {isAdmin && <AdminNav username={username} avatar={_avatar_} onLogout={onLogout} />}
          {isTeacher && <TeacherNav onCourseSelect={handleCourseSelect} username={username} avatar={_avatar_} onLogout={onLogout} />}
          {isStudent && <StudentNav username={username} avatar={_avatar_} onLogout={onLogout} />}
        </>
      )}
      {!username && (
        <>
          {isGuest && <GuestNav onLogin={onLogin} />}

        </>
      )}



      {/* <AdminNav /> */}
      <div className="MAMheading">
        <div className="MAMactivity-image">
          <img src={MentalImage} alt="" />
        </div>

        <div className="MAMactivity-text">
          {/* <h3>Scratch M4 L1 Anzan activity 1</h3> */}
          <h3>{currQuestion?.ActivityName}</h3>

        </div>
      </div>

      <div className="MAMcontainer">
        <div className="MAMhead">

          <div className="MAMquestions">

            <div className="MAMquestions-numbers">
              <p>Question <span id="MAOcurrent-question">{questionNumber}</span> of <span id="MAOtotal-questions">{totalQuestions}</span></p>
            </div>

            <div className="MAMprogress-bar">
              <div id="progress" ref={ProgressRef}></div>
            </div>


          </div>

          <div className="MAMscore">

            <div className="MAMscore-text">
              <h4>Score</h4>
            </div>

            <div className="MAMnumbered-score">
              <h2> <span id="MAOcurrent-score">{gainedMarks}</span> / <span id="MAOtotal-score">{totalMarks}</span></h2>
            </div>

          </div>

        </div>

        <div className="MAMbody">

          <div 
            className="MAMremaining-body"
            
          >

            <div className="MAMquestion"
            >

              <div className="MAMvolume">
                {/* <img src={VolumeImage} alt=""/> */}
                {/* <input type="range" name="" ref={VolumeRangeRef} /> */}
                <h1 style={{
              color: remainingBodyBgColor,
              transition: 'background-color 0.5s ease', // Smooth color transition
              fontFamily:"monospace",
              fontSize:"75px"
            }}>{AnzanQuestionPart}</h1>
                <br /><br />
              </div>

              {/* <audio autoplay id="question-audio" src=""></audio> */}

              <div className="MAMplay-button" >

                {
                 playButtonVisibility &&
                  <button className="MAMplay-question"><img src={PlayImage} alt="" onClick={playQuestion} /></button>
                  }

                <h6>Play Anzan Card</h6>
              </div>

            </div>

            <div className="MAMpicture">

              {imageData ? (
                <img
                  src={imageData}
                  alt="Question Image"
                // className="MApicture"
                />
              ) : (
                // <img src={imageData} alt="Question Image" /> 
                <p></p>
              )}
              {/* <img src="Addition-using-counting.png" alt=""/> */}

            </div>

            <div className="MAMtime-marks">
              <div className="MAMtime">
                <div className="MAMtime-text">
                  <h4>Time</h4>
                </div>
                <div className="MAMtimer">
                  <h4 id="MAtime-counter">{formatTime(count)}</h4>
                </div>
              </div>

              <div className="MAMmarks">
                <div className="MAMmarks-text">
                  <h4>Marks</h4>
                </div>
                <div className="MAMmarker">
                  <h4><span id="MAmarks-per-question">{marksPerQuestion}</span></h4>
                </div>
              </div>

            </div>

            <div className="MAMinstructions">
              <select className="MAMinstructions-dropdown" name="role">
                {/* {instructions?.map((item,index)=>(
                        <option value={`Instructions-${index+1}`}>{item}</option>
                      ))} */}
                <option value="Instructions">Instructions</option>
                <option value="Instruction-1">{currQuestion?.instructions}</option>
                {/* <option value="Instruction-2">Do not use calculator</option> */}
              </select>
            </div>

            <div class="MAOanswer-submit">
              <input id="MAstudent-answer" class="MAOanswer" type="text" ref={InputRef} placeholder="Type Answer here..." value={studentAnswer} onChange={handleStudentAnswer}
                onKeyDown={(e) => {
                  console.log("e.key", e.key)
                  if (e.key === 'Enter' && questionNumber != totalQuestions && count != 0) {
                    handleSubmit(e);
                  }
                }}
              />
              <input id="MAsubmission" type="submit" ref={submitButton} onClick={handleSubmit} />
            </div>
          </div>

          <div id="MAmarks-color">
            {
              items.map((item, index) => (
                <div className="MAbox">
                  <div className="MAbox1">
                    <h3>{index + 1}</h3>
                  </div>
                  <div className={`MAbox2${item}`}>

                  </div>
                </div>
              ))
            }

          </div>

        </div>
      </div>

      <div class="MAremarks">
        <div class="MAcorrect-wrong-picture">
          <img id="MAremarksId" src={GoodImage} ref={GoodImageRef} alt="" style={{ width: '100px', display: 'none' }} />
          <img id="remarksId" src={CrossImage} ref={CrossImageRef} alt="" style={{ width: '100px', display: 'none' }} />
        </div>

        <div class="MAfinished-picture">
          <img id="finishedId" src={FinishedImage} ref={FinishedImageRef} alt="" style={{ width: '100px', display: 'none' }} />
        </div>
      </div>
      <Footer />
    </div>


  )
}

export default MentalActivity;
