import React, { useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineCloudUpload, AiOutlineComment, AiOutlineTrophy } from "react-icons/ai";
import { toast } from "react-toastify";
import axiosInstance from "../ManageContent/managecontentforms/axiosInstance";
import "./ActivityList.css";

// Add Modal component
const RemarksModal = ({ isOpen, onClose, remarks, textColor, backgroundColor }) => {
  if (!isOpen) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
      }}
      onClick={onClose}
    >
      <div
        style={{
          backgroundColor: backgroundColor,
          padding: '20px',
          borderRadius: '8px',
          maxWidth: '500px',
          width: '90%',
          color: textColor,
          position: 'relative'
        }}
        onClick={e => e.stopPropagation()}
      >
        <button
          style={{
            position: 'absolute',
            right: '10px',
            top: '10px',
            border: 'none',
            background: 'none',
            fontSize: '18px',
            cursor: 'pointer',
            color: textColor
          }}
          onClick={onClose}
        >
          ×
        </button>
        <h3>Remarks</h3>
        <p>{remarks}</p>
      </div>
    </div>
  );
};

const ActivityList = React.memo(({
  title,
  projects,
  backgroundColor,
  textColor,
  iconBackground,
  image,
  courseID,
  activityID,
  uploadApiRoute,
  studentId,
  setRefresh = null,
  profileData
}) => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRemarks, setSelectedRemarks] = useState("");

  // Determine if time display is needed
  const shouldDisplayTime = ["Mental", "Oral", "Written", "Speed Quiz", 'MCQ'].includes(title);

  // Handle project click
  const handleProjectClick = useCallback(
    async (projectType, activityID, activityLink = null, project) => {
      let route = "";

      const updateActivity = async (activityID) => {
        try {
          const courseIDResponse = await axiosInstance.post(
            "/getActivitycompletefromStudentIF",
            {
              Activity_id: activityID,
              StudentId: studentId
            }
          );
          // Trigger refresh of parent component
          setRefresh(prev => !prev);
        } catch (error) {
          console.error("Error updating activity status:", error);
        }
      };

      if (projectType === "MCQ") {
        route = "/ActivityStartPage?activityType=MCQActivity";
        const queryParams = new URLSearchParams({
          activityId: activityID,
          studentId: studentId,
          onComplete: 'refresh'
        }).toString();
        window.open(`${route}&${queryParams}`, "_blank");
      } else if (projectType === "Written") {
        route = "/ActivityStartPage?activityType=WrittenActivity";
        const queryParams = new URLSearchParams({
          activityId: activityID,
          studentId: studentId,
          onComplete: 'refresh'
        }).toString();
        window.open(`${route}&${queryParams}`, "_blank");
      } else if (projectType === "Video") {
        // alert("Video");
        route = "/VideoActivity?";
        await updateActivity(activityID);
        window.open(`${route}&activityId=${activityID}&modulename=${profileData.modulename}&lessonname=${profileData.lessonname}`, "_blank");

      } else if (projectType === "Speed Quiz") {
        route = "/ActivityStartPage?activityType=SpeedQuizActivity";
        const queryParams = new URLSearchParams({
          activityId: activityID,
          studentId: studentId,
          onComplete: 'refresh'
        }).toString();
        window.open(`${route}&${queryParams}`, "_blank");
      } else if (projectType === "Mental") {
        route = "/ActivityStartPage?activityType=MentalActivity";
        const queryParams = new URLSearchParams({
          activityId: activityID,
          studentId: studentId,
          onComplete: 'refresh'
        }).toString();
        window.open(`${route}&${queryParams}`, "_blank");
      } else if (projectType === "Oral") {
        route = "/ActivityStartPage?activityType=OralActivity";
        const queryParams = new URLSearchParams({
          activityId: activityID,
          studentId: studentId,
          onComplete: 'refresh'
        }).toString();
        window.open(`${route}&${queryParams}`, "_blank");
      } else if (projectType === "Game") {
        await updateActivity(activityID);
        window.open(activityLink, "_blank");
      }
    },
    [studentId, setRefresh]
  );

  // Handle file selection
  const handleFileSelect = useCallback(
    async (e, projectID) => {
      const file = e.target.files[0];
      setSelectedFile(file);
      await handleUpload(file, projectID);
    },
    []
  );

  // Handle file upload
  const handleUpload = useCallback(
    async (file, projectID) => {
      try {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("Student_id", studentId);
        formData.append("Activity_id", projectID);

        const response = await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}${uploadApiRoute}`, {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          toast.success("Game Activity Uploaded", { position: toast.POSITION.TOP_RIGHT });
          setSelectedFile(null);
        } else {
          throw new Error("Upload failed");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        toast.error("Error uploading file");
      }
    },
    [uploadApiRoute, studentId]
  );

  const handleRemarksClick = useCallback((remarks) => {
    setSelectedRemarks(remarks);
    setIsModalOpen(true);
  }, []);

  // Add navigation handler for leaderboard
  const handleLeaderboardClick = (activityId) => {
    window.open(`/leaderboard?studentId=${studentId}&activityId=${activityId}`, "_blank");
  };

  return (
    // <div className="activity-row">
    <div className="AP_ModuleProjectLesson" style={{ backgroundColor }}>
      <h2 className="AP_title" style={{ color: textColor }}>
        <img src={image} alt="" style={{ width: "7vh", height: "7vh" }} /> {title} Activities
      </h2>
      <ul style={{ marginLeft: "0", paddingLeft: "0" }}>
        {projects.map((project, index) => {
          const { Activity_id, Lesson_Activity_id, ActivityName, MarksObtained, TotalMarks, TimeTaken, TotalTime, Complete, Remarks } = project.activity;
          const projectID = Activity_id || Lesson_Activity_id;

          return (
            <li key={index} className="AP_Projects">
              <div className="AP_ProjectItem">
                <div style={{ maxWidth: "100%", marginBottom: "5px" }}>
                  <span
                    style={{
                      color: textColor,
                      backgroundColor: (MarksObtained / TotalMarks * 100) > 75 ? "lightgreen" : (title == "Video" && Complete) ? "lightgreen" : "lightgrey",
                      borderRadius: "7vh",
                      padding: "1px 8px",
                      marginRight: "5px",
                    }}
                  ></span>
                  <Link
                    to=""
                    onClick={() => handleProjectClick(title, projectID, project?.activity?.ActivityLink, project)}
                    style={{ marginRight: "7%", color: textColor }}
                  >
                    {ActivityName}
                  </Link>
                </div>

                {shouldDisplayTime ? (
                  <div style={{ display: "flex" }}>
                    <span
                      style={{
                        color: textColor,
                        backgroundColor: iconBackground,
                        display: "flex",
                        flexDirection: "column",
                        width: "65px",
                        alignItems: "center",
                      }}
                      className="AP_Score"
                    >
                      {MarksObtained}/{TotalMarks}
                      <span style={{ fontSize: "11px" }}>
                        ({TotalMarks > 0 ? ((MarksObtained / TotalMarks) * 100).toFixed(1) : "0"}%)
                      </span>
                    </span>
                    <span
                      style={{
                        color: textColor,
                        backgroundColor: iconBackground,
                        display: "flex",
                        flexDirection: "column",
                        minWidth: "60px",
                        maxWidth: "70px",
                        alignItems: "center",
                      }}
                      className="AP_Time"
                    >
                      {(TimeTaken / 60).toFixed(0)}/{(TotalTime / 60).toFixed(0)}
                      <span style={{ fontSize: "11px" }}>min</span>
                    </span>
                    {Remarks && (
                      <div style={{ display: "flex", alignItems: "center", marginLeft: "10px", justifyContent: "center", cursor: "pointer" }}>
                        <span
                          style={{
                            color: textColor,
                            backgroundColor: iconBackground,
                            display: "flex",
                            flexDirection: "column",
                            width: "50px",
                            height: "50px",
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                          className="AP_Score"
                          onClick={() => handleRemarksClick(Remarks)}
                          title="Click to view remarks"
                        >
                          <AiOutlineComment size={24} />
                        </span>
                      </div>
                    )}

                    {title === "Speed Quiz" && (
                      <div style={{ display: "flex", alignItems: "center", marginLeft: "10px", justifyContent: "center", cursor: "pointer" }}>
                        <span
                          style={{
                            color: textColor,
                            backgroundColor: iconBackground,
                            display: "flex",
                            flexDirection: "column",
                            width: "50px",
                            height: "50px",
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                          className="AP_Score"
                          onClick={() => handleLeaderboardClick(projectID)}
                          title="View Leaderboard"
                        >
                          <AiOutlineTrophy size={24} />
                        </span>
                      </div>
                    )}
                  </div>
                ) : title !== "Video" && title !== 'MCQ' ? (
                  <label htmlFor={`Gfile-upload-${index}`}>
                    <span
                      style={{
                        color: textColor,
                        backgroundColor: iconBackground,
                      }}
                      className="AP_Icon"
                    >
                      <AiOutlineCloudUpload size={40} />
                    </span>
                    <input
                      id={`Gfile-upload-${index}`}
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => handleFileSelect(e, projectID)}
                    />

                  </label>
                ) : null}


              </div>
              <hr />
            </li>
          );
        })}
      </ul>

      <RemarksModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        remarks={selectedRemarks}
        textColor={textColor}
        backgroundColor={backgroundColor}
      />
    </div>
    // </div>
  );
});

export default ActivityList;
