import React,{useContext,useEffect} from 'react'
import { Link } from "react-router-dom";
import "./EngineeringCourseDetails.css";
import "../MathPortal/Slider.css";
import "../MathPortal/MathCourseDetails.css";
import "../MathPortal/MathPortalResponsive.css";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import pic1 from "./EngineeringCourseImages/civil.png";
import pic2 from "./EngineeringCourseImages/Electrical.png";
import pic3 from "./EngineeringCourseImages/Mechanical.png";
import pic4 from "./EngineeringCourseImages/IOT.png";

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from '../Footer'
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import FeeStructure from '../components/FeeStructure';
import ECD from './EngineeringCourseImages/EnggBackground.png'

const EngineeringCourseDetails = ({selectedCourse}) => {
  const handleCourseSelect = (course) => {
    console.log("Selected Course in Home:", course);
    selectedCourse(course);
  };

 const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };
  
  
 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
      navigate("/");

    }
      
      const username = userInfo?.username;
      const _avatar_ = userInfo?.avatar;
   
return (
  <>
    {/* Navbar */}
    {username && (
       <>
       {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
       {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
       {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
     </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}

    <div   >
    <div className="EnggPortalBackground">
       <section className="section">
         <p className="PageHead" style={{ color: "#8b0194" }}>Engineering Portal</p>
         <div className="PageDesc">
         <div style={{display:"flex"}}>
              <p style={{
                fontFamily: "KG Primary Whimsy,VanillaMilk,'DM Sans', sans-serif",
                color: "white",
                textAlign: "left",
                padding: "30px",
                fontSize: "22px",
                zIndex: 1,
                color: "#8b0194",
                width:"70%"
                
              }}>
           In this technological era, it is of utmost importance that we equip our kids with the coding skills which helps them with the logic, structure, creative thinking, self-expression, problem solving, sequencing and analytical thinking. Polymath-Kids offer a complete roadmap which includes the following courses.
           </p>
           <img style={{minWidth:"250px", maxWidth:"450px"}} src={ECD}></img>
           </div>
           <div className="btn-row d-flex btn-row-resp btn-row-resp1">

             {/* <div className="btn-row-resp"> */}
             <div style={{color:"#e2e872", width: "auto", height: "50px" }}>


               <a className="btn-row-resp button " href="#course">Individual Courses</a>
             </div>
             

             <div style={{ width: "auto", height: "50px" }}>


               <a className="btn-row-resp button btn-color-fee" href="#fee">Fee Structure</a>
             </div>
             
             <div style={{ width: "auto", height: "50px" }}>

               <a className=" btn-row-resp button btn-color-Testimonal" href="#testimonal">Testimonals</a>
             </div>
             <div style={{  height: "50px" }}>
               <a style={{padding:"3.5vh 11vh 3.7vh 11vh"}} className=" btn-row-resp button btn-color-Faq" href="#Faq">Faqs</a>


             </div>
             <div style={{ width: "auto", height: "50px" }}>
             <a  className=" btn-row-resp button btn-color-registered" href="#">Get Registered</a> 
              

             </div>


        
           </div>
         </div>

       </section>
       <div className="overlay"></div>
     </div>
     
{/* Individual courses */}
<div style={{ backgroundColor: '#d8f2fb' }} id="course">
       <p className="PageHead mx-auto" style={{ color: "#8b0194" }}>
       Engineering Individual Courses 
       </p>

       <div className="container"  >

         <MDBRow>
           <MDBCol md='4'>
             <div className=" col-xs-6">
               <Link to="/engineering_civil">
                 <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                   <h3 className="ind-course-title ">Civil Engineering</h3>
               <div>
                     <img src={pic1} alt="" style={{width:"100px",height:"100px"}}/>
                 </div>
                 
                    </div>
                
               </Link>
             </div>
           </MDBCol>
           <MDBCol md='4'>
             <div className=" col-xs-6">
               <Link to="#">
                 <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                   <h3 className="ind-course-title ">Mechanical Engineering
                   <b> (Coming Soon)</b>
                   </h3>
               <div>
                     <img src={pic2} alt="" style={{width:"100px",height:"100px"}}/>
                 </div>
                 
                    </div>
                
               </Link>
             </div>
           </MDBCol>
           <MDBCol md='4'>
             <div className=" col-xs-6">
               <Link to="#">
                 <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                   <h3 className="ind-course-title ">Electrical Engineering
                   <b> (Coming Soon)</b>
                   </h3>
               <div>
                     <img src={pic3} alt="" style={{width:"100px",height:"100px"}}/>
                 </div>
                 
                    </div>
                
               </Link>
             </div>
           </MDBCol>
           <MDBCol md='4'>
             <div className=" col-xs-6">
               <Link to="#">
                 <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                   <h3 className="ind-course-title ">IOT & Robotics
                   <b> (Coming Soon)</b>
                   </h3>
               <div>
                     <img src={pic4} alt="" style={{width:"100px",height:"100px"}}/>
                 </div>
                 
                    </div>
                
               </Link>
             </div>
           </MDBCol>
         </MDBRow>
       </div>
     </div>


    {/* Fee Structure */}
    <FeeStructure subject="Engineering"/>
  
     {/* Testimonals */}
     <div class="  background" id="testimonal">
          <div class="row" style={{ width: "100%" }}>
            <div class="col-lg-8 mx-auto">
              <h2 className="Testimonal_Heading">Testimonials</h2>
              <div id="myCarousel" class="carousel slide" data-ride="carousel">
                {/* <!-- Carousel indicators --> */}
                <ol class="carousel-indicators">
                  <li
                    data-target="#myCarousel"
                    data-slide-to="0"
                    class="active"
                  ></li>
                  <li data-target="#myCarousel" data-slide-to="1"></li>
                </ol>
                {/* <!-- Wrapper for carousel items --> */}
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div class="img-box">
                      <div className=" img-box1"></div>
                    </div>
                    <p
                      class="testimonial"
                      style={{ color: "black", fontSize: "3vh" }}
                    >
                      I enjoyed the learning experience with Polymath-Kids-Abacus.
                      The teacher was very friendly. I highly recommend
                      Polymath-Kids-Abacus.
                    </p>
                    <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                      <b>Mujtaba Khawaja Sahaf</b>
                    </p>
                  </div>

                  <div class="carousel-item">
                    <div class="img-box">
                      <div className="img-box2 "></div>
                    </div>
                    <p
                      class="testimonial"
                      style={{ color: "black", fontSize: "3vh" }}
                    >
                      I feel so proud when my class fellows call me ’BRAINIAC ’,
                      because I can effortlessly do mental calculations much
                      faster and accurate than my class mates. Thank you
                      Polymath-Kids.
                    </p>
                    <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                      <b>Wahaab Omer</b>, Age 9 Years
                    </p>
                  </div>
                </div>
                {/* <!-- Carousel controls --> */}
                <a
                  class="carousel-control-prev"
                  href="#myCarousel"
                  data-slide="prev"
                >
                  <i class="fa fa-angle-left"></i>
                </a>
                <a
                  class="carousel-control-next"
                  href="#myCarousel"
                  data-slide="next"
                >
                  <i class="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
    

     {/* Faqs */}
     <div style={{ maxWidth: '100%', backgroundColor: '#edffff' }} id="Faq">

       <MDBContainer className="mt-0" style={{ maxWidth: '180vh' }}>
         <MDBAccordion alwaysOpen initialActive={1} >
           <div className="text-center PageHead" style={{ color: "#8b0194" }}> FAQs</div>
           <MDBAccordionItem collapseId={1} headerTitle="Q: What are the various engineering courses offered by Polymath-Kids?" >
             <strong>A: </strong>
             Polymath-Kids is one of the best platform for kids to learn engineering. At the moment we are offering civil engineering course for kids of grade 8-9, 9-10 and 10-12, We are developing other engineering courses at the moment which will be made available soon. All courses are offered in group mode as well as one on one with the expert instructors with years of experience.
            </MDBAccordionItem>
           

           <MDBAccordionItem collapseId={3} headerTitle="Q: Which is the best Engineering platform for Kids?">
             <strong>A: </strong>
             Polymath-Kids is the best Engineering platform for kids aged 13 to 18 years old. A variety of courses are offered in a fun engaging way through a personalized learning platform for each child. In each lesson, kids get the opportunity to expand on to their knowledge through hands on activities. A project is completed at the end of each module to enable kids to incorporate the learned concepts to express their creativity.
           </MDBAccordionItem>

        

           <MDBAccordionItem collapseId={3} headerTitle="Q: Which age group is suitable for learning Engineering?">
             <strong>A: </strong>
             Students as young as 13 years can be introduced to engineering. We have developed separate customized curriculum for grade 8
             to 9, 9 to 10 and 10 to 12.
           </MDBAccordionItem>
        </MDBAccordion>
      </MDBContainer>

     </div>

 </div>
 
{/* Footer */}
<Footer/>

 </>
    
  )
}

export default EngineeringCourseDetails
