import React, { useState, useContext, useEffect } from 'react';
import './PolymatchReg.css'; // Make sure to link the CSS file
import polymatchImage from './Polymatch.png';
import { UserContext } from '../UserContext';
import StudentNav from '../Navbars/StudentNav';
import AdminNav from '../Navbars/AdminNav';
import GuestNav from '../Navbars/GuestNav';
import TeacherNav from '../Navbars/TeacherNav';
import Footer from "../Footer";
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import Select from 'react-select'; // Import react-select
import countryList from 'react-select-country-list'; // Import react-select-country-list
import ReactFlagsSelect from "react-flags-select";
import { toast } from 'react-toastify';

const PolymatchReg = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    age: '',
    gender: '',
    parentName: '',
    whatsapp: '',
    email: '',
    address: '',
    city: '',
    country: '',
    school: null,
    childPhoto: null,
  });

  const [countries, setCountries] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [competitionData, setCompetitionData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state for button loading

  const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo, userInfo } = useContext(UserContext);
  const navigate = useNavigate();

  const formattedDate = new Date(competitionData?.Competition_date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  useEffect(() => {
    const fetchData = async () => {
      await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
        credentials: 'include',
      }).then((response) => {
        response.json().then((userInfo) => {
          setUserInfo(userInfo);
          if (userInfo?.usertype) {
            setUserRole(userInfo?.usertype);
          }
        });
      });
    };

    const fetchCompetitionData = async () => {
      await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/polymatchcompetition`, {
        credentials: 'include',
      }).then((response) => {
        response.json().then((CompetitionData) => {
          setCompetitionData(CompetitionData);
        });
      });
    };

    fetchData();
    fetchCompetitionData();

    const options = countryList().getData();
    setCountries(options);
  }, [isAdmin, isTeacher, isStudent, isGuest]);

  const onLogout = () => {
    fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
      credentials: 'include',
      method: 'POST',
    });
    setUserInfo(null);
    setUserRole('guest');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const maxSize = 200 * 1024; // 200kb in bytes
      if (file.size > maxSize) {
        toast.error('Image size should not be more than 200kb.', {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
      setFormData({
        ...formData,
        childPhoto: file,
      });
      setImagePreview(URL.createObjectURL(file));
    }
  };

  // const handlePhotoChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     setFormData({
  //       ...formData,
  //       childPhoto: file,
  //     });
  //     setImagePreview(URL.createObjectURL(file));
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable button and show loading

    const formDataToSend = new FormData();
    formDataToSend.append('firstName', formData.firstName);
    formDataToSend.append('lastName', formData.lastName);
    formDataToSend.append('age', formData.age);
    formDataToSend.append('gender', formData.gender);
    formDataToSend.append('parentName', formData.parentName);
    formDataToSend.append('whatsapp', formData.whatsapp);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('address', formData.address);
    formDataToSend.append('city', formData.city);
    formDataToSend.append('school', formData.school);
    formDataToSend.append('country', formData.country);
    formDataToSend.append('childPhoto', formData.childPhoto);
    formDataToSend.append('Competition_Id', competitionData.Competition_Id);

    try {
      const response = await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/polymatchregister`, {
        method: 'POST',
        body: formDataToSend,
      });

      if (response.ok) {
        alert(
          'Thank you for registering for the competition. A member of our team will contact you shortly through the given WhatsApp number to provide payment details.'
        );
        setFormData({
          firstName: '',
          lastName: '',
          age: '',
          gender: '',
          parentName: '',
          whatsapp: '',
          email: '',
          address: '',
          city: '',
          country: '',
          school: null,
          childPhoto: null,
        });
        setImagePreview(null);
      } else {
        alert('Registration failed.');
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
    } finally {
      setIsSubmitting(false); // Re-enable button
    }
  };

  const handleCountryChange = (selectedOption) => {
    setFormData({
      ...formData,
      country: selectedOption.label,
    });
  };

  const username = userInfo?.username;
  const _avatar_ = userInfo?.avatar;

  return (
    <>
      {username && (
        <>
          {isAdmin && <AdminNav username={username} avatar={_avatar_} onLogout={onLogout} />}
          {isTeacher && <TeacherNav username={username} avatar={_avatar_} onLogout={onLogout} />}
          {isStudent && <StudentNav username={username} avatar={_avatar_} onLogout={onLogout} />}
        </>
      )}
      {!username && isGuest && <GuestNav />}

      <div className="registration-container">
        <div className="registration-left">
          <img src={polymatchImage} alt="Polymatch Logo" className="polymatch-image" />
          <h1>
            {competitionData?.Competition_Name} {new Date(competitionData?.Competition_date).getFullYear()}
          </h1>
        </div>

        <div className="registration-right">
          <form onSubmit={handleSubmit}>
            <h2>
              Register for {competitionData?.Competition_Name} being held on {formattedDate}
            </h2>
            <p className="PReg">
              Please make sure to enter the correct details below as this will be used to generate the
              certificate of your child.
            </p>

            <input
              type="text"
              name="firstName"
              placeholder="First Name of Child"
              value={formData.firstName}
              onChange={handleInputChange}
              required
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name of Child"
              value={formData.lastName}
              onChange={handleInputChange}
              required
            />
            <select
              name="age"
              value={formData.age}
              onChange={handleInputChange}
              required
            >
              <option value="">Age of Child</option>
              {[...Array(15).keys()].map((age) => (
                <option key={age + 4} value={age + 4}>
                  {age + 4}
                </option>
              ))}
            </select>
            <select
              name="gender"
              value={formData.gender}
              onChange={handleInputChange}
              required
            >
              <option value="">Gender of Child</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>

            <input
              type="text"
              name="parentName"
              placeholder="Parent Name"
              value={formData.parentName}
              onChange={handleInputChange}
              required
            />
            <input
              type="text"
              name="whatsapp"
              placeholder="WhatsApp Number"
              value={formData.whatsapp}
              onChange={handleInputChange}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '');
              }}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <input
              type="text"
              name="address"
              placeholder="Address"
              value={formData.address}
              onChange={handleInputChange}
              required
            />
            <input
              type="text"
              name="city"
              placeholder="City"
              value={formData.city}
              onChange={handleInputChange}
              required
            />
            <input
              type="text"
              name="school"
              placeholder="School"
              value={formData.school}
              onChange={handleInputChange}
            />

            <Select
              options={countries}
              value={countries.find((country) => country.label === formData.country)}
              onChange={handleCountryChange}
              placeholder="Choose your Country"
              required
            />

            <div className="file-upload">
              <label htmlFor="childPhoto">Click to upload Child's picture</label>
              <input
                type="file"
                id="childPhoto"
                name="childPhoto"
                onChange={handlePhotoChange}
              />
              {imagePreview && (
                <div className="image-preview">
                  <img src={imagePreview} alt="Child Preview" className="preview-image" />
                </div>
              )}
            </div>
            <br></br>
            <p><b>Note:</b> If Registration form doesn't load/work, please inform us on WhatsApp <b> (+92 3266699907) </b> <b> (+47 47650619) </b>, our representative will register your profile </p>
            <button type="submit" className="register-button" disabled={isSubmitting}
            style={{ 
              cursor: isSubmitting ? 'not-allowed' : 'pointer',
              opacity: isSubmitting ? 0.7 : 1 
            }}
          >
              {isSubmitting ? 'Registering...' : 'Register'}
            </button>
          </form>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PolymatchReg;
