import React, { useContext, useEffect, useState } from "react";
import AdminNav from "../Navbars/AdminNav";
import StudentNav from "../Navbars/StudentNav";
import GuestNav from "../Navbars/GuestNav";
import Footer from "../Footer";
import { UserContext } from "../UserContext";
import TeacherNav from "../Navbars/TeacherNav";
import "./MyProgress.css";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import pic1 from "../CourseImages/progress.png";

import axios from "axios";
import { useLocation } from "react-router-dom";

const StudentProgress = () => {
  const location = useLocation();
  const studentName = location.state?.studentName
  const selectedStudentId = location?.state?.studentId;
  const [coursesList, setCoursesList] = useState([])
  const [teacherId, setTeacherId] = useState("")
  const {
    isAdmin,
    isTeacher,
    isStudent,
    isGuest,
    setUserRole,
    setUserInfo,
    userInfo,
  } = useContext(UserContext);

  // var user_id = "";
  const [studentId, setStudentId] = useState("");
  const [pdfUrl, setPdfUrl] = useState(null);
  async function teacherIdFunction(User_id){
    return await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/teacherFromId/${User_id}`).then((res)=>{
      console.log("res teacher id",res.json().then((data)=>{
        setTeacherId(data?.teacher_id)
        axios.get(
          `${process.env.REACT_APP_LOCAL_SERVER_API}/getCoursesByteacherId/${selectedStudentId}/${data?.teacher_id}`
        ).then((res) => {
          console.log("setCoursesList : ", res?.data?.Courses)
          setCoursesList(res?.data?.Courses)
        });
        
      //   axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getStudentsDashboardByCourse/${data?.teacher_id}`)
      //   .then(response => {
      //     console.log("response.data.Students data:", response.data?.courseInfo)
      //     setStudentsData(response?.data?.courseInfo.map((item) => ({ ...item, activeCourse: 0, activeModule: 0, activeLesson: 0 })));
      //     // console.log(response)
      //   })
      //   .catch(error => {
      //     console.error('Error fetching Students:', error);
      //   });
      //   axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getAllCoursesByTeacher/${data?.teacher_id}`)
      // .then(response => {
      //   console.log("response.data.Students data:", response.data?.courseInfo)
      //   setCourses(response?.data);
      //   // console.log(response)
      // })
      // .catch(error => {
      //   console.error('Error fetching Students:', error);
      // });
      //   setUserId(data?.teacher_id);
      }))
      
    })
  }
  // getCourses
  useEffect(() => {
    const fetchData = async () => {
      await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
        credentials: "include",
      }).then((response) => {
        console.log("Response:", response);
        response
          .json()
          .then((userInfo) => {
            console.log("userinfo:", userInfo);
            setUserInfo(userInfo);

            if (userInfo?.usertype) {
              const uType = userInfo?.usertype;
              setUserRole(uType);
            }
            const uname = userInfo?.username;
            const avatar = userInfo?.avatar; // Store the avatar in a separate variable
            console.log("Uname: ", uname);
            console.log("Avatar: ", avatar);

            console.log("UserID: ", userInfo.User_id);
            teacherIdFunction(userInfo.User_id)
            const user_id = userInfo.User_id;
            getStudentIdFromUser(user_id);
          })
          .catch((err) => console.log("Error in useeffect:", err));
      });
    };
   

    const getStudentIdFromUser = async (user_id) => {

      console.log("User id received is: ", user_id)

      try {
        const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getStudentId/${user_id}`);

        if (response?.data.success) {
          setStudentId(response?.data.Student_id);
        } else {
          console.error(response?.data.Message);
          setStudentId(null);
        }
      } catch (error) {
        console.error('Error fetching student ID:', error);
        setStudentId(null);
      }


      // try {
      //   const response = await axios.post(
      //     `${process.env.REACT_APP_LOCAL_SERVER_API}/getStudentIDfromUser`,
      //     {
      //       UserId: user_id,
      //     }
      //   );
      //   if (response?.data && response?.data.length > 0) {
      //     const { Student_id } = response?.data[0];
      //     console.log("This is StudentId: ", Student_id);

      //     setStudentId(Student_id);
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
    };

    fetchData();
  }, [isAdmin, isTeacher, isStudent, isGuest]);

  const determineUserRole = (usertype) => {
    console.log("In home.js:", usertype);
    if (usertype === "Student") return "Student";
    else if (usertype === "Teacher") return "Teacher";
    else if (usertype === "Admin") return "Admin";
    else return "guest";
  };

  const navigate = useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    //  console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  async function onLogout() {
    console.log("inside Onlogout.");
    await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
      credentials: "include",
      method: "POST",
    });
    setUserInfo(null);
    setUserRole("guest");
    navigate("/");
  }
  const username = userInfo?.username;
  const _avatar_ = userInfo?.avatar;

  const [averageMarksObtainedPercentage, setAverageMarksObtainedPercentage] =
    useState(null);
  const [averageProjectScorePercentage, setAverageProjectScorePercentage] =
    useState([]);
  const [totalPointsEarned, setTotalPointsEarned] = useState([]);

  const calculateAverageScoreActivities = async (courseId, studentId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_SERVER_API}/getActivitiesAverageMarksObtained/${courseId}/${studentId}`
      );
      const _averageMarksObtainedPercentage =
        response?.data.ActivitiesMetrics[0].AverageMarksPercentage;
      console.log(
        "Average Marks Obtained Percentage: ",
        _averageMarksObtainedPercentage
      );
      setAverageMarksObtainedPercentage(Math.round(_averageMarksObtainedPercentage));
      // Calculate the average score for activities based on the activitiesMetrics

      // ...
      // Your logic to calculate the average score for activities
    } catch (error) {
      console.log(error);
      // Handle the error or throw it further if needed
    }
  };

  const calculateAverageScoreProject = async (courseId, studentId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_SERVER_API}/getAverageProjectScore/${courseId}/${studentId}`
      );
      console.log("Response: ", response?.data.combinedAverageMarksPercentage);
      const combinedAverageMarksPercentage =
        response?.data.CombinedAverageMarksPercentage;
      console.log("Average Project Sore: ", combinedAverageMarksPercentage);
      setAverageProjectScorePercentage(combinedAverageMarksPercentage);
      // Calculate the average score for activities based on the activitiesMetrics

      // ...
      // Your logic to calculate the average score for activities
    } catch (error) {
      console.log(error);
      // Handle the error or throw it further if needed
    }
  };

  const calculatePointsEarned = async (courseId, studentId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_SERVER_API}/courseMarks/${courseId}/${studentId}`
      );
      console.log("response: ", response?.data);
      setTotalPointsEarned((response?.data.TotalMarks - (response?.data.TotalMarks*0.7) ));
      // const _averageMarksObtainedPercentage = response?.data.ActivitiesMetrics[0].AverageMarksPercentage;
      // console.log("Average Marks Obtained Percentage: ", _averageMarksObtainedPercentage);
      // setAverageMarksObtainedPercentage(_averageMarksObtainedPercentage);
      // Calculate the average score for activities based on the activitiesMetrics

      // ...
      // Your logic to calculate the average score for activities
    } catch (error) {
      console.log(error);
      // Handle the error or throw it further if needed
    }
  };

  const handlePDF = async (courseId, studentId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_SERVER_API}/getCertificateFile/${studentId}/${courseId}`
      );
      const pdfFileName = response?.data.CertificateFile;
      console.log("PDF Certificate.......: ", response?.data.CertificateFile);

      console.log("PDF Certificate.......: ", response?.data);

      if (pdfFileName != undefined) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_LOCAL_SERVER_API}/getCertificate/${pdfFileName}`,
            {
              responseType: "blob",
            }
          );

          // Convert the Blob to a URL that can be used in an <iframe>
          const url = URL.createObjectURL(response?.data);
          setPdfUrl(url);
        } catch (error) {
          console.error("Error fetching PDF:", error);
        }
      } else {
        setPdfUrl(null);
      }
      // setAverageMarksObtainedPercentage(_averageMarksObtainedPercentage);
      // Calculate the average score for activities based on the activitiesMetrics

      // ...
      // Your logic to calculate the average score for activities
    } catch (error) {
      console.log(error);
      // Handle the error or throw it further if needed
    }
  };

  const getRemarks = (courseId, studentId) => {
    // Write your logic to generate remarks based on the provided CourseID and StudentID
  };

  const [courses, setCourses] = useState([]);
  // const [studentId, setStudentId] = useState(""); // Assuming you have a way to set the student ID

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_LOCAL_SERVER_API}/getCoursesByteacherId/${studentId}/${teacherId}`
        );
        console.log("Courses: ....", response?.data.Courses);
        setCourses(response?.data.Courses);
        setSelectedCourse(response?.data.Courses[0]);

        calculateAverageScoreActivities(
          response?.data.Courses[0].Course_id,
          studentId
        );
        calculateAverageScoreProject(
          response?.data.Courses[0].Course_id,
          studentId
        );
        calculatePointsEarned(response?.data.Courses[0].Course_id, studentId);
        handlePDF(response?.data.Courses[0].Course_id, studentId);
      } catch (error) {
        console.log(error);
      }
    };

    if (studentId && teacherId) {
      fetchCourses();
    }
  }, [studentId]);

  const [selectedCourse, setSelectedCourse] = useState("");

  const handleCourseChange = (event) => {
    const selectedValue = event.target.value;
    console.log("selectedValue: ", selectedValue);
    setSelectedCourse(selectedValue);
    // /getCertificate/:s_id/:c_id
    axios.get(
      `${process.env.REACT_APP_LOCAL_SERVER_API}/getCertificate/${selectedStudentId}/${selectedCourse}`
    ).then((res) => {
      console.log("setCoursesList : ", res?.data?.Courses)
      setPdfUrl(res?.data)
    })
    // /students_course_marks/:studentId/:courseId
    axios.get(
      `${process.env.REACT_APP_LOCAL_SERVER_API}/students_course_marks/${selectedStudentId}/${selectedCourse}`
    ).then((res) => {
      const total = res?.data?.total
      const obtain = res?.data?.obtain
      if (total) {
        // setAverageMarksObtainedPercentage(Math.round((obtain/total)*100));

      }
      else {
        setAverageMarksObtainedPercentage(0)

      }

    }).catch((error) => {
      setAverageMarksObtainedPercentage(0)
    })

    // setAverageMarksObtainedPercentage(0);
    calculateAverageScoreActivities(selectedCourse, selectedStudentId);
    calculateAverageScoreProject(selectedCourse, selectedStudentId);
    calculatePointsEarned(selectedCourse, selectedStudentId);
    handlePDF(selectedCourse, selectedStudentId);
    // Call your desired function with the selected value here
    // Example: yourFunction(selectedValue);
  };

  return (
    <>
      {/* Navbar */}
      {username && (
        <>
          {isAdmin && (
            <AdminNav
              username={username}
              avatar={_avatar_}
              onLogout={onLogout}
            />
          )}
          {isTeacher && (
            <TeacherNav
              username={username}
              avatar={_avatar_}
              onLogout={onLogout}
            />
          )}
          {isStudent && (
            <StudentNav
              username={username}
              avatar={_avatar_}
              onLogout={onLogout}
            />
          )}
        </>
      )}
      {!username && <>{isGuest && <GuestNav onLogin={onLogin} />}</>}

      <div
        style={{
          backgroundColor: "#edffff",
          height: "max-content",
          padding: "4vh",
        }}
      >
        <div className="coursesDropDown">
          <select
            name=""
            id=""
            className="coursesOption"
            value={selectedCourse}
            onChange={handleCourseChange}
          >
            <option>Select Course</option>
            {/* coursesList */}
            {coursesList?.map((course) => (

              <option key={course?.Course_id} value={course?.Course_id}>
                {course?.coursename}
              </option>
            ))}
            {/* {courses.map((course) => (
              <option key={course.Course_id} value={course.Course_id}>
                {course.coursename}
              </option>
            ))} */}
          </select>
        </div>

        <div className="student-progress">
          <div className="progressHead">
            <img
              src={pic1}
              alt=""
              style={{ width: "10%", height: "10%", margin: "2%" }}
            />
            <h3 style={{ fontSize: "5vh" }}>Student - Progress <br /> {studentName} </h3> <br />
          </div>
          <h5>{selectedCourse?.coursename}</h5>

          <br />

          <div className="studentProgressSection">
            <div className="studentProgressGrid">
              <div>
                <label htmlFor="">Certificate(s)</label>
                <div className="MyProgressRemarks">
                  <div>
                    {pdfUrl ? (
                      <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                        <p>Certificate</p>
                      </a>
                    ) : (
                      <p>NO</p>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <label htmlFor="">Remarks</label>
                <div className="MyProgressRemarks">
                  <i
                    style={{ color: "#da831e", fontSize: "4vh" }}
                    className="fa-regular fa-message"
                  ></i>
                </div>
              </div>
              <div>

                <label htmlFor="">Avg. score Activities</label>
                <div className="MyProgressRemarks">
                  <b style={{ color: "#da831e" }}>
                    {averageMarksObtainedPercentage?.toFixed(1)}%
                  </b>
                </div>
              </div>

              <div>
                <label htmlFor="">Avg. score Project</label>
                <div className="MyProgressRemarks">
                  {typeof averageProjectScorePercentage === "number" &&
                    !isNaN(averageProjectScorePercentage) ? (
                    <b style={{ color: "#da831e" }}>
                      {averageProjectScorePercentage?.toFixed(1)}%
                    </b>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="">Points Earned</label>
                <div className="MyProgressRemarks">
                  <b style={{ color: "#da831e" }}>{Math.round(totalPointsEarned)}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default StudentProgress;
