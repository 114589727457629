import React,{useContext} from 'react'
import './DescriptiveActivity.css'


import StudentNav from '../../Navbars/StudentNav'
import AdminNav from '../../Navbars/AdminNav'
import TeacherNav from '../../Navbars/TeacherNav';
import GuestNav from '../../Navbars/GuestNav';
import { UserContext } from '../../UserContext';
import Footer from '../../Footer'

import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import { useLocation } from "react-router-dom";

import MCQActivityImage from './mental-activity.png'
import GoodImage from './good.png'
import CrossImage from './cross.png'
import FinishedImage from './finished.jpeg'
import { useRef, useState, useEffect } from 'react'
import axios from 'axios';
// import AdminNav from '../../Navbars/AdminNav'
// import Footer from '../../Footer'




// const DescriptiveActivity = () => {
//     const [timer, setTimer] = useState(0);
  
//     useEffect(() => {
//       // Timer functionality
//       const interval = setInterval(() => {
//         setTimer((prevTimer) => prevTimer + 1);
//       }, 1000);
  
//       // Clean up the interval on unmount
//       return () => clearInterval(interval);
//     }, []);
  
//     const handleUpload = (event) => {
//       const file = event.target.files[0];
//       // Handle the file upload logic here
//     };
  
//     return (
//       <div>
//         <h1>Exam Questions</h1>
//         <div>Timer: {timer} seconds</div>
//         <div>
//           {/* Display the questions */}
//           <ol>
//             <li>Question 1</li>
//             <li>Question 2</li>
//             <li>Question 3</li>
//             {/* Add more questions here */}
//           </ol>
//         </div>
//         <div>
//           {/* Upload document */}
//           <input type="file" onChange={handleUpload} />
//         </div>
//       </div>
//     );
//   };


// export default DescriptiveActivity;









const ExamActivity = () => {


    const selectedCourse = ""
    // const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);
    // const activityId = searchParams.get("activityId");

    const activityId = 1;

    const [actualQuestions, setActualQuestions] = useState([]);
    const [currQuestion, setCurrQuestion] = useState([]);

    const [count, setCount] = useState();
    const countRef = useRef();

    const [hours, setHours] = useState(0);
    const hoursRef = useRef();

    const [minutes, setMinutes] = useState();
    const minutesRef = useRef();

    useEffect(() => {
        // Define an async function to fetch the questions from the API
        async function fetchQuestions() {
          try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getActivityQuestions/${activityId}`);
            setActualQuestions(response.data);
            console.log(response.data[0]);
            setCurrQuestion(response.data[0]);
            setMarksPerQuestion(response.data[0].marks);

            const timeResponse = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getActivityTime/${activityId}`);

            console.log("Time Response: ", timeResponse);


            var calculatedHours = Math.floor(timeResponse.data[0].TotalTime / 60); // Get the whole number of hours
            var calculatedMinutes = timeResponse.data[0].TotalTime % 60;

            if(calculatedMinutes === 0 && calculatedHours > 0){
                calculatedHours = calculatedHours - 1;
                calculatedMinutes = 60;
            }


            setCount(60);
            countRef.current = 60;
            setMinutes(calculatedMinutes - 1);
            minutesRef.current = calculatedMinutes - 1;
            setHours(calculatedHours);
            hoursRef.current = calculatedHours;


            var total_marks = 0;
            var total_questions = 0;
            response.data.forEach(data => {
                total_marks += data.marks;
                total_questions++;
            });

            setTotalMarks(total_marks);
            setTotalQuestions(total_questions);


          } catch (error) {
            console.error(error);
          }
        }
    
        // Call the async function to fetch the questions
        fetchQuestions();
      }, [activityId]);


      const submitButton = useRef(null);
      const GoodImageRef = useRef(null);
      const CrossImageRef = useRef(null);
      const FinishedImageRef = useRef(null);

      const optionRef1 = useRef(null)
      const optionRef2 = useRef(null)
      const optionRef3 = useRef(null)
      const optionRef4 = useRef(null)
    

    const [items, setItems] = useState([]);
    // const [currentQuestion, setCurrentQuestion] = useState(questions[0]);
    const [gainedMarks, setGainedMarks] = useState(0);
    const [questionNumber, setQuestionNumber] = useState(1);
    const [studentAnswer, setStudentAnswer] = useState("");
    const [totalQuestions, setTotalQuestions] = useState();
    const [marksPerQuestion, setMarksPerQuestion] = useState();
    const [totalMarks, setTotalMarks] = useState();
    
    const [student_ID, setStudent_ID] = useState("4");
    const studentCurrAnswerRef = useRef(0);
    const marksObtainedCurrQuestionRef = useRef(0);
    const isCorrectRef = useRef(0);

    const gainedMarksRef = useRef(0);

    function stopIntervalID(){
        setCount(0);
        countRef.current = 0;
        setMinutes(0);
        minutesRef.current = 0;
        setHours(0);
        hoursRef.current = 0;

        clearInterval(intervalId);


        studentCurrAnswerRef.current = "";
        marksObtainedCurrQuestionRef.current = marksPerQuestion;
        isCorrectRef.current = 0;
        submitStudentQuestionResponse();

        setItems([...items, 0]);
        clearInterval(intervalId);

        GoodImageRef.current.style.display = 'none';
        CrossImageRef.current.style.display = "inline-block";
        
        submitButton.current.style.display = "none";
        FinishedImageRef.current.style.display = "inline-block";
    }

    var intervalId;
    useEffect(() => {
            intervalId = setInterval(() => {
          countRef.current = countRef.current - 1;
          if (countRef.current == -1) {
            
            setMinutes(minutes => minutes - 1);
            minutesRef.current -= 1;

            if(minutesRef.current == -1 && hoursRef.current == 0){
                stopIntervalID();
            }

            console.log("Minutes ")

            if(minutesRef.current > 0){
                setCount(59);
                countRef.current = 59;
            }

            if(minutesRef.current < 0 && hoursRef.current > 0){
                setHours(hours => hours - 1);
                hoursRef.current -= 1;

                setMinutes(59);
                minutesRef.current = 59;

                setCount(59);
                countRef.current = 59;
            }

          } else {
            setCount(countRef.current);
          }
        }, 1000);
    
        // Return a cleanup function to clear the interval
        return () => clearInterval(intervalId);
      }, [questionNumber, count]);
    
      useEffect(() => {
        if (count == 0 && minutes == 0 && hours == 0) {

            console.log("I am in 0:0:0")
            submitStudentQuestionResponse();
            clearInterval(intervalId);
        }
      }, [count]);

      const handleStudentAnswer = (event) =>{
        setStudentAnswer(event.target.value);
      }


      const totalTimeTakenOfAllQuestions = useRef(0);
      const totalTimeOfAllQuestions = useRef(0);

      const submitStudentQuestionResponse = async () => {

        totalTimeTakenOfAllQuestions.current += currQuestion.timealloted - countRef.current - 1;
        totalTimeOfAllQuestions.current += currQuestion.timealloted;

        try {
            // console.log("I am in submitting response...")
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/saveActivityQuestion`, {
                Question_id: currQuestion.Question_id,
                Student_id: student_ID,
                timetaken: currQuestion.timealloted - countRef.current - 1,
                StudentAnswer: studentCurrAnswerRef.current,
                MarksObtained: marksObtainedCurrQuestionRef.current,
                TotalTime: currQuestion.timealloted,
                correct: isCorrectRef.current
            });
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
    };




    const completeActivityRef = useRef();

    const submitCompleteActivityResponse = async () => {
    
        try {
            console.log("I am in submitting Complete response...")
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/saveActivity`, {
                Activity_id: activityId,
                Student_id: student_ID,
                MarksObtained: gainedMarksRef.current,
                MarksObtainedPercentage: gainedMarksRef.current / totalMarks * 100,
                Remarks: 'Good Efforts',
                SubmitActivityFile: '',
                TimeTaken: totalTimeTakenOfAllQuestions.current,
                TotalMarks: totalMarks,
                TotalTime: totalTimeOfAllQuestions.current,
                Complete: completeActivityRef.current,
            });
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
      };

      const handleUpload = (event) => {
        const file = event.target.files[0];
        // Handle the file upload logic here



      };

      const handleSubmit = (event) => {
        event.preventDefault();
        console.log(studentAnswer);

        console.log("I am in handle submit...")
  
      }

           

      const handleCourseSelect = (course) => {
        console.log("Selected Course in Home:", course);
        selectedCourse(course);
      };
    
     const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
     
        useEffect(() => {
          const fetchData = async () => {
           await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
              credentials: 'include',
            }).then(response => {
                console.log("Response:",response);
              response.json().then(userInfo => {
                console.log("userinfo:",userInfo);
                setUserInfo(userInfo);
    
                if(userInfo?.usertype)
                {
                  const uType=userInfo?.usertype;
                  setUserRole(uType);
                }
              const uname = userInfo?.username;
              const avatar = userInfo?.avatar; // Store the avatar in a separate variable
              console.log("Uname: ", uname);
              console.log("Avatar: ", avatar);
    
              })
              .catch(err=> console.log("Error in useeffect:",err))
            });
          }
          fetchData();
        }, [isAdmin, isTeacher, isStudent, isGuest]);
      const determineUserRole = (usertype) => {
            console.log("In home.js:",usertype)
            if(usertype === 'Student')
              return "Student";
            else if(usertype === 'Teacher')
              return "Teacher";
            else if(usertype === 'Admin')
              return "Admin";
            else
              return "guest";
      };
      
      
     const navigate=useNavigate();
      const onLogin = (usertype) => {
        const userRole = determineUserRole(usertype);
        console.log("Calling setUserRole.")
        setUserRole(userRole);
      };
    
      function onLogout() {
          console.log("inside Onlogout.");
          fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
            credentials: 'include',
            method: 'POST',
          });
          setUserInfo(null);
          setUserRole('guest');
          navigate("/");

        }
          
      const username = userInfo?.username;
      const _avatar_ = userInfo?.avatar;
      const usertype = userInfo?.usertype;

      function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    }
  return (
    <div>

    {username && (
        <>
            {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
            {isTeacher && <TeacherNav  onCourseSelect={handleCourseSelect}  username={username} avatar={_avatar_} onLogout={onLogout}/>}
            {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
         </>
         )}  
         {!username && (
           <>
           {isGuest && <GuestNav onLogin={onLogin} />}
  
           </>
         )}


        {/* <AdminNav/> */}
         <div className="MAMCQheading">
            <div className="MAMCQactivity-image">
                <img src={MCQActivityImage} alt=""/>
            </div>

            <div className="MAMCQactivity-text">
                <h3>Scratch M4 L1 ExamActivity 1</h3>
            </div>

            <div className="MAMCQtime-marks MADescMargin">
                    <div className="MAMCQtime">
                        <div className="MAMCQtime-text">
                            <h4>Time</h4>
                        </div>
                        <div className="MAMCQtimer">
                            {/* <h4 id="MAtime-counter">{hours}hr:{minutes}min:{count}sec</h4> */}
                            <h4 id="MAtime-counter">{formatTime(count)}</h4>

                        </div>
                    </div>

                </div>

        </div>

    <div className="MAMCQcontainer">
        <div className="MAMCQhead">

            <div className="MAMCQquestions">

                <div className="MAMCQquestions-numbers">
                    <h2>Total Questions: <span id="MAtotal-questions">{totalQuestions}</span></h2>
                </div>                

            </div>

            <div className="MAMCQscore">

                <div className="MAMCQscore-text">
                    <h4>Total Marks</h4>
                </div>

                <div className="MAMCQnumbered-score">
                    <h2><span id="MAtotal-score">{totalMarks}</span></h2>
                </div>

            </div>

        </div>


        <div className="MADescbody">

            <div className="MADescremaining-body">

            <div class="MADescquestion">
                <div class="MAMCQmental-question-section">

                    <div class="MAMCQmcq-text">
                      
                        {/* <h4>Q: <span id="MAMCQmental-question-text">{currQuestion.question}</span> Marks: {marksPerQuestion}</h4> */}

                        {actualQuestions && (
                          <>
                        {actualQuestions.map((question, index) => (
                          <h4>Q{index+1}: <span id="MAMCQmental-question-text">{question.question}</span> Marks: {question.marks}</h4>
                        ))}
                        </>
                        )}

                    </div>


                    <div className="MADesfile-upload">
                      {
                      /* <input type="file" onChange={handleUpload} /> */}
                      {/* <label htmlFor="file-upload" className="MADesccustom-file-upload">
                        Upload file
                      </label> */}
                      <input
                        id="MADesfile-upload"
                        type="file"
                        accept=".pdf,.doc,.docx"
                        onChange={handleUpload}
                      />
                    </div> 

                    <div class="MAMCQplay-button">
                        <button ref={submitButton} className='MAMCQsubmitButton' onClick={handleSubmit}><h5>Submit</h5></button>
                    </div>
                    
                </div>
            </div>

                
            </div>

            

        </div>
    </div>

    
      <Footer/>
    </div>
  )
}

export default ExamActivity;
