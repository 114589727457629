import React, { useContext } from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from '../Footer'
import './CodingCourseDetails.css'
import { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import "../MathPortal/Slider.css";
import "../MathPortal/MathCourseDetails.css";
import "../MathPortal/MathPortalResponsive.css";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import pic1 from './CodingCourseImages/scratch.png'
import Technology from './CodingCourseImages/Technology.jpg'

import pic2 from './CodingCourseImages/appdev.png'
import pic3 from './CodingCourseImages/webdev.png'
import pic4 from './CodingCourseImages/gamedev.png'
import pic5 from './CodingCourseImages/php.png'
import pic6 from './CodingCourseImages/AI.svg'
import pic7 from './CodingCourseImages/py.png'
import pic8 from './CodingCourseImages/cpp.png'
import pic9 from './CodingCourseImages/sql.png'
import pic10 from './CodingCourseImages/unitylogo.png'
import pic11 from './CodingCourseImages/MLlogo.png'
import pic12 from './CodingCourseImages/Android_Studio.png'
import pic13 from './CodingCourseImages/pt.jfif'
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import FeeStructure from '../components/FeeStructure';
function CourseRoadMap({ bg1, bg2, bg3, bg4, bg5, bg6, fr1, fr2, fr3,
  text1, text2, text3,
  lesson1, lesson2, lesson3,
  bullet1, bullet2, bullet3, handleTabClick, activeTab }) {
  return (
    <>
      <div className='CourseRoadMap'>
        <div id="RoadMapTabs">
          <div id='tb'
            className={`tab ${activeTab === 'grade5to6' ? 'active' : ''}`}
            onClick={() => handleTabClick('grade5to6')}
          >
            Grade 5-6
          </div>
          <div id='tb'
            className={`tab ${activeTab === 'grade7to8' ? 'active' : ''}`}
            onClick={() => handleTabClick('grade7to8')}
          >
            Grade 7-8
          </div>
          <div id='tb'
            className={`tab ${activeTab === 'grade9to12' ? 'active' : ''}`}
            onClick={() => handleTabClick('grade9to12')}
          >
            Grade 9-12
          </div>
        </div>

        <div className="CourseTabContent" id="grade5to6">
          <div className='CourseTabContentSection'>
            <div style={{ backgroundColor: bg1 }} id='CourseTabContentDetails1'>
              <h4 style={{ color: fr1 }}>Polymath</h4>
              <p className='CourseRoadMapPara'>{text1}</p>
              <b></b>
              <p className='NoOfLesson' style={{ backgroundColor: fr1 }}>{lesson1} Lessons</p>
            </div>
            <div style={{ backgroundColor: bg4 }} id='CourseTabContentDetails2'>
              <ul className='RoadMapBullets'>
                {bullet1.map((bullet, index) => (
                  <li key={index}>{bullet}</li>
                ))}
              </ul>
              <Link to='/contact_us' className='RequestCurriculumbtn' style={{ backgroundColor: fr1 }}>
                Request Curriculum
              </Link>
            </div>
          </div>

          <div className='CourseTabContentSection'>
            <div style={{ backgroundColor: bg2 }} id='CourseTabContentDetails1'>
              <h4 style={{ color: fr2 }}>Polymath+</h4>
              <p className='CourseRoadMapPara'>{text2}</p>
              <p className='NoOfLesson' style={{ backgroundColor: fr2 }}>{lesson2} Lessons</p>
            </div>
            <div style={{ backgroundColor: bg5 }} id='CourseTabContentDetails2'>
              <ul className='RoadMapBullets'>
                {bullet2.map((bullet, index) => (
                  <li key={index}>{bullet}</li>
                ))}
              </ul>
              <Link to='/contact_us' className='RequestCurriculumbtn' style={{ backgroundColor: fr2 }}>
                Request Curriculum
              </Link>
            </div>
          </div>

          <div className='CourseTabContentSection'>
            <div style={{ backgroundColor: bg3 }} id='CourseTabContentDetails1'>
              <h4 style={{ color: fr3 }}>Polymath++</h4>
              <p className='CourseRoadMapPara'>{text3}</p>
              <p className='NoOfLesson' style={{ backgroundColor: fr3 }}>{lesson3} Lessons</p>
            </div>
            <div style={{ backgroundColor: bg6 }} id='CourseTabContentDetails2'>
              <ul className='RoadMapBullets'>
                {bullet3.map((bullet, index) => (
                  <li key={index}>{bullet}</li>
                ))}
              </ul>
              <Link to='/contact_us' className='RequestCurriculumbtn' style={{ backgroundColor: fr3 }}>
                Request Curriculum
              </Link>
            </div>
          </div>

        </div>



      </div>
    </>
  );
}
const CodingCourseDetails = ({ selectedCourse }) => {
  const [activeTab, setActiveTab] = useState('grade5to6');
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  var bullet1 = [
    "Block-based coding",
    "Conditionals",
    "Sequencing",
    "Functions",
    "Variables",
    "Loops",
    "Animations and storytelling",
    "Algorithms and data types",
    "Game development",
    "App development and emulator",
    "Animation and storytelling",
    "User interface and user experience design"
  ];
  var bullet2 = [
    "All Polymath Course modules",
    "Website development",
    "Web styling",
    "Advance Scratch Game design",
    "Advance game development"
  ];
  var bullet3 = [
    "All Polymath+ Course modules",
    "Python",
    "Advance Game concepts",
    "Sequencing, scoring, movement, and setting in game development"
  ];
  var bullet4 = [
    "Block-Based Coding",
    "Conditionals, Sequencing, Functions, Run-Time Input, Variables, and Loops",
    "Animations and Storytelling",
    "Algorithms, Data Types, and Flow Charts",
    "Game Development and Advance Game Design",
    "App Development and Emulator",
    "UI/UX",
    "Website Development"
  ];

  var bullet5 = [
    "All Polymath Course Modules",
    "Web Styling",
    "Advance Game Development",
    "Sequencing, Scoring, Movement, and Setting in Game Development",
    "CSS Styling",
    "Box Model"
  ];

  var bullet6 = [
    "All Polymath+ Course Modules",
    "UI/UX",
    "PHP Procedural",
    "Database & Data Types",
    "MySQLi",
    "XAMPP Server",
    "Fundamentals of Text-Based Python",
    "Print Function"
  ];
  var bullet7 = [
    "C++",
    "Web Development Basics in HTML and CSS",
    "JavaScript Fundamentals",
    "MySQLi Database",
    "Graphic Designing in Photoshop",
    "Game Development Basics"
  ];

  var bullet8 = [
    "All Polymath Course Modules",
    "AI Basics",
    "Advanced Web Development",
    "PHP Basics",
    "Advanced Game Development",
    "Advanced Graphic Designing Tools"
  ];

  var bullet9 = [
    "All Polymath+ Course Modules",
    "Advanced AI",
    "JS Object Model",
    "Python Dictionary",
    "PHP Procedural",
    "Database",
    "UI/UX"
  ];

  const handleCourseSelect = (course) => {
    console.log("Selected Course in Home:", course);
    selectedCourse(course);
  };

  const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo, userInfo } = useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
      await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
        credentials: 'include',
      }).then(response => {
        console.log("Response:", response);
        response.json().then(userInfo => {
          console.log("userinfo:", userInfo);
          setUserInfo(userInfo);

          if (userInfo?.usertype) {
            const uType = userInfo?.usertype;
            setUserRole(uType);
          }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

        })
          .catch(err => console.log("Error in useeffect:", err))
      });
    }
    fetchData();
  }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
    console.log("In home.js:", usertype)
    if (usertype === 'Student')
      return "Student";
    else if (usertype === 'Teacher')
      return "Teacher";
    else if (usertype === 'Admin')
      return "Admin";
    else
      return "guest";
  };


  const navigate = useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
    console.log("inside Onlogout.");
    fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
      credentials: 'include',
      method: 'POST',
    });
    setUserInfo(null);
    setUserRole('guest');
    navigate("/");

  }

  const username = userInfo?.username;
  const _avatar_ = userInfo?.avatar;

  return (
    <div   >

      {/* Navbar */}
      {username && (
        <>
          {isAdmin && <AdminNav username={username} avatar={_avatar_} onLogout={onLogout} />}
          {isTeacher && <TeacherNav username={username} avatar={_avatar_} onLogout={onLogout} />}
          {isStudent && <StudentNav username={username} avatar={_avatar_} onLogout={onLogout} />}
        </>
      )}
      {!username && (
        <>
          {isGuest && <GuestNav onLogin={onLogin} />}

        </>
      )}

      <div className="CodingPortalBackground">
        <section className="section">
          <p className="PageHead" style={{ color: "#8b0194" }}>Coding Portal</p>
          <div className="PageDesc">
            <div style={{display:"flex"}}>
              <p style={{
                fontFamily: "KG Primary Whimsy,VanillaMilk,'DM Sans', sans-serif",
                color: "white",
                textAlign: "left",
                padding: "30px",
                fontSize: "22px",
                zIndex: 1,
                color: "#8b0194",
                width:"70%"
                
              }}>
                In this technological era, it is of utmost importance that we equip our kids with the coding skills which helps them with the logic, structure, creative thinking, self-expression, problem solving, sequencing and analytical thinking. Polymath-Kids offer a complete roadmap which includes the following courses.
              </p>
              <img style={{minWidth:"250px", maxWidth:"450px"}} src={Technology}></img>
            </div>

            <div className="btn-row d-flex btn-row-resp btn-row-resp1">

              {/* <div className="btn-row-resp"> */}
              <div style={{ width: "auto", height: "50px" }}>


                <a className="btn-row-resp button btn-color-course" href="#course">Individual Courses</a>
              </div>
              <div style={{ width: "auto", height: "50px" }}>

                <a className=" btn-row-resp button btn-color-portal" href="#roadmap">Course Roadmap</a>

              </div>

              <div style={{ width: "auto", height: "50px" }}>
                <a className=" btn-row-resp button btn-color-fee" href="#fee">Fee Structure</a>

              </div>
              <div style={{ width: "auto", height: "50px" }}>

                <a className=" btn-row-resp button btn-color-Testimonal" href="#testimonal">Testimonals</a>
              </div>
              <div style={{ width: "auto", height: "50px" }}>
                <a className=" btn-row-resp button btn-color-Faq" href="#Faq">Faqs</a>


              </div>
              <div style={{ width: "auto", height: "50px" }}>
                <a className=" btn-row-resp button btn-color-registered" href="#">Get Registered</a>


              </div>



            </div>
          </div>

        </section>
        {/* <div className="overlay"></div> */}
      </div>
      {/* Individual courses */}
      <div style={{ backgroundColor: '#d8f2fb' }} id="course">
        <p className="PageHead mx-auto" style={{ color: "#8b0194" }}>
          Coding Individual Courses
        </p>

        <div className="container"  >

          <MDBRow>
            <MDBCol md='4'>
              <div className=" col-xs-6">
                <Link to="/coding_scratch">
                  <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                    <h3 className="ind-course-title ">Scratch Programming Course</h3>
                    <div>
                      <img src={pic1} alt="" style={{ width: "100px", height: "100px" }} />
                    </div>

                  </div>

                </Link>
              </div>
            </MDBCol>
            <MDBCol md='4'>
              <div className=" col-xs-6">
                <Link to="/coding_app_development">
                  <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                    <h3 className="ind-course-title ">App development course</h3>
                    <div>
                      <img src={pic2} alt="" style={{ width: "100px", height: "100px" }} />
                    </div>

                  </div>

                </Link>
              </div>
            </MDBCol>
            <MDBCol md='4'>
              <div className=" col-xs-6">
                <Link to="/coding_web_development">
                  <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                    <h3 className="ind-course-title ">Web development course</h3>
                    <div>
                      <img src={pic3} alt="" style={{ width: "100px", height: "100px" }} />
                    </div>

                  </div>

                </Link>
              </div>
            </MDBCol>
            <MDBCol md='4'>
              <div className=" col-xs-6">
                <Link to="/coding_game_development">
                  <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                    <h3 className="ind-course-title ">Game development course</h3>
                    <div>
                      <img src={pic4} alt="" style={{ width: "100px", height: "100px" }} />
                    </div>

                  </div>

                </Link>
              </div>
            </MDBCol>
            <MDBCol md='4'>
              <div className=" col-xs-6">
                <Link to="/coding_php">
                  <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                    <h3 className="ind-course-title ">PHP Procedural Course</h3>
                    <div>
                      <img src={pic5} alt="" style={{ width: "100px", height: "100px" }} />
                    </div>

                  </div>

                </Link>
              </div>
            </MDBCol>
            <MDBCol md='4'>
              <div className=" col-xs-6">
                <Link to="/coding_ai_datascience">
                  <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                    <h3 className="ind-course-title ">AI and Data Science
                    </h3>
                    <div>
                      <img src={pic6} alt="" style={{ width: "100px", height: "100px" }} />
                    </div>

                  </div>

                </Link>
              </div>
            </MDBCol>
            <MDBCol md='4'>
              <div className=" col-xs-6">
                <Link to="/coding_python">
                  <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                    <h3 className="ind-course-title ">Python Course
                    </h3>
                    <div>
                      <img src={pic7} alt="" style={{ width: "100px", height: "100px" }} />
                    </div>

                  </div>

                </Link>
              </div>
            </MDBCol>

            <MDBCol md='4'>
              <div className=" col-xs-6">
                <Link to="/coding_cplus">
                  <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                    <h3 className="ind-course-title ">C++
                    </h3>
                    <div>
                      <img src={pic8} alt="" style={{ width: "100px", height: "100px" }} />
                    </div>

                  </div>

                </Link>
              </div>
            </MDBCol>
            <MDBCol md='4'>
              <div className=" col-xs-6">
                <Link to="/coding_game_dev_unity">
                  <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                    <h3 className="ind-course-title ">Game Development Course(Text Based Unity)
                    </h3>
                    <div>
                      <img src={pic10} alt="" style={{ width: "100px", height: "100px" }} />
                    </div>

                  </div>

                </Link>
              </div>
            </MDBCol>
            <MDBCol md='4'>
              <div className=" col-xs-6">
                <Link to="/coding_machine_learning">
                  <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                    <h3 className="ind-course-title ">Machine Learning
                    </h3>
                    <div>
                      <img src={pic11} alt="" style={{ width: "100px", height: "100px" }} />
                    </div>

                  </div>

                </Link>
              </div>
            </MDBCol>
            <MDBCol md='4'>
              <div className=" col-xs-6">
                <Link to="#">
                  <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                    <h3 className="ind-course-title ">Database Management System
                      <b style={{ color: "blue" }}>(ComingSoon)</b>
                    </h3>
                    <div>
                      <img src={pic9} alt="" style={{ width: "100px", height: "100px" }} />
                    </div>

                  </div>

                </Link>
              </div>
            </MDBCol>
            <MDBCol md='4'>
              <div className=" col-xs-6">
                <Link to="/coding_app_dev_androidstu">
                  <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                    <h3 className="ind-course-title ">Android App Development</h3>
                    <div>
                      <img src={pic12} alt="" style={{ width: "100px", height: "100px" }} />
                    </div>

                  </div>

                </Link>
              </div>
            </MDBCol>
            <MDBCol md='4'>
              <div className=" col-xs-6">
                <Link to="/coding_ai_promptengineer">
                  <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                    <h3 className="ind-course-title ">AI - Prompt Engineering</h3>
                    <div>
                      <img src={pic13} alt="" style={{ width: "100px", height: "100px" }} />
                    </div>

                  </div>

                </Link>
              </div>
            </MDBCol>


          </MDBRow>
        </div>


      </div>

      {/* RoadMap */}
      {activeTab === 'grade5to6' && (
        <CourseRoadMap bg1='#f8f8da' bg2='#cff7d5' bg3='#d9defb'
          bg4='#fcfcf1' bg5='#e7f8e9' bg6='#e9ecfc'
          fr1='#979723' fr2='#249935' fr3='#485cd9'
          text1='A course for little ones to build foundation of coding, improve logic building and critical thinking
      50+ Activities | 50 Quizzes   Duration: 12 months (1 lesson/week) 6 months (2 lessons/week)'
          text2='An advance course for kids to help build a deeper understanding of complex coding concepts
      74+ Activities | 74 Quizzes   Duration: 17 months (1 lesson/week) 8 months (2 lessons/week)'
          text3='A course for kids who aspire to excel at coding and master the complex coding concepts to become a PRO in the field
      85+ Activities | 85 Quizzes  Duration: 20 months (1 lesson/week) 10 months (2 lessons/week)'
          lesson1='50' lesson2='74' lesson3='85'
          bullet1={bullet1}
          bullet2={bullet2}
          bullet3={bullet3}
          handleTabClick={handleTabClick}
          activeTab
          isClicked
        />
      )}
      {activeTab === 'grade7to8' && (
        <CourseRoadMap bg1='#f8f8da' bg2='#cff7d5' bg3='#d9defb'
          bg4='#fcfcf1' bg5='#e7f8e9' bg6='#e9ecfc'
          fr1='#979723' fr2='#249935' fr3='#485cd9'
          text1='A course for kids to build foundation of coding, improve logic building and critical thinking
          59+ Activities | 59 Quizzes  Duration: 13 months (1 lesson/week) 7 months (2 lessons/week)'
          text2='An advance course for kids to help build a deeper understanding of complex coding concepts
          83+ Activities | 83 Quizzes  Duration: 20 months (1 lesson/week) 10 months (2 lessons/week)'
          text3='A course for kids who aspire to excel at coding and master the complex coding concepts to become a PRO in the field
          116+ Activities | 116 Quizzes Duration: 30 months (1 lesson/week) 15 months (2 lessons/week)'
          lesson1='59' lesson2='83' lesson3='116'
          bullet1={bullet4}
          bullet2={bullet5}
          bullet3={bullet6}
          handleTabClick={handleTabClick}
          activeTab
          isClicked
        />
      )}
      {activeTab === 'grade9to12' && (
        <CourseRoadMap bg1='#f8f8da' bg2='#cff7d5' bg3='#d9defb'
          bg4='#fcfcf1' bg5='#e7f8e9' bg6='#e9ecfc'
          fr1='#979723' fr2='#249935' fr3='#485cd9'
          text1='The perfect course for teenagers to learn and advance their coding skills and develop a deeper understanding of complex coding concepts
      80+ Activities | 80 Quizzes Duration: 19 months (1 lesson/week) 10 months (2 lessons/week)'
          text2='An exceptional course for inquisitive youngsters inspired to excel at coding and build amazing websites, games and Apps
      135+ Activities | 135 Quizzes  Duration: 30 months (1 lesson/week)   15 months (2 lessons/week)'
          text3='The ideal course for teenagers who wish to master the art of coding and graphic designing to create a better future, using coding and designing skills.
      225+ Activities | 225 Quizzes Duration: 48 months (1 lesson/week) 24 months (2 lessons/week)'
          lesson1='80' lesson2='135' lesson3='225'
          bullet1={bullet7}
          bullet2={bullet8}
          bullet3={bullet9}
          handleTabClick={handleTabClick}
          activeTab
          isClicked
        />
      )}


      {/* Fee Structure */}
      <FeeStructure subject="Coding" />

      {/* Testimonals */}
      <div class="  background" id="testimonal">
        <div class="row" style={{ width: "100%" }}>
          <div class="col-lg-8 mx-auto">
            <h2 className="Testimonal_Heading">Testimonials</h2>
            <div id="myCarousel" class="carousel slide" data-ride="carousel">
              {/* <!-- Carousel indicators --> */}
              <ol class="carousel-indicators">
                <li
                  data-target="#myCarousel"
                  data-slide-to="0"
                  class="active"
                ></li>
                <li data-target="#myCarousel" data-slide-to="1"></li>
              </ol>
              {/* <!-- Wrapper for carousel items --> */}
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="img-box">
                    <div className=" img-box1"></div>
                  </div>
                  <p
                    class="testimonial"
                    style={{ color: "black", fontSize: "3vh" }}
                  >
                    I enjoyed the learning experience with Polymath-Kids-Abacus.
                    The teacher was very friendly. I highly recommend
                    Polymath-Kids-Abacus.
                  </p>
                  <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                    <b>Mujtaba Khawaja Sahaf</b>
                  </p>
                </div>

                <div class="carousel-item">
                  <div class="img-box">
                    <div className="img-box2 "></div>
                  </div>
                  <p
                    class="testimonial"
                    style={{ color: "black", fontSize: "3vh" }}
                  >
                    I feel so proud when my class fellows call me ’BRAINIAC ’,
                    because I can effortlessly do mental calculations much
                    faster and accurate than my class mates. Thank you
                    Polymath-Kids.
                  </p>
                  <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                    <b>Wahaab Omer</b>, Age 9 Years
                  </p>
                </div>
              </div>
              {/* <!-- Carousel controls --> */}
              <a
                class="carousel-control-prev"
                href="#myCarousel"
                data-slide="prev"
              >
                <i class="fa fa-angle-left"></i>
              </a>
              <a
                class="carousel-control-next"
                href="#myCarousel"
                data-slide="next"
              >
                <i class="fa fa-angle-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Faqs */}
      <div style={{ maxWidth: '100%', backgroundColor: '#edffff' }} id="Faq">

        <MDBContainer className="mt-0" style={{ maxWidth: '180vh' }}>
          <MDBAccordion alwaysOpen initialActive={1} >
            <div className="text-center PageHead" style={{ color: "#8b0194" }}> FAQs</div>
            <MDBAccordionItem collapseId={1} headerTitle="Q: What are the various coding courses offered by Polymath-Kids?" >
              <strong>A: </strong>
              Polymath-Kids is one of the best platform for kids to learn coding. A variety of coding courses are offered by Polymath-Kids platform such as Scratch Programming Beginner and Advanced Course, Game Development Course Beginner and Advanced Course, Web Development Beginner Course and Responsive Web Development Course, PHP Basic and Advanced Course and Python.  All courses are offered in group mode as well as one on one with the expert instructors with years of experience.
            </MDBAccordionItem>
            <MDBAccordionItem collapseId={2} headerTitle="Q: Which is the best Coding platform for Kids?">
              <strong>A: </strong>
              Polymath-Kids is the best Coding platform for kids aged 5 to 16 years old. A variety of courses are offered in a fun engaging way through a personalized learning platform for each child. In each lesson, kids get the opportunity to expand on to their knowledge through hands on activities. A project is completed at the end of each module to enable kids to incorporate the learned concepts to express their creativity.
            </MDBAccordionItem>


            <MDBAccordionItem collapseId={3} headerTitle="Q: Which age group is suitable for learning Coding?">
              <strong>A: </strong>
              Students as young as 5 years can be introduced to programming using Block Based Coding. Block Based Coding allows kids to learn the mechanics of coding in a simple and engaging way. Once they have mastered the block based coding and the mechanism involved, they can easily move on to Text Based Coding languages such as HTML, PHP and Python.Coding is like learning another language. The benefits of learning a new language such as increased memory capacity, critical thinking, problem solving and concentration applies to learning coding as well. Learning a new language is easy for younger students as the research shows that kids aged 4 to 12 shows the strongest learning effect.
            </MDBAccordionItem>

            <MDBAccordionItem collapseId={3} headerTitle="Q: What way is the Online Abacus Brain Training different from Offline classes?">
              <strong>A: </strong>
              The only difference in the Online way of leaning abacus is that there is no physical face-to-face interaction with the teacher. Instead there will be weekly class with trained instructors. The use of technology and interactive portal makes it easy for every student to learn in their personalized way and keep track of their activities through portal generated reports.
            </MDBAccordionItem>

            <MDBAccordionItem collapseId={3} headerTitle="Q: What is the Polymath-Kids Coding Roadmap?">
              <strong>A: </strong>
              Polymath-Kids offer individual Coding courses as well as Coding Roadmap. The individual courses are for students who wish to take only a particular short term course. The Coding roadmap offers extensive in-depth knowledge for kids who are passionate to master the art of coding and get a future in this field.
            </MDBAccordionItem>

            <MDBAccordionItem collapseId={3} headerTitle="Q: Who should attend Polymath-Kids coding classes?">
              <strong>A: </strong>
              Any child aged 5 + can register for Polymath-Kids classes according to their interest. The platform offers a range of courses to enable students learn and apply their knowledge.
            </MDBAccordionItem>

            <MDBAccordionItem collapseId={3} headerTitle="Q: Is there any pre requisite for taking Coding classes?">
              <strong>A: </strong>
              There is no pre requisite for taking the basic level coding courses. However, opting for the advance level courses requires the student to finish the basic level course first.
            </MDBAccordionItem>

            <MDBAccordionItem collapseId={3} headerTitle="Q: How coding helps improve mathematical skills?">
              <strong>A: </strong>
              Coding and mathematical skills are directly connected. Mathematical concepts such as counting, distance, dimensions, radius, quadrants, planes and iterations are frequently used in coding process.
            </MDBAccordionItem>

            <MDBAccordionItem collapseId={3} headerTitle="Q: How coding helps boost creativity?">
              <strong>A: </strong>
              Learning to be a creative thinker is an essential part of the Coding learning process. During the coding process, students learn to achieve their desired outcomes by using a variety of solutions. They learn to be efficient with their solutions as order and process are of utmost importance for the desired results. This helps in boosting creativity and problem solving in a fun and engaging way.
            </MDBAccordionItem>
          </MDBAccordion>
        </MDBContainer>

      </div>

      {/* Footer */}
      <Footer />

    </div>
  )
}

export default CodingCourseDetails
