import React, { useState, useContext, useEffect, useCallback } from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import GuestNav from '../Navbars/GuestNav';
import TeacherNav from '../Navbars/TeacherNav';
import Footer from '../Footer'
import UserCard from './UserCard';
import './User.css'
import { UserContext } from '../UserContext';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';


const User = () => {
  const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo, userInfo } = useContext(UserContext);

  const [users, setUsers] = useState([]);
  // State for search filters
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedSchool, setSelectedSchool] = useState('');


  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false); // Start with false
  useEffect(() => {
    const fetchData = async () => {
      await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
        credentials: 'include',
      }).then(response => {
        console.log("Response:", response);
        response.json().then(userInfo => {
          console.log("userinfo:", userInfo);
          setUserInfo(userInfo);

          if (userInfo?.usertype) {
            const uType = userInfo?.usertype;
            setUserRole(uType);
          }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname in user.js: ", uname);
          //  console.log("Avatar in: ", avatar);

        })
          .catch(err => console.log("Error in useeffect:", err))
      });
    }
    fetchData();
  }, []);

  // const fetchUsers = async () => {
  //   if (loading || !hasMore) return;

  //   setLoading(true);
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/getUsers?limit=50`, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       }
  //     });
  //     const data = await response.json();

  //     if (data.users && data.users.length > 0) {
  //       const formattedUsers = data.users.map(user => ({
  //         ...user,
  //         dateofbirth: new Date(user.dateofbirth).toLocaleDateString('en-US', {
  //           month: 'long',
  //           day: 'numeric',
  //           year: 'numeric'
  //         })
  //       }));

  //       setUsers(prevUsers => [...prevUsers, ...formattedUsers]);
  //       setHasMore(data.users.length === 20);
  //     } else {
  //       setHasMore(false);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching users:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // useEffect(() => {
  //   fetchUsers();
  // }, []); // Fetch users whenever the page changes
  
  const fetchUsers = async () => {
    if (loading || !hasMore) return;
  
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/getUsers?limit=50&page=${page}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
  
      if (data.users && data.users.length > 0) {
        const formattedUsers = data.users.map((user) => ({
          ...user,
          dateofbirth: new Date(user.dateofbirth).toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          }),
        }));
  
        setUsers((prevUsers) => [...prevUsers, ...formattedUsers]);
        setPage((prevPage) => prevPage + 1); // Increment the page number
        setHasMore(data.users.length === 50); // Check if more data is available
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };
  
  // Initial fetch on component mount
  useEffect(() => {
    setPage(1); // Reset the page to 1
    setUsers([]); // Clear previous users
    setHasMore(true); // Reset hasMore
    fetchUsers(); // Fetch the initial data
  }, []);
  
  const navigate = useNavigate();

  const username = userInfo?.username;
  const _avatar_ = userInfo?.avatar;
  
  const onLogin = (usertype) => {

    setUserRole(usertype);
  };

  async function onLogout() {
    console.log("inside Onlogout.");
    await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
      credentials: 'include',
      method: 'POST',
    });
    setUserInfo(null);
    console.log("navigating");
    navigate('/');
    // console.log("navigated");
    setUserRole('guest');
  }

  const updateTable = async (userId, userType, userPreviousType) => {

    await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/setTables/${userId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ usertype: userType, userPreviousType: userPreviousType }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response of the additional API call
      })
      .catch((error) => {
        console.error('Error in additional API call:', error);
      });
    // }
  }
  //Update user's type  i.e Student,Teacher,Admin
  const updateUserType = async (userId, userType, userPreviousType) => {

    await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/updateType/${userId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ usertype: userType }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Update the user's usertype in the local state
        const updatedUsers = users.map((user) =>
          user.id === userId ? { ...user, usertype: userType } : user
        );
        setUsers(updatedUsers);
        updateTable(userId, userType, userPreviousType);


      })

      .catch((error) => {
        console.error('Error updating user usertype:', error);
      });
  };
  const updateUserStatus = async (userId, userStatus) => {
    console.log('inside updateUserStatus')

    await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/updateUserStatus/${userId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userstatus: userStatus }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Update the user's usertype in the local state
        const updatedUsers = users.map((user) =>
          user.id === userId ? { ...user, deactivated: userStatus } : user
        );
        setUsers(updatedUsers);
      })
      .catch((error) => {
        console.error('Error updating user usertype:', error);
      });
  };

  return (
    <>
      {username && (
        <>
          {isAdmin && <AdminNav username={username} avatar={_avatar_} onLogout={onLogout} />}
          {isTeacher && <TeacherNav username={username} avatar={_avatar_} onLogout={onLogout} />}
          {isStudent && <StudentNav username={username} avatar={_avatar_} onLogout={onLogout} />}
        </>
      )}
      {!username && (
        <>
          {isGuest && <GuestNav onLogin={onLogin} />}

        </>
      )}

      {/* Search Bars */}
      <div className="search-filters-container">
        <div className="search-filters">
          <input
            type="text"
            placeholder="Type to search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <select
            className="MAdropdown-menu"
            value={selectedRole}
            onChange={(e) => setSelectedRole(e.target.value)}
          >
            <option value="">Filter by Role</option>
            <option value="Admin">Admin</option>
            <option value="Teacher">Teacher</option>
            <option value="Student">Student</option>
          </select>

          <select
            className="MAdropdown-menu"
            value={selectedSchool}
            onChange={(e) => setSelectedSchool(e.target.value)}
          >
            <option value="">Filter by School</option>
            <option value="Bascule Faisal Town">Bascule Faisal Town</option>
            <option value="Bascule Johar Town">Bascule Johar Town</option>
          </select>
        </div>
      </div>

      {loading ? (
        <div className="loading-container">
          <ClipLoader size={50} color={"rgb(3, 180, 198)"} loading={loading} />
          <p>Please wait...</p>
        </div>
      ) : (
        // <div className="MAcards-in-row">
        //   {users?.map((user, index) => (
        //     user.username.includes(searchTerm) && (user?.usertype?.includes(selectedRole)) && (user?.address?.includes(selectedSchool)) &&
        //     <UserCard key={index} user={user} updateUserType={updateUserType} updateUserStatus={updateUserStatus} />
        //   ))}
        // </div>
        <>
          <div className="MAcards-in-row">
            {users?.map((user, index) => (
              user.username.includes(searchTerm) &&
              (user?.usertype?.includes(selectedRole)) &&
              (user?.address?.includes(selectedSchool)) &&
              <UserCard
                key={index}
                user={user}
                updateUserType={updateUserType}
                updateUserStatus={updateUserStatus}
              />
            ))}
          </div>
          {hasMore && (
            <div className="load-more-container" style={{ textAlign: 'center', margin: '20px 0' }}>
              <button
                onClick={fetchUsers}
                disabled={loading}
                style={{
                  padding: '10px 20px',
                  backgroundColor: 'rgb(3, 180, 198)',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: loading ? 'not-allowed' : 'pointer'
                }}
              >
                {loading ? 'Loading...' : 'Load More Users'}
              </button>
            </div>
          )}

        </>



      )}


      <Footer />
    </>
  );
};

export default User
