import React,{useContext} from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from '../Footer'

import { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import "./ArchitectureCourseDetails.css";
import "../MathPortal/Slider.css";
import "../MathPortal/MathCourseDetails.css";
import "../MathPortal/MathPortalResponsive.css";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';

import pic1 from "./ArchitectureCourseImages/pic1.png";
import pic2 from "./ArchitectureCourseImages/pic2.png";
import pic3 from "./ArchitectureCourseImages/cuttingPasting.png";
import pic4 from "./ArchitectureCourseImages/conceptualSketching.png";
import pic5 from "./ArchitectureCourseImages/StringArt.png";
import pic6 from "./ArchitectureCourseImages/2D_Drafting.png";
import pic7 from "./ArchitectureCourseImages/3DRoom.png";
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import FeeStructure from '../components/FeeStructure';
import arct from './ArchitectureCourseImages/architectureBackground.jpg'

const ArchitectureCourseDetails = ({selectedCourse}) => {
  function CourseStructure({
    moduleNumber,
    roundBoxColor,
    Activities,
    Lessons,
    smallBoxColor,
    largeBoxColor,
    moduleName,
    moduleDesc,
    roundBoxBgColor,
    smallBoxBgColor,
    largeBoxBgColor,
  }) {
    return (
      <div className="modules">
        {/* round box */}
        <div className="round-box" style={{backgroundColor:roundBoxBgColor}}>
          <p style={{ marginTop: "5vh", color: roundBoxColor }}>
            Module
            <br />
            {moduleNumber}
          </p>
        </div>
        {/* small boxes */}
        <div className="small-box" style={{ color: smallBoxColor,backgroundColor:smallBoxBgColor }}>
          <p style={{ margin: "auto" }}>
            Activities
            <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
              {Activities}
            </p>
          </p>
  
          <div className="small-box sm-box">
            <p style={{ margin: "auto" }}>
              No. of Lessons
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                {Lessons}
              </p>
            </p>
          </div>
        </div>
  
        <div className="large-box" style={{ color: largeBoxColor,backgroundColor:largeBoxBgColor }}>
          <p style={{ margin: "auto" }}>
            {moduleName}
            <p
              style={{
                fontWeight: "lighter",
                fontSize: "15px",
                marginBottom: "0px",
              }}
            >
              {moduleDesc}
            </p>
          </p>
        </div>
      </div>
    );
  }
  const handleCourseSelect = (course) => {
    console.log("Selected Course in ACD:", course);
    selectedCourse(course);
  };

 const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };
  
  
 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
      navigate("/");

    }
      
      const username = userInfo?.username;
      const _avatar_ = userInfo?.avatar;
  return (
    
    <>
    <div >
        {/* Navbar */}
        {username && (
        <>
        {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
        {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
        {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
      </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}

      <div className="ArchitecturePortalBackground">
        <section className="section">
        <p className="PageHead" style={{ color: "#8b0194" }}  >Architecture & Interior Design Course</p>
          <div className="PageDesc">
          <div style={{display:"flex"}}>
              <p style={{
                fontFamily: "KG Primary Whimsy,VanillaMilk,'DM Sans', sans-serif",
                color: "white",
                textAlign: "left",
                padding: "30px",
                fontSize: "22px",
                zIndex: 1,
                color: "#8b0194",
                width:"70%"
                
              }}>
            Polymath-kids offer comprehensive online Architecture & Interior Design course for kids. The online classes’ offers two modes -online 1:1 classes and online group classes with trained teachers having years of experience in Architecture & Interior Design.
            </p>
            <img style={{minWidth:"250px", maxWidth:"450px"}} src={arct}></img>
            </div>
            <div className="btn-row d-flex btn-row-resp btn-row-resp1">

              {/* <div className="btn-row-resp"> */}
              <div style={{ width: "auto", height: "50px" }}>


                <a className="btn-row-resp button btn-color-course" href="#course">Course Structure</a>
              </div>
             

              <div style={{ width: "auto", height: "50px" }}>
                <a className=" btn-row-resp button btn-color-fee" href="#fee">Fee Structure</a>

              </div>
              <div style={{ width: "auto", height: "50px" }}>

                <a className=" btn-row-resp button btn-color-Testimonal" href="#testimonal">Testimonals</a>
              </div>
              <div style={{ width: "auto", height: "50px" }}>
                <a className=" btn-row-resp button btn-color-Faq" href="#Faq">Faqs</a>


              </div>
              <div style={{ width: "auto", height: "50px" }}>
              <a className=" btn-row-resp button btn-color-registered" href="#">Get Registered</a> 
               

              </div>


         
            </div>
          </div>

        </section>
        <div className="overlay"></div>
      </div>

      <div className="scratch-background">
      <section className="section">
        <div className="PageHead" style={{ color: "#8b0194" }}>
            <b>What is Architecture & Interior Design?</b>
          <p
            style={{
              color: "#8b0194",
              marginTop: "2vh",
              fontSize: "4vh",
              fontFamily: "sans serif",
            }}
          >
          Interior architecture focuses more on structural design, with an emphasis on adaptive reuse and the remodelling of existing buildings. Whereas interior design is the practice of creating interior atmospheres – focusing on finishes, furniture and colour palettes.
                <br />
            Both interior designers and interior architects have a similar set of skills but need to apply these skills in diverse ways. Interior design and interior architecture are both creative and innovative careers to work in and can help transform the way we use spaces to live, work and play. 
           </p>
        </div>
      </section>
    </div>

   {/* Course Structure and Syllabus */}
   <section className="section course-structure" id="course">
      <p className="PageHead" style={{color:"#8b0194"}}>
        Course Structure and Syllabus
      </p>
      <h4 className="H4_Heading" style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
        <b>Course Objectives</b>
      </h4>
      <p style={{ color: "#8b0194", fontFamily: "sans-serif" ,padding: "0 19% 0 25%"}}>
      The objective of this architecture and interior design course for kids aged 12 to 18 is to introduce them to the principles and concepts of design, encourage creativity, and develop their skills in architectural and interior design. By the end of the course, students should be able to:
      <br />
        1. Understand the basics of architecture and interior design and how they are applied in real-life situations.<br />
        2. Develop a design concept and create sketches and drawings using various media.<br />
        3. Apply design principles such as color, form, texture, and scale to create visually appealing designs.<br />
        4. Create models and prototypes using basic tools and materials.<br />
        5. Use technology such as 3D modeling software to create digital designs.<br />
        6. Collaborate with peers and communicate their design ideas effectively.<br />
        7. Understand the importance of sustainability in architecture and design and integrate eco-friendly practices into their designs.<br />
        8. Appreciate different styles and periods of architecture and design and their cultural and historical significance.<br />
        9. Showcase their final designs and present their design process to a group.<br />
        10. Develop problem-solving skills through hands-on design challenges and exercises. <br />
        11. 21st Century Life Skills <br />
        12. STEM Education. <br />

        Overall, this course aims to cultivate a lifelong appreciation and understanding of design, inspire creativity, and equip students with the skills and knowledge to pursue further studies or careers in the field of architecture and interior design.
        
      </p>

      <div>
        <p className='moduleSection'>
          <img src={pic1} alt="" style={{ width: "2em", height: "2em" }} />
          Architecture & Interior Design  Beginner Course (Suitable for Grade 7 to 8)
        </p>
      </div>

      {/* Module one */}
      
      <CourseStructure moduleNumber='1' roundBoxColor='#cff7d5' Activities='11' Lessons='7'  smallBoxColor='#249935' largeBoxColor='#249935' moduleName='Basic Design Principles'    moduleDesc="In this module, students will learn about all the principles of designing, which will help them to design spaces logically. They will learn the concept of Balance, Rhythm, Emphasis, Proportion and scale, Movement, Contrast, Unity (Cutting pasting)." roundBoxBgColor='#249935'
      smallBoxBgColor='#cff7d5' largeBoxBgColor='#cff7d5'
      />
      
      {/* Module  two */}
      
      <CourseStructure moduleNumber='2' roundBoxColor='#cff7d5' Activities='3' Lessons='2'
       smallBoxColor='#249935' largeBoxColor='#249935' moduleName='Basic Design Laws'
      moduleDesc="Students will learn about all laws for design the spaces according to the space requirements. They will gain knowledge of standards of design.
      "  roundBoxBgColor='#249935' 
      smallBoxBgColor='#cff7d5' largeBoxBgColor='#cff7d5'
      />
      
       {/* Module  Three */}
      <CourseStructure moduleNumber='3' roundBoxColor='#cff7d5' Activities='1' Lessons='1'smallBoxColor='#249935' largeBoxColor='#249935' moduleName=' Conceptual Design' moduleDesc=" In this module, students will learn the Conceptual designs which will help them to create clear user interfaces which are easy to understand and interpret."  roundBoxBgColor='#249935'
      smallBoxBgColor='#cff7d5' largeBoxBgColor='#cff7d5'
      />

         {/* Module  Four */}
         <CourseStructure moduleNumber='4' roundBoxColor='#cff7d5' Activities='3' Lessons='2'smallBoxColor='#249935' largeBoxColor='#249935' moduleName='Conceptual Sketching' moduleDesc="  Students will go through the process of developing their early ideas and produce numerous sketches through iterative scribbles and doodles that document potential design solutions."  roundBoxBgColor='#249935'
        smallBoxBgColor='#cff7d5' largeBoxBgColor='#cff7d5'
         />

        {/* Module  Five */}
        <CourseStructure moduleNumber='5' roundBoxColor='#cff7d5' Activities='4' Lessons='2'smallBoxColor='#249935' largeBoxColor='#249935' moduleName='Live Sketching' moduleDesc="In this module, students will learn to make capable designs that are easy to comprehend using various objects from basic (objects) to advance level (facial features)."  roundBoxBgColor='#249935'
        smallBoxBgColor='#cff7d5' largeBoxBgColor='#cff7d5'
        />
       
        {/* Module  Six */}
        <CourseStructure moduleNumber='6' roundBoxColor='#cff7d5' Activities='6' Lessons='4' smallBoxColor='#249935' largeBoxColor='#249935' moduleName='Conceptual Modelling' moduleDesc=" Concept models are a great way to shape a project at critical moments. Students will learn to use these concept models as sketches to start things off, mid-process tests to elaborate or refine an idea or as physical diagrams to distill a project for final presentations. They will use various techniques like Origamy, Physical modeling, Modeling with raw materials, Space design modeling."  roundBoxBgColor='#249935' smallBoxBgColor='#cff7d5' largeBoxBgColor='#cff7d5'
        />
    
    
       {/* Module One */}
       <div>
          <p className='moduleSection'style={{color: "#485cd9"}}
          >
            <img src={pic2} alt="" style={{ width: "2em", height: "2em" }} />
            Architecture & Interior Design  Beginner Course 
            (Suitable for Grade 9 to 10)
          </p>
        </div>

        <div className="modules my-3">
          {/* round box */}
          <div className="round-box round-box1 ">
            <p style={{ marginTop: "5vh", color: "#d9defb" }}>
              {" "}
              Module
              <br />
              1
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box small-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>1</p>
            </p>

            <div
              className="small-box small-box1 sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>1</p>
              </p>
            </div>
          </div>

          <div className="large-box large-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
            Basic Design Principles
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >
              In this module, students will learn about all the principles of designing, which will help them to design spaces logically. They will learn the concept of Balance, Rhythm, Emphasis, Proportion and scale, Movement, Contrast, Unity (Cutting pasting).
              </p>
            </p>
          </div>
        </div>
     
        {/* Module Two */}
        <div className="modules my-3">
          {/* round box */}
          <div className="round-box round-box1 ">
            <p style={{ marginTop: "5vh", color: "#d9defb" }}>
              {" "}
              Module
              <br />
              2
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box small-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>1</p>
            </p>

            <div
              className="small-box small-box1 sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>1</p>
              </p>
            </div>
          </div>

          <div className="large-box large-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
            Conceptual Design
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >
              In this module, students will learn the Conceptual designs which will help them to create clear user interfaces which are easy to understand and interpret.
              </p>
            </p>
          </div>
        </div>

        {/* Module Three */}
        <div className="modules my-3">
          {/* round box */}
          <div className="round-box round-box1 ">
            <p style={{ marginTop: "5vh", color: "#d9defb" }}>
              {" "}
              Module
              <br />
              3
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box small-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>1</p>
            </p>

            <div
              className="small-box small-box1 sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>1</p>
              </p>
            </div>
          </div>

          <div className="large-box large-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
            Conceptual Sketching

              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >
            Students will go through the process of developing their early ideas and produce numerous sketches through iterative scribbles and doodles that document potential design solutions.
              </p>
            </p>
          </div>
        </div>

          {/* Module Four */}
        <div className="modules my-3">
          {/* round box */}
          <div className="round-box round-box1 ">
            <p style={{ marginTop: "5vh", color: "#d9defb" }}>
              {" "}
              Module
              <br />
              4
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box small-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>1</p>
            </p>

            <div
              className="small-box small-box1 sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>1</p>
              </p>
            </div>
          </div>

          <div className="large-box large-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
            Conceptual Modelling
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >          
                Concept models are a great way to shape a project at critical moments. Students will learn to use these concept models as sketches to start things off, mid-process tests to elaborate or refine an idea or as physical diagrams to distill a project for final presentations. They will use various techniques like Origamy, Physical modeling, Modeling with raw materials, Space design modeling.
              </p>
            </p>
          </div>
        </div>   

        {/* Module Five */}
        <div className="modules my-3">
          {/* round box */}
          <div className="round-box round-box1 ">
            <p style={{ marginTop: "5vh", color: "#d9defb" }}>
              {" "}
              Module
              <br />
              5
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box small-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>1</p>
            </p>

            <div
              className="small-box small-box1 sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>1</p>
              </p>
            </div>
          </div>

          <div className="large-box large-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
            Landscaping

              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >          
            Landscaping provides excellent means to create pocket parks, roof gardens, etc. to utilize the small open spaces in urban areas. Students will learn to deal with the crisis of space by creating a cost-effective & sustainable development of green plants & natural landforms. 
              </p>
            </p>
          </div>
        </div>   

        {/* Module Six */}
        <div className="modules my-3">
          {/* round box */}
          <div className="round-box round-box1 ">
            <p style={{ marginTop: "5vh", color: "#d9defb" }}>
              {" "}
              Module
              <br />
              6
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box small-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>1</p>
            </p>

            <div
              className="small-box small-box1 sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>1</p>
              </p>
            </div>
          </div>

          <div className="large-box large-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
            Building Material Study
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >          
                The building material affects the vision of a structure, as it deals with the appearance-related qualities of the structure. It also impacts the durability of the structure. Students will learn the pros & cons and also the availability of the materials.
              </p>
            </p>
          </div>
        </div>   

          {/* Module Seven */}
          <div className="modules my-3">
          {/* round box */}
          <div className="round-box round-box1 ">
            <p style={{ marginTop: "5vh", color: "#d9defb" }}>
              {" "}
              Module
              <br />
              7
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box small-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>1</p>
            </p>

            <div
              className="small-box small-box1 sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>1</p>
              </p>
            </div>
          </div>

          <div className="large-box large-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
            AutoCAD 2D
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >          
                In this module, students will learn drafting in AutoCAD. 
              </p>
            </p>
          </div>
        </div>
        
         {/* Module Eight */}
         <div className="modules my-3">
          {/* round box */}
          <div className="round-box round-box1 ">
            <p style={{ marginTop: "5vh", color: "#d9defb" }}>
              {" "}
              Module
              <br />
              8
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box small-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>5</p>
            </p>

            <div
              className="small-box small-box1 sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>4</p>
              </p>
            </div>
          </div>

          <div className="large-box large-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
            3D Modelling
            
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >          
                3D modeling is very important now days. Students will have the opportunity to learn Sketcup. 
              </p>
            </p>
          </div>
        </div>   
        {/* Module nine */}
        <div className="modules my-3">
          {/* round box */}
          <div className="round-box round-box1 ">
            <p style={{ marginTop: "5vh", color: "#d9defb" }}>
              {" "}
              Module
              <br />
              9
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box small-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>2</p>
            </p>

            <div
              className="small-box small-box1 sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>2</p>
              </p>
            </div>
          </div>

          <div className="large-box large-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
            Architrechtural Photoshop
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >          
                Students will learn Architectural designing in Photoshop.
              </p>
            </p>
          </div>
        </div>   
      
          {/* Module one */}
          <div>
            <p className='moduleSection' style={{color: "#999924"}}
            >
                <img src={pic2} alt=""style={{width:"2em",height:"2em"}} />
                Architecture & Interior Design  Beginner Course 
(Suitable for Grade 11 to 12)
            </p>
          </div>

          <div className="modules">
            {/* round box */}
            <div className="round-box RB1 ">
              <p style={{ marginTop: "5vh", color: "#f8f8da" }}>
                {" "}
                Module
                <br />
                1
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box SB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  1
                </p>
              </p>

              <div
                className="small-box SB1 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   1
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box LB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
              Basic Design Principles
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
              In this module, students will learn about all the principles of designing, which will help them to design spaces logically. They will learn the concept of Balance, Rhythm, Emphasis, Proportion and scale, Movement, Contrast, Unity (Cutting pasting).

                </p>
              </p>
            </div>
          </div>

          {/* Module 2 */}
          <div className="modules">
            {/* round box */}
            <div className="round-box RB1 ">
              <p style={{ marginTop: "5vh", color: "#f8f8da" }}>
                {" "}
                Module
                <br />
                2
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box SB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  1
                </p>
              </p>

              <div
                className="small-box SB1 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   1
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box LB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
              Conceptual Design

                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                    This module introduces the students to AUTOCAD software and will enable them to develop house plans with medium difficulty level. 
                </p>
              </p>
            </div>
          </div>

           {/* Module 3 */}
           <div className="modules">
            {/* round box */}
            <div className="round-box RB1 ">
              <p style={{ marginTop: "5vh", color: "#f8f8da" }}>
                {" "}
                Module
                <br />
                3
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box SB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  1
                </p>
              </p>

              <div
                className="small-box SB1 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   1
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box LB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
            Conceptual Sketching
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                Students will go through the process of developing their early ideas and produce numerous sketches through iterative scribbles and doodles that document potential design solutions. 
                </p>
              </p>
            </div>
          </div>

           {/* Module 4*/}
           <div className="modules">
            {/* round box */}
            <div className="round-box RB1 ">
              <p style={{ marginTop: "5vh", color: "#f8f8da" }}>
                {" "}
                Module
                <br />
                4
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box SB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  1
                </p>
              </p>

              <div
                className="small-box SB1 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                1
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box LB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
              Conceptual Modelling
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                Concept models are a great way to shape a project at critical moments. Students will learn to use these concept models as sketches to start things off, mid-process tests to elaborate or refine an idea or as physical diagrams to distill a project for final presentations. They will use various techniques like Origamy, Physical modeling, Modeling with raw materials, Space design modeling.
                </p>
              </p>
            </div>
          </div>

            {/* Module 5*/}
            <div className="modules">
            {/* round box */}
            <div className="round-box RB1 ">
              <p style={{ marginTop: "5vh", color: "#f8f8da" }}>
                {" "}
                Module
                <br />
                5
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box SB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  1
                </p>
              </p>

              <div
                className="small-box SB1 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   1
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box LB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>     
                     Landscaping
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                    Landscaping provides excellent means to create pocket parks, roof gardens, etc. to utilize the small open spaces in urban areas. Students will learn to deal with the crisis of space by creating a cost-effective & sustainable development of green plants & natural landforms. 
                </p>
              </p>
            </div>
          </div>
         
          {/* Module 6*/}
          <div className="modules">
            {/* round box */}
            <div className="round-box RB1 ">
              <p style={{ marginTop: "5vh", color: "#f8f8da" }}>
                {" "}
                Module
                <br />
                6
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box SB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  1
                </p>
              </p>

              <div
                className="small-box SB1 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   1
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box LB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
              Building Material Study
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                The building material affects the vision of a structure, as it deals with the appearance-related qualities of the structure. It also impacts the durability of the structure. Students will learn the pros & cons and also the availability of the materials.
                </p>
              </p>
            </div>
          </div>

          {/* Module 7*/}
          <div className="modules">
            {/* round box */}
            <div className="round-box RB1 ">
              <p style={{ marginTop: "5vh", color: "#f8f8da" }}>
                {" "}
                Module
                <br />
                7
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box SB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  5
                </p>
              </p>

              <div
                className="small-box SB1 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   4
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box LB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
              AutoCAD 2D
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                    In this module, students will learn drafting in AutoCAD. 
                </p>
              </p>
            </div>
          </div>

           {/* Module 8*/}
           <div className="modules">
            {/* round box */}
            <div className="round-box RB1 ">
              <p style={{ marginTop: "5vh", color: "#f8f8da" }}>
                {" "}
                Module
                <br />
                8
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box SB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  7
                </p>
              </p>

              <div
                className="small-box SB1 sm-box" 
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   6
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box LB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
              3D Modelling
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                    3D modeling is very important now days. Students will have the opportunity to learn Sketcup
                </p>
              </p>
            </div>
          </div>
         {/* Module 9*/}
         <div className="modules">
            {/* round box */}
            <div className="round-box RB1 ">
              <p style={{ marginTop: "5vh", color: "#f8f8da" }}>
                {" "}
                Module
                <br />
                9
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box SB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  2
                </p>
              </p>

              <div
                className="small-box SB1 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   2
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box LB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
              Rendering
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                    The greatest advantage of 3D renderings is they make the project real for clients, particularly those that can't read flat or black and white drawings. Students will have the opportunity to learn Lumion, Enscape.
                </p>
              </p>
            </div>
          </div>

          {/* Module 10*/}
         <div className="modules">
            {/* round box */}
            <div className="round-box RB1 ">
              <p style={{ marginTop: "5vh", color: "#f8f8da" }}>
                {" "}
                Module
                <br />
                10
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box SB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  2
                </p>
              </p>

              <div
                className="small-box SB1 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   2
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box LB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
              Architectural Photoshop
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                        Students will learn Architectural designing in Photoshop.
                </p>
              </p>
            </div>
          </div>

     {/* Module one */}
         <div>
            <p className='moduleSection'
              style={{color: "#b8584a"}}
            >
                <img src={pic2} alt=""style={{width:"2em",height:"2em"}} />
                Architecture & Interior Design  Beginner Course 
(Suitable for Grade 12+)
            </p>
          </div>

          <div className="modules">
            {/* round box */}
            <div className="round-box RB2 ">
              <p style={{ marginTop: "5vh", color: "#f8f8da" }}>
                {" "}
                Module
                <br />
                1
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box SB2" style={{ color: "#b8584a" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  4
                </p>
              </p>

              <div
                className="small-box SB2 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   3
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box LB2" style={{ color: "#b8584a" }}>
              <p style={{ margin: "auto" }}>
              Basic Design Principles
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
              In this module, students will learn about all the principles of designing, which will help them to design spaces logically. They will learn the concept of Balance, Rhythm, Emphasis, Proportion and scale, Movement, Contrast, Unity (Cutting pasting).

                </p>
              </p>
            </div>
          </div>

        {/* Module two */}
        <div className="modules">
            {/* round box */}
            <div className="round-box RB2 ">
              <p style={{ marginTop: "5vh", color: "#f8f8da" }}>
                {" "}
                Module
                <br />
                2
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box SB2" style={{ color: "#b8584a" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  9
                </p>
              </p>

              <div
                className="small-box SB2 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   8
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box LB2" style={{ color: "#b8584a" }}>
              <p style={{ margin: "auto" }}>
             AutoCAD 2D
                
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                    In this module, students will learn drafting in AutoCAD. 

                </p>
              </p>
            </div>
          </div>

            {/* Module Three */}
            <div className="modules">
            {/* round box */}
            <div className="round-box RB2 ">
              <p style={{ marginTop: "5vh", color: "#f8f8da" }}>
                {" "}
                Module
                <br />
                3
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box SB2" style={{ color: "#b8584a" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  13
                </p>
              </p>

              <div
                className="small-box SB2 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   12
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box LB2" style={{ color: "#b8584a" }}>
              <p style={{ margin: "auto" }}>
              3D Modelling
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                3D modeling is very important now days. Students will have the opportunity to learn Sketcup, 3D max.
                </p>
              </p>
            </div>
          </div>

           {/* Module Four */}
           <div className="modules">
            {/* round box */}
            <div className="round-box RB2 ">
              <p style={{ marginTop: "5vh", color: "#f8f8da" }}>
                {" "}
                Module
                <br />
                4
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box SB2" style={{ color: "#b8584a" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  7
                </p>
              </p>

              <div
                className="small-box SB2 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   6
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box LB2" style={{ color: "#b8584a" }}>
              <p style={{ margin: "auto" }}>
              Rendering
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                The greatest advantage of 3D renderings is they make the project real for clients, particularly those that can't read flat or black and white drawings. Students will have the opportunity to learn Lumion, Enscape.
                </p>
              </p>
            </div>
          </div>

           {/* Module Five */}
           <div className="modules">
            {/* round box */}
            <div className="round-box RB2 ">
              <p style={{ marginTop: "5vh", color: "#f8f8da" }}>
                {" "}
                Module
                <br />
                5
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box SB2" style={{ color: "#b8584a" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  7
                </p>
              </p>

              <div
                className="small-box SB2 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   6
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box LB2" style={{ color: "#b8584a" }}>
              <p style={{ margin: "auto" }}>
              Architectural Photoshop
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                    Students will learn Architectural designing in Photoshop.
                </p>
              </p>
            </div>
          </div>


      <h6 style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
        <b>Learn Graphic Designing in Adobe Illustrator with 30+ Fun Activities</b>
      </h6>

      <div className="activityImagesContainer">
        <div className="row">
          <div class="col-lg mx-2">
            <img src={pic5} alt="" className='ActivityImages' />
            <p className="mx-3">Cutting Pasting</p>
          </div>

          <div class="col-lg mx-2">
            <img src={pic4} alt="" className='ActivityImages'/>
            <p className="mx-3">Conceptual Sketching</p>
          </div>

          <div class="col-lg mx-2">
            <img src={pic5} alt="" className='ActivityImages'/>
            <p className="mx-3">String Art</p>
          </div>

          <div class="col-lg mx-2">
            <img src={pic6} alt="" className='ActivityImages'/>
            <p className="mx-3">2D Drafting</p>
          </div>

          <div class="col-lg mx-2">
            <img src={pic7} alt="" className='ActivityImages'/>
            <p className="mx-3">3D Room Interior</p>
          </div>

        </div>
      </div>
    </section>
     
 

      {/* Fee Structure */}
      <FeeStructure subject="Architecture & Interior Design"/>

      {/* Testimonals */}
      <div class="  background" id="testimonal">
        <div class="row" style={{ width: "100%" }}>
          <div class="col-lg-8 mx-auto">
            <h2 className="Testimonal_Heading">Testimonials</h2>
            <div id="myCarousel" class="carousel slide" data-ride="carousel">
              {/* <!-- Carousel indicators --> */}
              <ol class="carousel-indicators">
                <li
                  data-target="#myCarousel"
                  data-slide-to="0"
                  class="active"
                ></li>
                <li data-target="#myCarousel" data-slide-to="1"></li>
              </ol>
              {/* <!-- Wrapper for carousel items --> */}
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="img-box">
                    <div className=" img-box1"></div>
                  </div>
                  <p
                    class="testimonial"
                    style={{ color: "black", fontSize: "3vh" }}
                  >
                    I enjoyed the learning experience with Polymath-Kids-Abacus.
                    The teacher was very friendly. I highly recommend
                    Polymath-Kids-Abacus.
                  </p>
                  <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                    <b>Mujtaba Khawaja Sahaf</b>
                  </p>
                </div>

                <div class="carousel-item">
                  <div class="img-box">
                    <div className="img-box2 "></div>
                  </div>
                  <p
                    class="testimonial"
                    style={{ color: "black", fontSize: "3vh" }}
                  >
                    I feel so proud when my class fellows call me ’BRAINIAC ’,
                    because I can effortlessly do mental calculations much
                    faster and accurate than my class mates. Thank you
                    Polymath-Kids.
                  </p>
                  <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                    <b>Wahaab Omer</b>, Age 9 Years
                  </p>
                </div>
              </div>
              {/* <!-- Carousel controls --> */}
              <a
                class="carousel-control-prev"
                href="#myCarousel"
                data-slide="prev"
              >
                <i class="fa fa-angle-left"></i>
              </a>
              <a
                class="carousel-control-next"
                href="#myCarousel"
                data-slide="next"
              >
                <i class="fa fa-angle-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Faqs */}
      <div style={{ maxWidth: '100%', backgroundColor: '#edffff' }} id="Faq">

        <MDBContainer className="mt-0" style={{ maxWidth: '180vh' }}>
          <MDBAccordion alwaysOpen initialActive={1} >
            <div className="text-center PageHead" style={{ color: "#8b0194" }}> FAQs</div>
            
            <MDBAccordionItem collapseId={3} headerTitle="      Q: What age range is this course designed for?">
              <strong>A: </strong>
              
                Polymath-Kids has developed separate customized and age appropriate Architecture & Interior Design curriculum for three age groups i.e. grade 7 to 8, grade 9 to 10 and grade 11 to 12.
            </MDBAccordionItem>

            <MDBAccordionItem collapseId={3} headerTitle="Q: Is previous experience in architecture or interior design required?">
              <strong>A: </strong>
              No previous experience is required, as this course is designed to introduce students to the principles and concepts of design.
            </MDBAccordionItem>
            <MDBAccordionItem collapseId={3} headerTitle="Q: What materials will be needed for the course?">
              <strong>A: </strong>
              Basic materials such as pencils, pens, paper, and rulers will be required, as well as access to technology such as computers or tablets. Additional materials for creating models and prototypes will be provided.
            </MDBAccordionItem>
            <MDBAccordionItem collapseId={3} headerTitle="Q: Will students have the opportunity to work on real-life design projects?">
              <strong>A: </strong>
              While students will not be working on actual building projects, they will have the opportunity to work on design challenges and exercises that simulate real-life design scenarios.
            </MDBAccordionItem>
            <MDBAccordionItem collapseId={3} headerTitle="Q: Will the course cover sustainability and eco-friendly design practices?">
              <strong>A: </strong>
              While students will not be working on actual building projects, they will have the opportunity to work on design challenges and exercises that simulate real-life design scenarios.
            </MDBAccordionItem>
            <MDBAccordionItem collapseId={3} headerTitle="Q: Will students have the opportunity to collaborate with their peers?">
              <strong>A: </strong>
               Yes, students will have opportunities to collaborate with their peers on design projects and exercises.
            </MDBAccordionItem>
            <MDBAccordionItem collapseId={3} headerTitle="Q: Will students learn about different styles and periods of architecture and design?">
              <strong>A: </strong>

             Yes, students will learn about different styles and periods of architecture and design, and their cultural and historical significance.
            </MDBAccordionItem>
            <MDBAccordionItem collapseId={3} headerTitle="Q: Will there be opportunities for students to showcase their final designs?">
              <strong>A: </strong>
             Yes, students will have the opportunity to showcase their final designs and present their design process to a group.
            </MDBAccordionItem>
            <MDBAccordionItem collapseId={3} headerTitle="Q: Will this course prepare students for further studies or careers in architecture and interior design?">
              <strong>A: </strong>
             This course is designed to introduce students to the principles and concepts of architecture and interior design, and to develop their skills in these areas. While it may not provide a comprehensive preparation for further studies or careers in the field, it will certainly be a valuable starting point.
            </MDBAccordionItem>

            

          </MDBAccordion>
        </MDBContainer>

      </div>

                {/* Footer */}
                <Footer/>
    </div>
  </>
  )
}

export default ArchitectureCourseDetails
