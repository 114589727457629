import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './LeaderBoard.css';

const LeaderBoard = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [currentStudentData, setCurrentStudentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Get URL parameters
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const studentId = queryParams.get('studentId');
  const activityId = queryParams.get('activityId');

  useEffect(() => {
    // Replace with your API endpoint for fetching leaderboard data
    const fetchData = async () => {
      await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/leaderboardRank/${activityId}`)
      .then(data=>data.json()).then(response => { 
        const studentData = response.find(entry => entry.Student_id === parseInt(studentId));
        setCurrentStudentData(studentData);
        setLeaderboard(response);
        console.log(response)})
    }

    fetchData()
  }, []);

  return (
    <div className="leaderboard-container">
      <header className="leaderboard-header">
        <h1>{currentStudentData?.firstname}  {currentStudentData?.lastname}, you are about to start the Speed Quiz: "GOOD LUCK"</h1>
      </header>
      <section className="leaderboard-message">
        <p>Dear {currentStudentData?.firstname}  {currentStudentData?.lastname},</p>
        <p>Thank you for participating in the speed quiz. Please review the result of your speed quiz below:</p>
      </section>
      <div className="leaderboard-summary">
        <div className="summary-item">
          <span>Total Time:</span>
          <span>{currentStudentData?.TimeTaken}</span>
        </div>
        <div className="summary-item">
          <span>Total Questions Answered:</span>
          <span> {currentStudentData?.TotalMarks}</span>
        </div>
        <div className="summary-item">
          <span>Total Correct Answers:</span>
          <span>{currentStudentData?.MarksObtained}</span>
        </div>
        <div className="summary-item">
          <span>Leaderboard Position:</span>
          <span> - </span>
        </div>
      </div>
      <table className="leaderboard-table">
        <thead>
          <tr>
            <th>Position</th>
            <th>Name</th>
            <th>Questions Answered</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          {leaderboard?.map((entry, index) => (
            <tr key={index} style={entry.Student_id==studentId?{fontWeight:"bold"}:null}>
              <td>{index+1}</td>
              <td>{entry?.firstname+" "+entry?.lastname}</td>
              <td>{entry?.TotalMarks}</td>
              <td>{entry?.MarksObtained}%</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LeaderBoard;
