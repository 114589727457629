import React,{useContext} from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from '../Footer'
import "./CodingCourseDetails.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../MathPortal/Slider.css";
import "../MathPortal/MathCourseDetails.css";
import "../MathPortal/MathPortalResponsive.css";
import "./Android.css";
import "./ScratchProgramming.css";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import pic1 from "./CodingCourseImages/webdev.png";
import pic2 from "./CodingCourseImages/ScrollingTextApp.png";
import pic3 from "./CodingCourseImages/counter.jpg";
import pic4 from "./CodingCourseImages/FoodOrdering.jpg";
import pic5 from "./CodingCourseImages/AlarmApp.jpg";
import pic6 from "./CodingCourseImages/Dictionaryapp.jpg";
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import FeeStructure from '../components/FeeStructure';
import Android from './CodingCourseImages/AndroidBackground.jfif'

const WebDev = ({selectedCourse}) => {
  const handleCourseSelect = (course) => {
    console.log("Selected Course in Home:", course);
    selectedCourse(course);
  };

 const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };
  
  
 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
      navigate("/");

    }
      
      const username = userInfo?.username;
      const _avatar_ = userInfo?.avatar;
   
  return (
   
    <div >
      
     {/* Navbar */}
     {username && (
        <>
        {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
        {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
        {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
      </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}
 
      <div className="Webdev-Backgroundd">
        <section className="section">
          <p className="PageHead" style={{ color: "#8b0194" }}>Android App Development Course</p>
          <div className="PageDescr">
          <div style={{display:"flex"}}>
              <p style={{
                fontFamily: "KG Primary Whimsy,VanillaMilk,'DM Sans', sans-serif",
                color: "white",
                textAlign: "left",
                padding: "30px",
                fontSize: "22px",
                zIndex: 1,
                color: "#8b0194",
                width:"70%"
                
              }}>
            Polymath-Kids App Development Program for kids is a comprehensive course which enables them to develop and publish their own interesting and creative Apps. There are countless career opportunities for individuals proficient in app development essentials. Learning to code at an early age can help learners become experts and reap the benefits provided by technology.
            </p>
            <img style={{minWidth:"250px", maxWidth:"450px"}} src={Android}></img>
            </div>
            <div className="btn-row d-flex btn-row-resp btn-row-resp1">
              {/* <div className="btn-row-resp"> */}
              <div style={{ width: "auto", height: "50px" }}>
                <a
                  className="btn-row-resp button btn-color-course"
                  href="#course"
                >
                  Course Structure
                </a>
              </div>

              <div style={{ width: "auto", height: "50px" }}>
                <a className=" btn-row-resp button btn-color-fee" href="#fee">
                  Fee Structure
                </a>
              </div>
              <div style={{ width: "auto", height: "50px" }}>
                <a
                  className=" btn-row-resp button btn-color-Testimonal"
                  href="#testimonal"
                >
                  Testimonals
                </a>
              </div>
              <div style={{ width: "auto", height: "50px" }}>
                <a className=" btn-row-resp button btn-color-Faq" href="#Faq">
                  Faqs
                </a>
              </div>
              <div style={{ width: "auto", height: "50px" }}>
                <a
                  className=" btn-row-resp button btn-color-registered"
                  href="#"
                >
                  Get Registered
                </a>
              </div>
            </div>
          </div>
        </section>
        <div className="overlay"></div>
      </div>

      {/* Intro text */}
      <div className="scratch-background">
        <section className="section">
          <p className="PageHead" style={{ color: "#8b0194" }}>
          What is App Development?
          </p>
          <div className="PageDesc">
            <p
              style={{
                color: "#8b0194",
                marginTop: "2vh",
                fontSize: "4vh",
                fontFamily: "sans serif",
              }}
            >
            Learning to code involves critical thinking and combining scientific and digital knowledge to develop applications and games that help kids express themselves. Polymath-Kids App Development Program provides the fundamentals of developing apps and games for smart phones. Using a structured approach, students will learn to develop simple apps, single and multi-screen apps and engaging gaming apps. This course enables learners to bring their app ideas to life through a range of in class activities and App projects.
            </p>
          </div>
        </section>
      </div>

      {/* Course Structure and Syllabus */}
      <section className="section course-structure" id="course">
        <p className="PageHead" style={{ color: "#8b0194" }}>
          Course Structure and Syllabus
        </p>
        <h4 className="H4_Heading" style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
          <b>Course Objectives</b>
        </h4>
        <ul style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
          <li>Understand Object-Oriented Programming (OOP) concepts and their relevance in Android development.</li>
          <li>Comprehend Classes and Objects, Inheritance, Polymorphism, Exception Handling, and Threads in the context of Android. </li>
          <li> Learn about Views, Layouts, and Resources in Android UI design. </li>
          <li>Implement Text and Scrolling Views effectively.</li>
          <li>Manage Activities, Intents, and the Activity Lifecycle  </li>
          <li> Utilize Recycle View, Dialogs, Pickers, and * Common Gestures for enhanced user interaction. </li>
          <li> Work with Drawables, Asset Studio, Styles, Themes, and UI formatting. </li>
          <li> Understand Motion and Components, along with testing User Interface elements. </li>
          <li> Learn the concept of Firebase. </li>
          
        </ul>

        <div>
          <p className='moduleSection' >
            <img src={pic1} alt="" style={{ width: "2em", height: "2em" }} />
            App Development (Android Studio) Course
          </p>
        </div>

        {/* Module one */}
        <div className="modules">
          {/* round box */}
          <div className="round-box">
            <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
              {" "}
              Module
              <br />
              1
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box" style={{ color: "#249935" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>10</p>
            </p>

            <div
              className="small-box sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>5</p>
              </p>
            </div>
          </div>

          <div className="large-box" style={{ color: "#249935" }}>
            <p style={{ margin: "auto" }}>
            Object Oriented Programming (OOP) Concepts
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >
                The "OOP Concepts" module offers a thorough Java programming introduction, covering language basics and progressing to Object-Oriented Programming (OOP) principles like classes, inheritance, polymorphism, method overloading, and exception handling for creating robust, responsive Java applications.
            </p>
            </p>
          </div>
        </div>

        {/* Module  two */}
        <div className="modules" style={{ marginTop: "2vh" }}>
          {/* round box */}
          <div className="round-box">
            <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
              {" "}
              Module
              <br />
              2
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box" style={{ color: "#249935" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>10</p>
            </p>

            <div
              className="small-box sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>5</p>
              </p>
            </div>
          </div>

          <div className="large-box" style={{ color: "#249935" }}>
            <p className="my-4" style={{ margin: "auto" }}>
            Android App
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >
                The "Android App" module introduces the creation of mobile apps on Android via Android Studio, covering Views, Layouts, Resources, text and scrolling Views, Activities, Intents, and Activity Lifecycle Management for a seamless user experience.
              </p>
            </p>
          </div>
        </div>
        {/* Module  three */}
        <div className="modules" style={{ marginTop: "2vh" }}>
          {/* round box */}
          <div className="round-box">
            <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
              {" "}
              Module
              <br />
              3
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box" style={{ color: "#249935" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>6</p>
            </p>

            <div
              className="small-box sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>3</p>
              </p>
            </div>
          </div>

          <div className="large-box" style={{ color: "#249935" }}>
            <p className="my-4" style={{ margin: "auto" }}>
            User Interaction and navigation
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >
                This module teaches students to create intuitive mobile apps by exploring user input controls and efficient navigation structures. It covers components like Recycle View, Dialogs, and Gestures, empowering students to craft interactive interfaces for user-friendly experiences.
              </p>
            </p>
          </div>
        </div>
{/* Module  four */}
<div className="modules" style={{ marginTop: "2vh" }}>
          {/* round box */}
          <div className="round-box">
            <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
              {" "}
              Module
              <br />
              4
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box" style={{ color: "#249935" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>6</p>
            </p>

            <div
              className="small-box sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>3</p>
              </p>
            </div>
          </div>

          <div className="large-box" style={{ color: "#249935" }}>
            <p className="my-4" style={{ margin: "auto" }}>
            Delightful User Experience
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >
                In the "Delightful User Experience" module, students learn to design captivating interfaces using Drawable and Asset Studio tools. They explore Styles, Themes, Motion, and Components, gaining skills in crafting visually appealing and interactive elements, and conclude by mastering UI testing for optimal usability and satisfaction.
              </p>
            </p>
          </div>
        </div>
        {/* Module  five */}
        <div className="modules" style={{ marginTop: "2vh" }}>
          {/* round box */}
          <div className="round-box">
            <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
              {" "}
              Module
              <br />
              5
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box" style={{ color: "#249935" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>6</p>
            </p>

            <div
              className="small-box sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>3</p>
              </p>
            </div>
          </div>

          <div className="large-box" style={{ color: "#249935" }}>
            <p className="my-4" style={{ margin: "auto" }}>
            Services 
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >
                This module covers essential aspects of Android app development, including internet connectivity, scheduling tasks with the Alarm Manager, utilizing the Job Scheduler for background tasks, and efficient data storage management for enhanced user experiences, empowering students to craft robust, responsive Android applications.
              </p>
            </p>
          </div>
        </div>
{/* Module  six */}
<div className="modules" style={{ marginTop: "2vh" }}>
          {/* round box */}
          <div className="round-box">
            <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
              {" "}
              Module
              <br />
              6
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box" style={{ color: "#249935" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>10</p>
            </p>

            <div
              className="small-box sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>5</p>
              </p>
            </div>
          </div>

          <div className="large-box" style={{ color: "#249935" }}>
            <p className="my-4" style={{ margin: "auto" }}>
            Firebase
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >
                This module teaches students app development, covering Firebase integration, user registration, data storage in Firebase Realtime Database, image uploading to Firebase Storage, and data retrieval. The practical application involves creating a Food Ordering App, providing hands-on experience building real-world Firebase applications. </p>
            </p>
          </div>
        </div>


        <h6 style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
          <b>Learn App Development with 40+ Fun Activities</b>
        </h6>

        <div className="activityImagesContainer">
          <div class="row">
            <div class="col-lg mx-3">
              <img src={pic2} alt="" className='ActivityImages'/>
              <p className="mx-3">Scrolling Text App</p>
            </div>

            <div class="col-lg mx-3">
              <img src={pic3} alt="" className='ActivityImages' />
              <p className="mx-3">Counter App</p>
            </div>

            <div class="col-lg mx-3">
              <img src={pic4} alt="" className='ActivityImages'/>
              <p className="mx-3">Food Ordering App</p>
            </div>

            <div class="col-lg mx-3">
              <img src={pic5} alt="" className='ActivityImages'/>
              <p className="mx-3">Alarm Manager</p>
            </div>

            <div class="col-lg mx-3">
              <img src={pic6} alt="" className='ActivityImages'/>
              <p className="mx-3">Dictionary App</p>
            </div>

          </div>
        </div>
      </section>

      {/* Fee Structure */}
      <FeeStructure subject="Coding"/>

      {/* Testimonals */}
      <div class="  background" id="testimonal">
        <div class="row" style={{ width: "100%" }}>
          <div class="col-lg-8 mx-auto">
            <h2 className="Testimonal_Heading">Testimonials</h2>
            <div id="myCarousel" class="carousel slide" data-ride="carousel">
              {/* <!-- Carousel indicators --> */}
              <ol class="carousel-indicators">
                <li
                  data-target="#myCarousel"
                  data-slide-to="0"
                  class="active"
                ></li>
                <li data-target="#myCarousel" data-slide-to="1"></li>
              </ol>
              {/* <!-- Wrapper for carousel items --> */}
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="img-box">
                    <div className=" img-box1"></div>
                  </div>
                  <p
                    class="testimonial"
                    style={{ color: "black", fontSize: "3vh" }}
                  >
                    I enjoyed the learning experience with Polymath-Kids-Abacus.
                    The teacher was very friendly. I highly recommend
                    Polymath-Kids-Abacus.
                  </p>
                  <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                    <b>Mujtaba Khawaja Sahaf</b>
                  </p>
                </div>

                <div class="carousel-item">
                  <div class="img-box">
                    <div className="img-box2 "></div>
                  </div>
                  <p
                    class="testimonial"
                    style={{ color: "black", fontSize: "3vh" }}
                  >
                    I feel so proud when my class fellows call me ’BRAINIAC ’,
                    because I can effortlessly do mental calculations much
                    faster and accurate than my class mates. Thank you
                    Polymath-Kids.
                  </p>
                  <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                    <b>Wahaab Omer</b>, Age 9 Years
                  </p>
                </div>
              </div>
              {/* <!-- Carousel controls --> */}
              <a
                class="carousel-control-prev"
                href="#myCarousel"
                data-slide="prev"
              >
                <i class="fa fa-angle-left"></i>
              </a>
              <a
                class="carousel-control-next"
                href="#myCarousel"
                data-slide="next"
              >
                <i class="fa fa-angle-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Faqs */}
      <div style={{ maxWidth: "100%", backgroundColor: "#edffff" }} id="Faq">
        <MDBContainer className="mt-0" style={{ maxWidth: "180vh" }}>
          <MDBAccordion alwaysOpen initialActive={1}>
            <div className="text-center PageHead" style={{ color: "#8b0194" }}>
              {" "}
              FAQs
            </div>
            <MDBAccordionItem
              collapseId={1}
              headerTitle="Q: What is App development?"
            >
              <strong>A: </strong>
              Application development is a process of creating software that allows you to perform required tasks. Applications can be developed for use on desktops or mobile devices. The App development process involves gathering requirements, designing, testing, implementing and integrating the applications.            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={2}
              headerTitle="Q: How can kids create their own mobile apps?"
            >
              <strong>A: </strong>
              
              Kids are naturally curious and love to build on to their ideas. There are a variety of mobile app creation tools that let kids create an app and publish it as well without having hardcore coding skills. 
Polymath-Kids provide the finest platform for kids to learn and develop their own apps with the help of expert teachers. Polymath-Kids’ App Development Program for kids provides a structured approach to learn, develop and publish their apps on Google Play. Students get the opportunity to learn a variety of app development tools and techniques, utilize them for their own App development projects, get assessed and get Certificates to show case their achievement.
            </MDBAccordionItem>

            <MDBAccordionItem
              collapseId={3}
              headerTitle="Q: Why should kids learn app development?"
            >
              <strong>A: </strong>
              App development for children requires them to apply reasoning and logic skills along with critical thinking and problem solving. These 21st Century life skills help them throughout their personal and professional career.
App development help kids learn the working behind the app and how the commands are used to get desired outcome. This process assist child to learn, how to convert their ideas into reality and express their thoughts through a working app. 
Polymath-Kids App Development Classes encourages kids to think beyond the ordinary and create engaging applications to solve real world problems. The program enhances kids’ efficiency by encouraging them to utilize their time by learning something new and productive.
                  </MDBAccordionItem>

            <MDBAccordionItem
              collapseId={3}
              headerTitle="Q: What are the results of learning app development for kids?"
            >
              <strong>A: </strong>
             
              App development for kids is a developing field with a lot to explore. While developing apps, kids not only learn the technical knowledge but also develop the research skills by investing time into finding more details about a particular game of interest and how to make it interesting.
              <li>
              Learn to code from an early age can help kids become experts in the field and out shine others.
              </li>
              <li>
              App development involves a variety of 21st Century Life Skills such as digital literacy, creative design thinking, applying logic and reasoning, real world problem solving and ability to express them. 
              </li>
              </MDBAccordionItem>

            <MDBAccordionItem
              collapseId={3}
              headerTitle="Q: Which is the best mobile app development course for kids?"
            >
              <strong>A: </strong>
              Polymath-Kids App Development Program provides the best structured approach to help kids master the fundamentals of App Development. Under the supervision of trained expert teachers, students get the opportunity to work on a variety of applications and projects to develop their own personalized Apps. Students also learn how to publish their apps on Google Play Store. </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={3}
              headerTitle="Q: What prerequisites do I need for this course?"
            >
              <strong>A: </strong>
              Prior programming experience in languages like Java or OPP concepts.
              
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={3}
              headerTitle="Q: What are the best web development courses for kids?"
            >
              <strong>A: </strong>
              You will find many free platforms and videos which can be helpful in teaching Web Development to kids. But the lack of mentorship and direct guidance can hinder their progress.
              Polymath-Kids offers age appropriate Web Development courses to help your child build and maintain their websites through a structured approach. Kids can show case their creativity to their friends, teachers and others.
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={3}
              headerTitle="Q:  Is this course suitable for beginners?"
            >
              <strong>A: </strong>
              Yes, this course is designed to accommodate beginners. It starts with fundamental concepts and gradually progresses to more advanced topics.
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={3}
              headerTitle="Q: What tools or software do I need to follow along?"
            >
              <strong>A: </strong>
              
              You'll need Android Studio, which is the official IDE for Android app development, and a computer running Windows, macOS, or Linux.
            </MDBAccordionItem>


           
          </MDBAccordion>
        </MDBContainer>
      </div>
      
{/* Footer */}
<Footer/>

    </div>
  )
}

export default WebDev
