import React,{useContext} from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from '../Footer'
import "./CodingCourseDetails.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../MathPortal/Slider.css";
import "../MathPortal/MathCourseDetails.css";
import "../MathPortal/MathPortalResponsive.css";
import "./Android.css";
import "./ScratchProgramming.css";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import pic1 from "./CodingCourseImages/webdev.png";
import pic2 from "./CodingCourseImages/ScrollingTextApp.png";
import pic3 from "./CodingCourseImages/counter.jpg";
import pic4 from "./CodingCourseImages/FoodOrdering.jpg";
import pic5 from "./CodingCourseImages/AlarmApp.jpg";
import pic6 from "./CodingCourseImages/Dictionaryapp.jpg";
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import FeeStructure from '../components/FeeStructure';
import PromptE from './CodingCourseImages/promptb.jpg'

const WebDev = ({selectedCourse}) => {
  const handleCourseSelect = (course) => {
    console.log("Selected Course in Home:", course);
    selectedCourse(course);
  };

 const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };
  
  
 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
      navigate("/");

    }
      
      const username = userInfo?.username;
      const _avatar_ = userInfo?.avatar;
   
  return (
   
    <div >
      
     {/* Navbar */}
     {username && (
        <>
        {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
        {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
        {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
      </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}
 
      <div className="Webdev-Background">
        <section className="section">
          <p className="PageHead"style={{ color: "#8b0194" }}>AI - Prompt Engineering</p>
          <div className="PageDesc">
          <div style={{display:"flex"}}>
              <p style={{
                fontFamily: "KG Primary Whimsy,VanillaMilk,'DM Sans', sans-serif",
                color: "white",
                textAlign: "left",
                padding: "30px",
                fontSize: "22px",
                zIndex: 1,
                color: "#8b0194",
                width:"70%"
                
              }}>
            Polymath-kids offer comprehensive online AI - Prompt Engineering  course. The online classes’ offers two modes -online 1:1 classes and online group classes with trained teachers having years of experience in Computer Sciences.  
            </p>
            <img style={{minWidth:"250px", maxWidth:"450px"}} src={PromptE}></img>
            </div>
            <div className="btn-row d-flex btn-row-resp btn-row-resp1">
              {/* <div className="btn-row-resp"> */}
              <div style={{ width: "auto", height: "50px" }}>
                <a
                  className="btn-row-resp button btn-color-course"
                  href="#course"
                >
                  Course Structure
                </a>
              </div>

              <div style={{ width: "auto", height: "50px" }}>
                <a className=" btn-row-resp button btn-color-fee" href="#fee">
                  Fee Structure
                </a>
              </div>
              <div style={{ width: "auto", height: "50px" }}>
                <a
                  className=" btn-row-resp button btn-color-Testimonal"
                  href="#testimonal"
                >
                  Testimonals
                </a>
              </div>
              <div style={{ width: "auto", height: "50px" }}>
                <a className=" btn-row-resp button btn-color-Faq" href="#Faq">
                  Faqs
                </a>
              </div>
              <div style={{ width: "auto", height: "50px" }}>
                <a
                  className=" btn-row-resp button btn-color-registered"
                  href="#"
                >
                  Get Registered
                </a>
              </div>
            </div>
          </div>
        </section>
        <div className="overlay"></div>
      </div>

      {/* Intro text */}
      <div className="scratch-background">
        <section className="section">
          <p className="PageHead" style={{ color: "#8b0194" }}>
          What is AI - Prompt Engineering?
          </p>
          <div className="PageDesc">
            <p
              style={{
                color: "#8b0194",
                marginTop: "2vh",
                fontSize: "4vh",
                fontFamily: "sans serif",
              }}
            >
            Prompt engineering is the process of crafting clear, specific, and effective instructions (called prompts) to guide AI systems, like chatbots or image generators, in performing tasks or generating outputs. It involves carefully designing the words or phrases you use to communicate with AI, ensuring that the machine understands what you want and responds in a useful or creative way.

Key Aspects of Prompt Engineering:
Clarity: A good prompt should be easy to understand, leaving little room for confusion.

Example: Instead of saying "Tell me something," you might say, "Tell me a story about a dog who saves the day."
Specificity: The more details you provide in a prompt, the better the AI can meet your expectations.

Example: "Draw a tree" is less specific than "Draw a large oak tree with red apples hanging from its branches."
Creativity: In some cases, you want to inspire AI to generate creative responses.

Example: "Imagine a city in the clouds and describe what life is like there."
Problem Solving: Prompt engineering can be used to ask AI for solutions or suggestions for tasks.

Example: "Give me 5 tips for organizing a birthday party."
Why It’s Important:
AI systems rely on human input to perform tasks. How you phrase your prompt determines the quality and relevance of the response. Well-designed prompts can help users get better results, whether they’re asking for information, generating art, writing stories, or solving problems.

In summary, prompt engineering is about guiding AI to give you the best possible outcome by asking the right questions or giving clear instructions.  </p>
          </div>
        </section>
      </div>

      {/* Course Structure and Syllabus */}
      <section className="section course-structure" id="course">
        <p className="PageHead" style={{ color: "#8b0194" }}>
          Course Structure and Syllabus
        </p>
        <h4 className="H4_Heading" style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
          <b>Course Objectives</b>
        </h4>
        <ul style={{ color: "#8b0194", fontFamily: "'Comic Sans MS', cursive, sans-serif", listStyleType: "disc", marginLeft: "20px" }}>
    <li>Learn the expertise of AI Prompt Engineering:</li>
    <ul style={{ listStyleType: "circle", marginLeft: "40px" }}>
      <li>Understand the fundamentals of Prompt Engineering and its applications.</li>
      <li>Design and optimize effective text prompts for AI systems.</li>
      <li>Communicate efficiently with AI models to achieve desired outcomes.</li>
      <li>Apply prompt engineering techniques to various domains (education, creativity, etc.).</li>
      <li>Evaluate and refine prompts to improve AI response accuracy.</li>
      <li>Recognize and promote fairness, kindness, and respect in AI interactions.</li>
      <li>Develop expertise in leveraging AI tools for creative projects, homework assistance, and academic success.</li>
    </ul>
    </ul>
    {/*
    <ul style={{ color: "#8b0194", fontFamily: "'Comic Sans MS', cursive, sans-serif", listStyleType: "disc", marginLeft: "20px" }}>
    <li>Develop 21st Century Skills:</li>
    <ul style={{ listStyleType: "circle", marginLeft: "40px" }}>
      <li>Logic application and self-expression</li>
      <li>Creativity and Problem-Solving Skills</li>
      <li>Mathematical, Computational and Analytical Thinking</li>
      <li>Persistence</li>
      <li>Creativity and Imagination</li>
      <li>Digital Literacy</li>
      <li>Career-Building Skills</li>
      <li>Confidence and Communication Skills</li>
      <li>STEM Education</li>
    </ul>
  </ul> */}


        <div>
          <p className='moduleSection' >
            <img src={pic1} alt="" style={{ width: "2em", height: "2em" }} />
            AI - Prompt Engineering
          </p>
        </div>

        {/* Module one */}
        <div className="modules">
          {/* round box */}
          <div className="round-box">
            <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
              {" "}
              Module
              <br />
              1
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box" style={{ color: "#249935" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>16</p>
            </p>

            <div
              className="small-box sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>8</p>
              </p>
            </div>
          </div>

          <div className="large-box" style={{ color: "#249935" }}>
            <p style={{ margin: "auto" }}>
            Introduction to Prompt Engineering
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >
                This module introduces students to the world of Prompt Engineering, where they learn how to communicate effectively with AI systems. It covers the basics of how AI and robots interpret human language, tips for creating clear prompts, and strategies for improving answers by fixing vague or unclear instructions. By the end of the module, students will begin their journey toward becoming prompt experts.            </p>
            </p>
          </div>
        </div>

        {/* Module  two */}
        <div className="modules" style={{ marginTop: "2vh" }}>
          {/* round box */}
          <div className="round-box">
            <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
              {" "}
              Module
              <br />
              2
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box" style={{ color: "#249935" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>12</p>
            </p>

            <div
              className="small-box sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>8</p>
              </p>
            </div>
          </div>

          <div className="large-box" style={{ color: "#249935" }}>
            <p className="my-4" style={{ margin: "auto" }}>
            AAdvanced Prompt Engineering
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >
                Students will dive deeper into the fascinating world of *Prompt Engineering*, learning how AI and robots interpret human language. Through lessons like "Making Your Prompts Super Clear" & "Fixing Prompts for Better Answers," students will enhance their skills in crafting precise prompts. With examples from famous AI applications, this module guides students toward becoming prompt experts, ready to use AI responsibly & effectively.              </p>
            </p>
          </div>
        </div>
        

        <h6 style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
          <b>Learn AI - Prompt Engineering with 40+ Fun Activities</b>
        </h6>

        <div className="activityImagesContainer">
          <div class="row">
            <div class="col-lg mx-3">
              <img src={pic2} alt="" className='ActivityImages'/>
              <p className="mx-3">Scrolling Text App</p>
            </div>

            <div class="col-lg mx-3">
              <img src={pic3} alt="" className='ActivityImages' />
              <p className="mx-3">Counter App</p>
            </div>

            <div class="col-lg mx-3">
              <img src={pic4} alt="" className='ActivityImages'/>
              <p className="mx-3">Food Ordering App</p>
            </div>

            <div class="col-lg mx-3">
              <img src={pic5} alt="" className='ActivityImages'/>
              <p className="mx-3">Alarm Manager</p>
            </div>

            <div class="col-lg mx-3">
              <img src={pic6} alt="" className='ActivityImages'/>
              <p className="mx-3">Dictionary App</p>
            </div>

          </div>
        </div>
      </section>

      {/* Fee Structure */}
      <FeeStructure subject="Coding"/>

      {/* Testimonals */}
      <div class="  background" id="testimonal">
        <div class="row" style={{ width: "100%" }}>
          <div class="col-lg-8 mx-auto">
            <h2 className="Testimonal_Heading">Testimonials</h2>
            <div id="myCarousel" class="carousel slide" data-ride="carousel">
              {/* <!-- Carousel indicators --> */}
              <ol class="carousel-indicators">
                <li
                  data-target="#myCarousel"
                  data-slide-to="0"
                  class="active"
                ></li>
                <li data-target="#myCarousel" data-slide-to="1"></li>
              </ol>
              {/* <!-- Wrapper for carousel items --> */}
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="img-box">
                    <div className=" img-box1"></div>
                  </div>
                  <p
                    class="testimonial"
                    style={{ color: "black", fontSize: "3vh" }}
                  >
                    I enjoyed the learning experience with Polymath-Kids-Abacus.
                    The teacher was very friendly. I highly recommend
                    Polymath-Kids-Abacus.
                  </p>
                  <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                    <b>Mujtaba Khawaja Sahaf</b>
                  </p>
                </div>

                <div class="carousel-item">
                  <div class="img-box">
                    <div className="img-box2 "></div>
                  </div>
                  <p
                    class="testimonial"
                    style={{ color: "black", fontSize: "3vh" }}
                  >
                    I feel so proud when my class fellows call me ’BRAINIAC ’,
                    because I can effortlessly do mental calculations much
                    faster and accurate than my class mates. Thank you
                    Polymath-Kids.
                  </p>
                  <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                    <b>Wahaab Omer</b>, Age 9 Years
                  </p>
                </div>
              </div>
              {/* <!-- Carousel controls --> */}
              <a
                class="carousel-control-prev"
                href="#myCarousel"
                data-slide="prev"
              >
                <i class="fa fa-angle-left"></i>
              </a>
              <a
                class="carousel-control-next"
                href="#myCarousel"
                data-slide="next"
              >
                <i class="fa fa-angle-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Faqs */}
<div style={{ maxWidth: "100%", backgroundColor: "#edffff" }} id="Faq">
  <MDBContainer className="mt-0" style={{ maxWidth: "180vh" }}>
    <MDBAccordion alwaysOpen initialActive={1}>
      <div className="text-center PageHead" style={{ color: "#8b0194" }}>
        {" "} FAQs
      </div>
      <MDBAccordionItem
        collapseId={1}
        headerTitle="Q: What is this course about?"
      >
        <strong>A: </strong>
        This course introduces the concept of Prompt Engineering, focusing on how to create clear, effective prompts for AI. It covers how AI understands human input, how to refine prompts for better responses, and explores fairness and ethics in AI interactions.
      </MDBAccordionItem>

      <MDBAccordionItem
        collapseId={2}
        headerTitle="Q: Who is this course for?"
      >
        <strong>A: </strong>
        The course is designed for students aged 11 to 18 who are interested in learning how to communicate effectively with AI systems, regardless of prior experience with AI or coding.
      </MDBAccordionItem>

      <MDBAccordionItem
        collapseId={3}
        headerTitle="Q: Do I need any prior knowledge of AI to take this course?"
      >
        <strong>A: </strong>
        No prior knowledge is required. This course provides a foundation in Prompt Engineering and introduces key concepts about how AI works.
      </MDBAccordionItem>

      <MDBAccordionItem
        collapseId={3}
        headerTitle="Q: What kind of tools will I use?"
      >
        <strong>A: </strong>
        You will explore various fun AI tools that demonstrate how prompts work in real-world applications, helping you practice and enhance your skills.
      </MDBAccordionItem>

      <MDBAccordionItem
        collapseId={3}
        headerTitle="Q: How long is this course?"
      >
        <strong>A: </strong>
        The course is structured to be completed over several weeks (14 lessons), with each lesson providing engaging content and hands-on activities.
      </MDBAccordionItem>

      <MDBAccordionItem
        collapseId={3}
        headerTitle="Q: Will there be any assessments?"
      >
        <strong>A: </strong>
        Yes, there will be interactive tasks and activities to test your understanding, but the focus is on learning through practice rather than formal exams.
      </MDBAccordionItem>

      <MDBAccordionItem
        collapseId={3}
        headerTitle="Q: How can this course help me in the future?"
      >
        <strong>A: </strong>
        Mastering prompt engineering is a valuable skill for anyone interested in AI, programming, or even creative fields like writing or game design, where clear communication with AI systems is becoming more important.
      </MDBAccordionItem>

      <MDBAccordionItem
        collapseId={3}
        headerTitle="Q: What happens after I complete this course?"
      >
        <strong>A: </strong>
        Upon completing this course, you'll be equipped with advanced skills in Prompt Engineering and be prepared to explore more advanced AI topics in future modules. You will be equipped to make use of AI in your daily routine including studies, assessments, assignments, research etc.
      </MDBAccordionItem>
    </MDBAccordion>
  </MDBContainer>
</div>

{/* Footer */}
<Footer/>

    </div>
  )
}

export default WebDev
