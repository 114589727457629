import React, { useState, useContext, useEffect } from "react";
import StudentNav from "../Navbars/StudentNav";
import AdminNav from "../Navbars/AdminNav";
import TeacherNav from "../Navbars/TeacherNav";
import GuestNav from "../Navbars/GuestNav";
import { UserContext } from "../UserContext";
import Footer from "../Footer";
import { AiOutlineCloudUpload, AiOutlineCheckCircle, AiOutlineFileDone } from "react-icons/ai";
import "./ActivitiesAndProjects.css";
import { Link } from "react-router-dom";
import { useLocation, useParams } from 'react-router-dom/dist/umd/react-router-dom.development';

import puzzleicon from "./ActivityIcons/AP_ModuleProjectLesson.png";
import videoicon from "./ActivityIcons/AP_VideoIcon.png";
import brainicon from "./ActivityIcons/AP_Brainicon.png";
import SpeedQuiz from "./ActivityIcons/Speedicon.png";
import oralicon from "./ActivityIcons/AP_Oralicon.png";
import writtenicon from "./ActivityIcons/AP_writtenicon.png";
//import axiosInstance from "../ManageContent/managecontentforms/axiosInstance";
//import { useNavigate } from "react-router-dom";
import ModuleProjects from "./ModuleProjects";
import LessonProjects from "./LessonProjects";
//import { toast } from "react-toastify";

const ActivityList = ({
  title,
  projects,
  backgroundColor,
  textColor,
  iconBackground,
  image,
  courseID,
  activityID,
  uploadApiRoute,
  studentId,
  setRefresh=null

}) => {
  const shouldDisplayTime =
    title === "Mental Activities" ||
    title === "Oral Activities" ||
    title === "Written Activities"||
    title === "Speed Quiz Activities";

  return (
    <div className="AP_ModuleProjectLesson" style={{ backgroundColor }}>
      <h2 className="AP_title" style={{ color: textColor }}>
        <img src={image} alt="" style={{ width: "7vh", height: "7vh" }} />{" "}
        {title}
      </h2>
      <ul style={{ marginLeft: "0", paddingLeft: "0" }}>
        {projects.map((project, index) => (
          <li key={index} className="AP_Projects">
            <div className="AP_ProjectItem">
              <div style={{ maxWidth: "100%", marginBottom: "5px" }}>
              <span
                style={{
                  color: textColor,
                  backgroundColor: project?.activity?.Complete ? "lightgreen" : "lightgrey",
                  borderRadius: "7vh",
                  padding: "1px 8px",
                  marginRight: "5px"

                }}
              >
              </span>
                <Link
                  to=""
                  onClick={() =>
                    handleProjectClick(title, project.activity.Activity_id||project.activity.Lesson_Activity_id, project?.activity?.ActivityLink, project)
                  }
                  style={{ marginRight: "7%", color: textColor }}
                >
                  {project.activity.ActivityName}
                </Link>

              </div>
              
              {shouldDisplayTime ? (
                <div style={{display:"flex"}}>
                  
                  <span
                    style={{
                      color: textColor,
                      backgroundColor: iconBackground,
                      display:"flex",
                      flexDirection:"column",
                      width:"65px",
                       alignItems:"center"
                    }}
                    className="AP_Score"
                  >
                    {project.activity.MarksObtained}/
                    {project.activity.TotalMarks} 
                    <span style={{fontSize:"11px"}}>(
                    {project.activity.TotalMarks>0?((
                      (project.activity.MarksObtained /
                        project.activity.TotalMarks) *
                      100
                    ).toFixed(1)):"0"}
                    %)
                    </span>
                  </span>
                  <span
                    style={{
                      color: textColor,
                      backgroundColor: iconBackground,
                      display:"flex",
                      flexDirection:"column",
                      minWidth:"60px",
                      maxWidth:"70px",
                      alignItems:"center"
                    }}
                    className="AP_Time"
                  >
                    {(project.activity.TimeTaken / 60).toFixed(0)}/
                    {(project.activity.TotalTime / 60).toFixed(0)}
                    <span style={{fontSize:"11px"}}>
                     min
                    </span>
                  </span>
                </div>
              ) : (
                <>
                  {title!="Video Activities" && <label htmlFor={`Gfile-upload-${index}`}>
                    <span
                      style={{
                        color: textColor,
                        backgroundColor: iconBackground,
                      }}
                      className="AP_Icon"
                    >
                      <AiOutlineCloudUpload size={40} />
                    </span>
                    <input
                      id={`Gfile-upload-${index}`}
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) =>
                        handleFileSelect(e, project.activity.Lesson_Activity_id)}
                    />
                  </label>}

                </>
              )}
            </div>
            <hr />
          </li>
        ))}
      </ul>
    </div>
  );
};

const ActivitiesAndProjects = () => {
  const location = useLocation();

  const [ActivityId, setActivityId] = useState();
  const [CourseID, setCourseID] = useState();
  
  return (
    <>
      {username && (
        <>
          {isAdmin && (
            <AdminNav
              username={username}
              avatar={_avatar_}
              onLogout={onLogout}
            />
          )}
          {isTeacher && (
            <TeacherNav
              onCourseSelect={handleCourseSelect}
              username={username}
              avatar={_avatar_}
              onLogout={onLogout}
            />
          )}
          {isStudent && (
            <StudentNav
              username={username}
              avatar={_avatar_}
              onLogout={onLogout}
            />
          )}
        </>
      )}
      {!username && <>{isGuest && <GuestNav onLogin={onLogin} />}</>}
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        
        <div className="AP_container">
          <div style={{justifyContent:"left", width:"100%"}}>
            <div style={{fontSize:"16px",fontWeight:"bold",display:"flex",justifyContent:"start",alignItems:"center",height:"auto",color:"rgb(172, 77, 203)", width:"400px",padding:"30px 0 30px 30px"}}>
              <div style={{marginRight:"10px"}}>
                <img src={userInfo?.avatar} alt="Avatar" style={{ width: "50px", height: "50px", borderRadius: "50%" }} />
              </div>
              <div>{`${userInfo?.firstname} ${userInfo?.lastname} you are on  ${profileData?.coursename}  > ${profileData?.modulename} > ${profileData?.lessonname}`}
              </div>
              
            </div>
          </div>
          {Array.isArray(projects) && projects.length > 0 && (
            <ModuleProjects
              title="Module Project"
              projects={projects}
              textColor="#5665e9"
              backgroundColor="#dae8ff"
              iconBackground="#aec7f0"
              image={puzzleicon}
              activityID={ActivityId}
              courseID={CourseID}
              uploadApiRoute="/api/activitiesandprojects/uploadStudentModuleProject"
              studentid={studentid}
            />
          )}
          {Array.isArray(projects2) && projects2.length > 0 && (
            <LessonProjects
              title="Lesson Project"
              projects={projects2}
              textColor="#5665e9"
              backgroundColor="#dae8ff"
              iconBackground="#aec7f0"
              image={puzzleicon}
              activityID={ActivityId}
              courseID={CourseID}
              uploadApiRoute="/api/activitiesandprojects/uploadStudentLessonProject"
              studentid={studentid}
            />
          )}
        </div>

        <div className="AP_container">
          {GameActivities.length > 0 && (
            <ActivityList
              title="Game Activities"
              projects={GameActivities}
              textColor="#d9ce23"
              backgroundColor="#f7fbdb"
              iconBackground="#f2fcae"
              image={puzzleicon}
              activityID={ActivityId}
              courseID={CourseID}
              uploadApiRoute="/api/activitiesandprojects/uploadStudentGameActivity"
              studentId={studentid}
              setRefresh={setRefresh}
              
            />
          )}
        </div>

        <div className="AP_container">
          {MentalActivities.length > 0 && (
            <ActivityList
              title="Mental Activities"
              projects={MentalActivities}
              textColor="#da831e"
              backgroundColor="#faf0d6"
              iconBackground="#fae7b4"
              image={brainicon}
              activityID={ActivityId}
              courseID={CourseID}
              studentId={studentid}
            />
          )}
          {OralActivities.length > 0 && (
            <ActivityList
              title="Oral Activities"
              projects={OralActivities}
              textColor="#d83162"
              backgroundColor="#feecf2"
              iconBackground="#fccada"
              image={oralicon}
              activityID={ActivityId}
              courseID={CourseID}
              studentId={studentid}
            />
          )}
        </div>
        <div className="AP_container">
          {WrittenActivities.length > 0 && (
            <ActivityList
              title="Written Activities"
              projects={WrittenActivities}
              textColor="#ac4dcb"
              backgroundColor="#e4ddfb"
              iconBackground="#d0c2fc"
              image={writtenicon}
              activityID={ActivityId}
              courseID={CourseID}
              studentId={studentid}
            />
          )}
        </div>
        <div className="AP_container">
          {SpeedQuizActivities.length > 0 && (
            <ActivityList
              title="Speed Quiz Activities"
              projects={SpeedQuizActivities}
              textColor="rgb(50, 192, 133)"
              backgroundColor="rgb(200, 248, 228)"
              iconBackground="rgb(200, 248, 228)"
              image={SpeedQuiz}
              activityID={ActivityId}
              courseID={CourseID}
              studentId={studentid}
            />
          )}
        </div>
        <div className="AP_container">
          {MCQActivities.length > 0 && (
            <ActivityList
              title="MCQ Activities"
              projects={MCQActivities}
              textColor="#ac4dcb"
              backgroundColor="#e4ddfb"
              iconBackground="#d0c2fc"
              image={brainicon}
              activityID={ActivityId}
              courseID={CourseID}
              studentId={studentid}
            />
          )}
        </div>
        <div className="AP_container">
          {VideoActivities.length > 0 && (
            <ActivityList
              title="Video Activities"
              projects={VideoActivities}
              textColor="rgb(52, 151, 156)"
              backgroundColor="rgb(205, 243, 245)"
              iconBackground="rgb(205, 243, 245)"
              image={videoicon}
              activityID={ActivityId}
              courseID={CourseID}
              studentId={studentid}
              setRefresh={setRefresh}
            />
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ActivitiesAndProjects;
