import React,{useContext} from 'react'
import './SpeedQuizActivity.css'


import StudentNav from '../../Navbars/StudentNav'
import AdminNav from '../../Navbars/AdminNav'
import TeacherNav from '../../Navbars/TeacherNav';
import GuestNav from '../../Navbars/GuestNav';
import { UserContext } from '../../UserContext';
import Footer from '../../Footer'

import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import { useLocation } from "react-router-dom";

import { fetchActivitiesImage } from '../fetchActivitiesImage';

import MCQActivityImage from './mental-activity.png'
import GoodImage from './good.png'
import CrossImage from './cross.png'
import FinishedImage from './finished.jpeg'
import { useRef, useState, useEffect } from 'react'
import axios from 'axios';
// import AdminNav from '../../Navbars/AdminNav'
// import Footer from '../../Footer'

const SpeedQuizActivity = () => {


    const selectedCourse = ""
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const activityId = searchParams.get("activityId");


    const [actualQuestions, setActualQuestions] = useState([]);
    const [currQuestion, setCurrQuestion] = useState([]);

    const [imageData, setImageData] = useState(null);

    const [count, setCount] = useState();
    const countRef = useRef();

    const [hours, setHours] = useState(0);
    const hoursRef = useRef();

    const [minutes, setMinutes] = useState();
    const minutesRef = useRef();
    const ProgressRef = useRef(null);

    const progressIncrement = useRef();

    useEffect(() => {
        // Define an async function to fetch the questions from the API
        async function fetchQuestions() {
          try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getActivityQuestions/${activityId}`);
            setActualQuestions(response.data);
            console.log("getActivityQuestions",response);
            setCurrQuestion(response.data[0]);
            if(response.data[0]?.ActivityType=="Video"){
                FinishedImageRef.current.style.display = "inline-block";
            }
            setMarksPerQuestion(response.data[0]?.marks);

            console.log("..........Calling Fetch Image 01..........")
            console.log("Question Picture:",response.data[0]?.questionpicture)
            fetchActivitiesImage(setImageData,response.data[0]?.questionpicture);

            const timeResponse = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getActivityTime/${activityId}`);

            console.log("Time Response: ", timeResponse);


            var calculatedHours = Math.floor(timeResponse.data[0].TotalTime / 60); // Get the whole number of hours
            var calculatedMinutes = timeResponse.data[0].TotalTime % 60;

            if(calculatedMinutes === 0 && calculatedHours > 0){
                calculatedHours = calculatedHours - 1;
                calculatedMinutes = 60;
            }


            setCount(60);
            countRef.current = 60;
            setMinutes(calculatedMinutes - 1);
            minutesRef.current = calculatedMinutes - 1;
            setHours(calculatedHours);
            hoursRef.current = calculatedHours;


            var total_marks = 0;
            var total_questions = 0;
            response.data.forEach(data => {
                total_marks += data?.marks;
                total_questions++;
            });

            setTotalMarks(total_marks);
            setTotalQuestions(total_questions);

            progressIncrement.current = (100/(total_questions))*1.3;

          } catch (error) {
            console.error(error);
          }

          try {
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/saveActivity`, {
              Activity_id: activityId,
              Student_id: student_ID,
              MarksObtained: gainedMarksRef.current,
              MarksObtainedPercentage: gainedMarksRef.current / totalMarks * 100,
              Remarks: (gainedMarksRef.current / totalMarks * 100) > 90 ? 'Excellent Work' : (gainedMarksRef.current / totalMarks * 100) > 70 ? "Good Job" : "Try Again",
              SubmitActivityFile: '',
              TimeTaken: totalTimeTakenOfAllQuestions.current,
              TotalMarks: totalMarks,
              TotalTime: totalTimeOfAllQuestions.current < 0 ? 0 : totalTimeOfAllQuestions.current,
              Complete: 0,//completeActivityRef.current,
            });
            console.log(response.data);
          } catch (error) {
            console.error(error);
          }
          try {
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/deleteActivityQuestions`, {
              Lesson_Activity_id: activityId,
              Student_id: student_ID,
    
            });
            console.log(response.data);
          } catch (error) {
            console.error(error);
          }
        }
    
        // Call the async function to fetch the questions
        fetchQuestions();
      }, [activityId]);


      const submitButton = useRef(null);
      const GoodImageRef = useRef(null);
      const CrossImageRef = useRef(null);
      const FinishedImageRef = useRef(null);

      const optionRef1 = useRef(null)
      const optionRef2 = useRef(null)
      const optionRef3 = useRef(null)
      const optionRef4 = useRef(null)
    

    const [items, setItems] = useState([]);
    // const [currentQuestion, setCurrentQuestion] = useState(questions[0]);
    const [gainedMarks, setGainedMarks] = useState(0);
    const [questionNumber, setQuestionNumber] = useState(1);
    const [studentAnswer, setStudentAnswer] = useState("");
    const [totalQuestions, setTotalQuestions] = useState();
    const [marksPerQuestion, setMarksPerQuestion] = useState();
    const [totalMarks, setTotalMarks] = useState();
    
    const questionNumberRef = useRef(0);

    const [student_ID, setStudent_ID] = useState("4");
    const studentCurrAnswerRef = useRef(0);
    const marksObtainedCurrQuestionRef = useRef(0);
    const isCorrectRef = useRef(0);

    const gainedMarksRef = useRef(0);

    const progressCount = useRef(0);
    // const progressIncrement = useRef((100/(totalQuestionsRef.current))*1.3);

    console.log("progress Increment is: ", progressIncrement.current);


    function stopIntervalID(){
        setCount(0);
        countRef.current = 0;
        setMinutes(0);
        minutesRef.current = 0;
        setHours(0);
        hoursRef.current = 0;

        clearInterval(intervalId);


        studentCurrAnswerRef.current = "";
        marksObtainedCurrQuestionRef.current = marksPerQuestion;
        isCorrectRef.current = 0;
        submitStudentQuestionResponse();

        setItems([...items, 0]);
        clearInterval(intervalId);

        GoodImageRef.current.style.display = 'none';
        CrossImageRef.current.style.display = "inline-block";
        
        submitButton.current.style.display = "none";
        FinishedImageRef.current.style.display = "inline-block";
    }

    var intervalId;
    useEffect(() => {
            intervalId = setInterval(() => {
          countRef.current = countRef.current - 1;
          if (countRef.current === -1) {
            
            // studentCurrAnswerRef.current = "";
            // marksObtainedCurrQuestionRef.current = marksPerQuestion;
            // isCorrectRef.current = 0;

            // submitStudentQuestionResponse();

            setMinutes(minutes => minutes - 1);
            minutesRef.current -= 1;

            if(minutesRef.current === -1 && hoursRef.current === 0){
                stopIntervalID();
            }

            console.log("Minutes ")

            if(minutesRef.current > 0){
                setCount(59);
                countRef.current = 59;
            }

            // if(minutesRef.current === 0 && hoursRef.current === 0){
            //     setCount(59);
            //     countRef.current = 59;
            // }

            if(minutesRef.current < 0 && hoursRef.current > 0){
                setHours(hours => hours - 1);
                hoursRef.current -= 1;

                setMinutes(59);
                minutesRef.current = 59;

                setCount(59);
                countRef.current = 59;
            }

            

            // questionNumberRef.current = questionNumberRef.current + 1;
            // setQuestionNumber(questionNumber => questionNumber + 1);
            // setCurrQuestion(actualQuestions[questionNumberRef.current]);
            // setMarksPerQuestion(actualQuestions[questionNumberRef.current].marks);
            // setCount(actualQuestions[questionNumberRef.current].timealloted);
            // setItems([...items, 0]);
            // countRef.current = actualQuestions[questionNumberRef.current].timealloted;
            // GoodImageRef.current.style.display = 'none';
            // CrossImageRef.current.style.display = "inline-block";
          } else {
            setCount(countRef.current);
          }
        }, 1000);
    
        // Return a cleanup function to clear the interval
        return () => clearInterval(intervalId);
      }, [questionNumber, count]);
    
      useEffect(() => {
        if (questionNumber == totalQuestions && count == 0 && minutes == 0 && hours == 0) {

            studentCurrAnswerRef.current = "";
            marksObtainedCurrQuestionRef.current = marksPerQuestion;
            isCorrectRef.current = 0;
            submitStudentQuestionResponse();

            setItems([...items, 0]);
            clearInterval(intervalId);

            GoodImageRef.current.style.display = 'none';
            CrossImageRef.current.style.display = "inline-block";
          
            submitButton.current.style.display = "none";
            FinishedImageRef.current.style.display = "inline-block";
        }
      }, [questionNumber, count]);

      const handleStudentAnswer = (event) =>{
        setStudentAnswer(event.target.value);
      }


      const totalTimeTakenOfAllQuestions = useRef(0);
      const totalTimeOfAllQuestions = useRef(0);

      const submitStudentQuestionResponse = async () => {
        // event.preventDefault();

        totalTimeTakenOfAllQuestions.current += currQuestion?.timealloted - countRef.current - 1;
        totalTimeOfAllQuestions.current += currQuestion?.timealloted;

        try {
            // console.log("I am in submitting response...")
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/saveActivityQuestion`, {
                Question_id: currQuestion?.Question_id,
                Student_id: student_ID,
                timetaken: currQuestion?.timealloted - countRef.current - 1,
                StudentAnswer: studentCurrAnswerRef.current,
                MarksObtained: marksObtainedCurrQuestionRef.current,
                TotalTime: currQuestion?.timealloted,
                correct: isCorrectRef.current
            });
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }

        try {
            console.log("I am in submitting  response...")
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/saveActivity`, {
              Activity_id: activityId,
              Student_id: student_ID,
              MarksObtained: gainedMarksRef.current,
              MarksObtainedPercentage: gainedMarksRef.current / totalMarks * 100,
              Remarks: (gainedMarksRef.current / totalMarks * 100) > 90 ? 'Excellent Work' : (gainedMarksRef.current / totalMarks * 100) > 70 ? "Good Job" : "Try Again",
              SubmitActivityFile: '',
              TimeTaken: totalTimeTakenOfAllQuestions.current,
              TotalMarks: totalMarks,
              TotalTime: totalTimeOfAllQuestions.current < 0 ? 0 : totalTimeOfAllQuestions.current,
              Complete: 0,//completeActivityRef.current,
            });
            console.log(response.data);
          } catch (error) {
            console.error(error);
          }
    };




    const completeActivityRef = useRef();

    const submitCompleteActivityResponse = async () => {
        // e.preventDefault();
    
        try {
            console.log("I am in submitting Complete response...")
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/saveActivity`, {
                Activity_id: activityId,
                Student_id: student_ID,
                MarksObtained: gainedMarksRef.current,
                MarksObtainedPercentage: gainedMarksRef.current / totalMarks * 100,
                Remarks: 'Good Efforts',
                SubmitActivityFile: '',
                TimeTaken: totalTimeTakenOfAllQuestions.current,
                TotalMarks: totalMarks,
                TotalTime: totalTimeOfAllQuestions.current,
                Complete: completeActivityRef.current,
            });
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
      };




      const handleSubmit = (event) => {
        event.preventDefault();
        console.log(studentAnswer);

        console.log("I am in handle submit...")

        if(questionNumber === totalQuestions){

            if(optionRef1.current.checked && currQuestion?.option1 == currQuestion?.correctanswer){
                setItems([...items, 1]);
                gainedMarksRef.current = gainedMarksRef.current + marksPerQuestion;
                setGainedMarks(gainedMarks => gainedMarks + marksPerQuestion);
                CrossImageRef.current.style.display = 'none';
                GoodImageRef.current.style.display = "inline-block";

                studentCurrAnswerRef.current = currQuestion?.option1;
                marksObtainedCurrQuestionRef.current = marksPerQuestion;
                isCorrectRef.current = 1;


                submitStudentQuestionResponse();


            }
            else if(optionRef2.current.checked && currQuestion?.option2 == currQuestion?.correctanswer){
                setItems([...items, 1]);
                gainedMarksRef.current = gainedMarksRef.current + marksPerQuestion;
                setGainedMarks(gainedMarks => gainedMarks + marksPerQuestion);
                CrossImageRef.current.style.display = 'none';
                GoodImageRef.current.style.display = "inline-block";

                studentCurrAnswerRef.current = currQuestion?.option2;
                marksObtainedCurrQuestionRef.current = marksPerQuestion;
                isCorrectRef.current = 1;

                submitStudentQuestionResponse();

            }
            else if(optionRef3.current.checked && currQuestion?.option3 == currQuestion?.correctanswer){
                setItems([...items, 1]);
                gainedMarksRef.current = gainedMarksRef.current + marksPerQuestion;
                setGainedMarks(gainedMarks => gainedMarks + marksPerQuestion);
                CrossImageRef.current.style.display = 'none';
                GoodImageRef.current.style.display = "inline-block";

                studentCurrAnswerRef.current = currQuestion?.option3;
                marksObtainedCurrQuestionRef.current = marksPerQuestion;
                isCorrectRef.current = 1;

                submitStudentQuestionResponse();
            }
            else if(optionRef4.current.checked && currQuestion?.option4 == currQuestion?.correctanswer){
                setItems([...items, 1]);
                gainedMarksRef.current = gainedMarksRef.current + marksPerQuestion;
                setGainedMarks(gainedMarks => gainedMarks + marksPerQuestion);
                CrossImageRef.current.style.display = 'none';
                GoodImageRef.current.style.display = "inline-block";

                studentCurrAnswerRef.current = currQuestion?.option4;
                marksObtainedCurrQuestionRef.current = marksPerQuestion;
                isCorrectRef.current = 1;

                submitStudentQuestionResponse();
            }
            else{
                setItems([...items, 0]);
                GoodImageRef.current.style.display = 'none';
                CrossImageRef.current.style.display = "inline-block";

                if(optionRef1.current.checked){
                    studentCurrAnswerRef.current = currQuestion?.option1;
                }else if(optionRef2.current.checked){
                    studentCurrAnswerRef.current = currQuestion?.option2;
                }else if(optionRef3.current.checked){
                    studentCurrAnswerRef.current = currQuestion?.option3;
                }else if(optionRef4.current.checked){
                    studentCurrAnswerRef.current = currQuestion?.option4;
                }
                marksObtainedCurrQuestionRef.current = 0;
                isCorrectRef.current = 0;

                submitStudentQuestionResponse();
            }

            clearInterval(intervalId);
            submitButton.current.style.display = "none";
            FinishedImageRef.current.style.display = "inline-block";

            progressCount.current += progressIncrement.current;
            ProgressRef.current.style.width = `${progressCount.current}px`;

            completeActivityRef.current = 1;
            submitCompleteActivityResponse();

        }
        else{

            if(optionRef1.current.checked && currQuestion?.option1 == currQuestion?.correctanswer){
                setItems([...items, 1]);
                gainedMarksRef.current = gainedMarksRef.current + marksPerQuestion;
                setGainedMarks(gainedMarks => gainedMarks + marksPerQuestion);
                CrossImageRef.current.style.display = 'none';
                GoodImageRef.current.style.display = "inline-block";

                studentCurrAnswerRef.current = currQuestion?.option1;
                marksObtainedCurrQuestionRef.current = marksPerQuestion;
                isCorrectRef.current = 1;


                submitStudentQuestionResponse();
            }
            else if(optionRef2.current.checked && currQuestion?.option2 == currQuestion?.correctanswer){
                setItems([...items, 1]);
                gainedMarksRef.current = gainedMarksRef.current + marksPerQuestion;
                setGainedMarks(gainedMarks => gainedMarks + marksPerQuestion);
                CrossImageRef.current.style.display = 'none';
                GoodImageRef.current.style.display = "inline-block";

                studentCurrAnswerRef.current = currQuestion?.option2;
                marksObtainedCurrQuestionRef.current = marksPerQuestion;
                isCorrectRef.current = 1;


                submitStudentQuestionResponse();
            }
            else if(optionRef3.current.checked && currQuestion?.option3 == currQuestion?.correctanswer){
                setItems([...items, 1]);
                gainedMarksRef.current = gainedMarksRef.current + marksPerQuestion;
                setGainedMarks(gainedMarks => gainedMarks + marksPerQuestion);
                CrossImageRef.current.style.display = 'none';
                GoodImageRef.current.style.display = "inline-block";

                studentCurrAnswerRef.current = currQuestion?.option3;
                marksObtainedCurrQuestionRef.current = marksPerQuestion;
                isCorrectRef.current = 1;


                submitStudentQuestionResponse();

            }
            else if(optionRef4.current.checked && currQuestion?.option4 == currQuestion?.correctanswer){
                setItems([...items, 1]);
                gainedMarksRef.current = gainedMarksRef.current + marksPerQuestion;
                setGainedMarks(gainedMarks => gainedMarks + marksPerQuestion);
                CrossImageRef.current.style.display = 'none';
                GoodImageRef.current.style.display = "inline-block";
                


                studentCurrAnswerRef.current = currQuestion?.option4;
                marksObtainedCurrQuestionRef.current = marksPerQuestion;
                isCorrectRef.current = 1;


                submitStudentQuestionResponse();

            }
            else{
                setItems([...items, 0]);
                GoodImageRef.current.style.display = 'none';
                CrossImageRef.current.style.display = "inline-block";


                if(optionRef1.current.checked){
                    studentCurrAnswerRef.current = currQuestion?.option1;
                }else if(optionRef2.current.checked){
                    studentCurrAnswerRef.current = currQuestion?.option2;
                }else if(optionRef3.current.checked){
                    studentCurrAnswerRef.current = currQuestion?.option3;
                }else if(optionRef4.current.checked){
                    studentCurrAnswerRef.current = currQuestion?.option4;
                }

                marksObtainedCurrQuestionRef.current = 0;
                isCorrectRef.current = 0;
                
                submitStudentQuestionResponse();
                
            }
            questionNumberRef.current = questionNumberRef.current + 1;
            setQuestionNumber(questionNumber => questionNumber + 1);
            setCurrQuestion(actualQuestions[questionNumberRef.current])
            console.log("..........Calling Fetch Image..........")
            fetchActivitiesImage(setImageData,actualQuestions[questionNumberRef.current]?.questionpicture);
            setMarksPerQuestion(actualQuestions[questionNumberRef.current]?.marks);

            // setCount(actualQuestions[questionNumberRef.current].timealloted);
            // countRef.current = actualQuestions[questionNumberRef.current].timealloted;

            optionRef1.current.checked = false;
            optionRef2.current.checked = false;
            optionRef3.current.checked = false;
            optionRef4.current.checked = false;

            progressCount.current += progressIncrement.current;
            ProgressRef.current.style.width = `${progressCount.current}px`;

        }


      }
           


      const handleCourseSelect = (course) => {
        console.log("Selected Course in Home:", course);
        selectedCourse(course);
      };
    
     const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
     
        useEffect(() => {
          const fetchData = async () => {
           await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
              credentials: 'include',
            }).then(response => {
                console.log("Response:",response);
              response.json().then(userInfo => {
                console.log("userinfo:",userInfo);
                setUserInfo(userInfo);
    
                if(userInfo?.usertype)
                {
                  const uType=userInfo?.usertype;
                  setUserRole(uType);
                }
              const uname = userInfo?.username;
              const avatar = userInfo?.avatar; // Store the avatar in a separate variable
              console.log("Uname: ", uname);
              console.log("Avatar: ", avatar);
    
              })
              .catch(err=> console.log("Error in useeffect:",err))
            });
          }
          fetchData();
        }, [isAdmin, isTeacher, isStudent, isGuest]);
      const determineUserRole = (usertype) => {
            console.log("In home.js:",usertype)
            if(usertype === 'Student')
              return "Student";
            else if(usertype === 'Teacher')
              return "Teacher";
            else if(usertype === 'Admin')
              return "Admin";
            else
              return "guest";
      };
      
      
     const navigate=useNavigate();
      const onLogin = (usertype) => {
        const userRole = determineUserRole(usertype);
        console.log("Calling setUserRole.")
        setUserRole(userRole);
      };
    
      function onLogout() {
          console.log("inside Onlogout.");
          fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
            credentials: 'include',
            method: 'POST',
          });
          setUserInfo(null);
          setUserRole('guest');
          navigate("/");

        }
          
        const username = userInfo?.username;
        const _avatar_ = userInfo?.avatar;
        const usertype = userInfo?.usertype;

           
        function getEmbedUrl(youtubeUrl) {
            // Use a regular expression to capture the video ID
            const videoIdMatch = youtubeUrl.match(/(?:youtube\.com\/(?:watch\?v=|)([a-zA-Z0-9_-]+))/);
            
            // Check if a match was found
            if (videoIdMatch && videoIdMatch[1]) {
                const videoId = videoIdMatch[1];
                // Return the new embed URL format
                return `https://www.youtube.com/embed/${videoId}`;
            } else {
                return youtubeUrl;
            }
        }

        function formatTime(seconds) {
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = seconds % 60;
            return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
        }

        // Add this after activity completion
        if (window.opener) {
          window.opener.postMessage('activityCompleted', '*');
        }

  return (
    <div>



    {username && currQuestion&& (
        <>
             {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
            {isTeacher && <TeacherNav  onCourseSelect={handleCourseSelect}  username={username} avatar={_avatar_} onLogout={onLogout}/>}
            {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
         </>
         )}  
         {!username && (
           <>
           {isGuest && <GuestNav onLogin={onLogin} />}
  
           </>
         )}


        {/* <AdminNav/> */}
         <div className="MAMCQheading">
            <div className="MAMCQactivity-image">
                <img src={MCQActivityImage} alt=""/>
            </div>

            <div className="MAMCQactivity-text">
                {/* <h3>Scratch M4 L1 MCQ Activity 1</h3> */}
                <h3>{currQuestion?.ActivityName}</h3>
            </div>
        </div>
    
    <>
    <div className="MAMCQcontainer">
        <div className="MAMCQhead">

            <div className="MAMCQquestions">

                <div className="MAMCQquestions-numbers">
                    <p>{currQuestion?.ActivityType=="Video"?"Video":"Question"} <span id="MAcurrent-question">{questionNumber}</span> of <span id="MAtotal-questions">{totalQuestions}</span></p>
                </div>

                <div className="MAMCQprogress-bar">
                    <div id="MAprogress" ref={ProgressRef}></div>
                </div>
                

            </div>

            <div className="MAMCQscore">

                <div className="MAMCQscore-text">
                    <h4>Score</h4>
                </div>

                <div className="MAMCQnumbered-score">
                    <h2> <span id="MAcurrent-score">{gainedMarks}</span> / <span id="MAtotal-score">{totalMarks}</span></h2>
                </div>

            </div>

        </div>
        {currQuestion?.ActivityType=="Video"?
    // <video style={{height:"300px",width:"500px"}} src={currQuestion?.ActivityLink} />
    <>
        <div className="MAMCQbody">
            <iframe
            src={getEmbedUrl(currQuestion?.ActivityLink)}
            frameborder='0'
            allow='autoplay; encrypted-media'
            allowfullscreen
            title='video'
            width={"100%"}
            style={{minHeight:"450px", height:"auto"}}
            />
            
        </div>
        <div className="MAMCQfinished-picture">
            <img id="finishedId" src={FinishedImage} ref={FinishedImageRef} alt="" style={{ width: '100px', display: 'none'}}/>
        </div>
        </>
    :

        <div className="MAMCQbody">

            <div className="MAMCQremaining-body">

            <div class="MAMCQquestion">
                <div class="MAMCQmental-question-section">

                    <div class="MAMCQmcq-text">
                        <h4 className='mcqqstn'>Q: <span id="MAMCQmental-question-text">{currQuestion?.question}</span></h4>
                    </div>

                    <div className="MAMCQmcq-options">

                    <div>
                    <input type="radio" className="mcq-radio" ref={optionRef1} id={"option1"} name="mcq" value={currQuestion?.option1} style={{width:"20px",marginRight:"20px"}}/>
                    <label for={"option1"} className="mcq-option-label"> {currQuestion?.option1}</label><br />
                    </div>
                    <div>
                    <input type="radio" className="mcq-radio" ref={optionRef2} id={"option2"} name="mcq" value={currQuestion?.option2} style={{width:"20px",marginRight:"20px"}}/>
                    <label for={"option2"} className="mcq-option-label"> {currQuestion?.option2}</label><br />
                    </div>
                    <div>
                    <input type="radio" className="mcq-radio" ref={optionRef3} id={"option3"} name="mcq" value={currQuestion?.option3} style={{width:"20px",marginRight:"20px"}}/>
                    <label for={"option3"} className="mcq-option-label"> {currQuestion?.option3}</label><br />
                    </div>
                    <div>
                    <input type="radio" className="mcq-radio" ref={optionRef4} id={"option4"} name="mcq" value={currQuestion?.option4} style={{width:"20px",marginRight:"20px"}}/>
                    <label for={"option4"} className="mcq-option-label"> {currQuestion?.option4}</label><br />
                    </div>
                    </div>
                    <div class="MAMCQplay-button">
                        <button ref={submitButton} className='MAMCQsubmitButton' onClick={handleSubmit}><h5>Submit</h5></button>
                    </div>
                    
                </div>
            </div>
            <div class="MApicture">

                        {imageData ? (
                        <img
                          src={imageData}
                          alt="Question Image"
                          // className="MApicture"
                        />
                      ) : (
                        // <img src={imageData} alt="Question Image" /> 
                        <p></p>
                      )}
                    {/* <img src="" alt=""/> */}
                </div>

                <div className="MAMCQtime-marks">
                    <div className="MAMCQtime">
                        <div className="MAMCQtime-text">
                            <h4>Time</h4>
                        </div>
                        <div className="MAMCQtimer">
                            {/* <h4 id="MAtime-counter">0{hours}:{minutes}:{count}</h4> */}
                            <h4 id="MAtime-counter">{formatTime(count)}</h4>
                        </div>
                    </div>

                    <div className="MAMCQmarks">
                        <div className="MAMCQmarks-text">
                            <h4>Marks</h4>
                        </div>
                        <div className="MAMCQmarker">
                            <h4><span id="MAmarks-per-question">{marksPerQuestion}</span></h4>
                        </div>
                    </div>

                </div>

                <div className="MAMCQinstructions">
                    <select className="MAMCQinstructions-dropdown" name="role">
                        {/* <option value="Instructions">Instructions</option> */}
                        <option value="Instruction-1">{currQuestion?.instructions}</option>
                        {/* <option value="Instruction-2">Do not use calculator</option> */}
                    </select>
                </div>

                
            </div>

            <div id="MAmarks-color">
                {
                    items.map((item, index)=>(
                        <div className="MAbox">
                            <div className="MAbox1">
                                <h3>{index + 1}</h3>
                            </div>
                            <div className={`MAbox2${item}`}>

                            </div>
                        </div>
                    ))
                }

            </div>

        </div>}
    </div>

    <div className="MAMCQremarks">
        <div className="MAMCQcorrect-wrong-picture">
            <img id="MAremarksId" src={GoodImage} ref={GoodImageRef} alt="" style={{ width: '100px', display: 'none'}}/>
            <img id="remarksId" src={CrossImage} ref={CrossImageRef} alt="" style={{ width: '100px', display: 'none'}}/>
        </div>

        <div className="MAMCQfinished-picture">
            <img id="finishedId" src={FinishedImage} ref={FinishedImageRef} alt="" style={{ width: '100px', display: 'none'}}/>
        </div>
    </div>
    </>
    <div className="leaderboard-button-container" style={{ textAlign: "center", margin: "20px" }}>
        <button
          onClick={() => navigate("/leaderboard")}
          className="leaderboard-button"
          style={{
            padding: "10px 20px",
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "16px",
          }}
        >
          View Leaderboard
        </button>
      </div>
      <Footer/>
    </div>
  )
}

export default SpeedQuizActivity;
