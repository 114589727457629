import React,{useContext,useEffect} from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from '../Footer'

import "./CivilEngineering.css";
import "../MathPortal/Slider.css";
import "../MathPortal/MathCourseDetails.css";
import "../MathPortal/MathPortalResponsive.css";
import { MDBAccordion, MDBAccordionItem, MDBContainer} from "mdb-react-ui-kit";
import pic1 from "./EngineeringCourseImages/bridge.png";
import pic2 from "./EngineeringCourseImages/AutoCad.png";
import pic3 from "./EngineeringCourseImages/construction.png";
import pic4 from "./EngineeringCourseImages/projectPlan.png";
import civilicon from "./EngineeringCourseImages/civilIcon.png";
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import FeeStructure from '../components/FeeStructure';
import CE from './EngineeringCourseImages/civilEngg.png'

const CivilEngineering = ({selectedCourse}) => {
  const handleCourseSelect = (course) => {
    console.log("Selected Course in Home:", course);
    selectedCourse(course);
  };

 const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };
  
  
 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
      navigate("/");

    }
      
      const username = userInfo?.username;
      const _avatar_ = userInfo?.avatar;
   
return (
  <>
    {/* Navbar */}
    {username && (
        <>
        {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
        {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
        {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
      </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}

    <div  >
    <div className="CivilPortalBackground">
      <section className="section">
        <p className="PageHead" style={{ color: "#8b0194" }}>Civil Engineering Course</p>
        <div className="PageDesc">
        <div style={{display:"flex"}}>
              <p style={{
                fontFamily: "KG Primary Whimsy,VanillaMilk,'DM Sans', sans-serif",
                color: "white",
                textAlign: "left",
                padding: "30px",
                fontSize: "22px",
                zIndex: 1,
                color: "#8b0194",
                width:"70%"
                
              }}>
          Polymath-kids offer comprehensive online Civil Engineering course for kids. The online engineering classes’ offers two modes -online 1:1 classes and online group classes with trained teachers having years of experience in Civil Engineering.
          </p>
          <img style={{minWidth:"250px", maxWidth:"450px"}} src={CE}></img>
          </div>
          <div className="btn-row d-flex btn-row-resp btn-row-resp1">
            {/* <div className="btn-row-resp"> */}
            <div style={{ width: "auto", height: "50px" }}>
              <a
                className="btn-row-resp button btn-color-course"
                href="#course"
              >
                Course Structure
              </a>
            </div>

            <div style={{ width: "auto", height: "50px" }}>
              <a className=" btn-row-resp button btn-color-fee" href="#fee">
                Fee Structure
              </a>
            </div>
            <div style={{ width: "auto", height: "50px" }}>
              <a
                className=" btn-row-resp button btn-color-Testimonal"
                href="#testimonal"
              >
                Testimonals
              </a>
            </div>
            <div style={{ width: "auto", height: "50px" }}>
              <a className=" btn-row-resp button btn-color-Faq" href="#Faq">
                Faqs
              </a>
            </div>
            <div style={{ width: "auto", height: "50px" }}>
              <a
                className=" btn-row-resp button btn-color-registered"
                href="#"
              >
                Get Registered
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="overlay"></div>
    </div>

    {/* Intro text */}
    <div className="scratch-background">
      <section className="section">
        <p className="PageHead" style={{ color: "#8b0194" }}>
        What is Civil Engineering?
        </p>
        <div className="PageDesc">
          <p
            style={{
              color: "#8b0194",
              marginTop: "2vh",
              fontSize: "4vh",
              fontFamily: "sans serif",
            }}
          >
          Civil engineering is the application of physical and scientific principles for solving the problems of society, and its history is intricately linked to advances in the understanding of physics and mathematics throughout history. Because civil engineering is a broad profession, including several specialized sub-disciplines, its history is linked to knowledge of structures, materials science, geography, geology, soils, hydrology, environmental science, mechanics, project management, and other fields.

What do Civil Engineers do?

<ul>
    <li>
        
Civil Engineers work for public and private organizations.
    </li>
    <li>
    They design, develop, and construct structures.
    </li>
    <li>
They design, build, and maintain transportation systems and infrastructure.
    </li>
    <li>
They design, operate, and control water resource systems.

    </li>
</ul>

How do I know if Civil Engineering is right for me?
Are you creative? Do you enjoy strategic thinking? Are you a planner or well organized? When you visit a city, do you marvel at the way it functions? Are you fascinated by bridges, railways, buildings and complex systems? Civil Engineers shape our world through their analysis of intricate, interdependent systems and their ability to find creative solutions to complex problems. 
<br />
Being a patient and curious person helps and of course a solid background in science and math are essential, too.
<br />
          </p>
        </div>
      </section>
    </div>

    {/* Course Structure and Syllabus */}
    <section className="section course-structure" id="course">
      <p className="PageHead" style={{ color: "#8b0194" }}>
        Course Structure and Syllabus
      </p>
      <h4 className="H4_Heading" style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
        <b>Course Objectives</b>
      </h4>
      <ul style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
        <li>
          Learn the foundation of Civil Engineering
        </li>
       
        
        <li>
        Develop 21st Century Skills using Block based Programming:
    
        </li>

        <div style={{marginLeft:"5%"}}>
            <p>
            - Logic application and self-expression <br />
            - Creativity and Problem-Solving Skills <br />
            - Mathematical, Computational and Analytical Thinking <br />
            - Persistence <br />
            - Creativity and Imagination <br />
            - Career-Building Skills <br />
            - Confidence and Communication Skills <br />
            - STEM Education <br />
            </p>
        </div>
        
      </ul>

      <div>
        <p className='moduleSection'>
          <img src={civilicon} alt="" style={{ width: "2em", height: "2em" }} />
          Civil Engineering Beginner Course (Suitable for Grade 7 to 8)
        </p>
      </div>

      {/* Module one */}
      <div className="modules">
        {/* round box */}
        <div className="round-box">
          <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
            {" "}
            Module
            <br />
            1
          </p>
        </div>
        {/* small boxes */}
        <div className="small-box" style={{ color: "#249935" }}>
          <p style={{ margin: "auto" }}>
            Activities
            <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>3</p>
          </p>

          <div
            className="small-box sm-box"
          >
            <p style={{ margin: "auto" }}>
              No.of Lessons
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>2</p>
            </p>
          </div>
        </div>

        <div className="large-box" style={{ color: "#249935" }}>
          <p style={{ margin: "auto" }}>
            Structure
            <p
              style={{
                fontWeight: "lighter",
                fontSize: "15px",
                marginBottom: "0px",
              }}
            >
            This module will help the students to understand different forces that exist in a structure. The project of ice cream sticks bridge will further clear their ambiguities as they will observe those forces in it. Furthermore, this module is designed to cover the domain of structures of Civil Engineering.
            </p>
          </p>
        </div>
      </div>

      {/* Module  two */}
      <div className="modules" style={{ marginTop: "2vh" }}>
        {/* round box */}
        <div className="round-box">
          <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
            {" "}
            Module
            <br />
            2
          </p>
        </div>
        {/* small boxes */}
        <div className="small-box" style={{ color: "#249935" }}>
          <p style={{ margin: "auto" }}>
            Activities
            <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>3</p>
          </p>

          <div
            className="small-box sm-box"
          >
            <p style={{ margin: "auto" }}>
              No.of Lessons
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>2</p>
            </p>
          </div>
        </div>

        <div className="large-box" style={{ color: "#249935" }}>
          <p className="my-4" style={{ margin: "auto" }}>
          AUTOCAD
            <p
              style={{
                fontWeight: "lighter",
                fontSize: "15px",
                marginBottom: "0px",
              }}
            >
             This module introduces the students to AUTOCAD software and will enable them to develop basic house plans. 
             
            </p>
          </p>
        </div>
      </div>

       {/* Module  Three */}
       <div className="modules" style={{ marginTop: "2vh" }}>
        {/* round box */}
        <div className="round-box">
          <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
            {" "}
            Module
            <br />
           3
          </p>
        </div>
        {/* small boxes */}
        <div className="small-box" style={{ color: "#249935" }}>
          <p style={{ margin: "auto" }}>
            Activities
            <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>4</p>
          </p>

          <div
            className="small-box sm-box"
          >
            <p style={{ margin: "auto" }}>
              No.of Lessons
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>3</p>
            </p>
          </div>
        </div>

        <div className="large-box" style={{ color: "#249935" }}>
          <p className="my-4" style={{ margin: "auto" }}>
          Construction Equipments and Machinery
            <p
              style={{
                fontWeight: "lighter",
                fontSize: "15px",
                marginBottom: "0px",
              }}
            >
             Students will learn about different types of machinery and equipment used in the construction of small and large projects. This module will also throw light on types of construction projects.
            </p>
          </p>
        </div>
      </div>

         {/* Module  4 */}
         <div className="modules" style={{ marginTop: "2vh" }}>
        {/* round box */}
        <div className="round-box">
          <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
            {" "}
            Module
            <br />
           4
          </p>
        </div>
        {/* small boxes */}
        <div className="small-box" style={{ color: "#249935" }}>
          <p style={{ margin: "auto" }}>
            Activities
            <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>3</p>
          </p>

          <div
            className="small-box sm-box"
          >
            <p style={{ margin: "auto" }}>
              No.of Lessons
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>2</p>
            </p>
          </div>
        </div>

        <div className="large-box" style={{ color: "#249935" }}>
          <p className="my-4" style={{ margin: "auto" }}>
          Construction Management
            <p
              style={{
                fontWeight: "lighter",
                fontSize: "15px",
                marginBottom: "0px",
              }}
            >
            In this module, students will learn about different processes involved in the construction phase and methods to manage them.
            </p>
          </p>
        </div>

        {/*  */}
      </div>

          {/* Module  5 */}
          <div className="modules" style={{ marginTop: "2vh" }}>
        {/* round box */}
        <div className="round-box">
          <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
            {" "}
            Module
            <br />
           5
          </p>
        </div>
        {/* small boxes */}
        <div className="small-box" style={{ color: "#249935" }}>
          <p style={{ margin: "auto" }}>
            Activities
            <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>4</p>
          </p>

          <div
            className="small-box sm-box"
          >
            <p style={{ margin: "auto" }}>
              No.of Lessons
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>3</p>
            </p>
          </div>
        </div>

        <div className="large-box" style={{ color: "#249935" }}>
          <p className="my-4" style={{ margin: "auto" }}>
          Road Planning and Traffic Symbols
            <p
              style={{
                fontWeight: "lighter",
                fontSize: "15px",
                marginBottom: "0px",
              }}
            >
        This module will help in creating traffic awareness. Different traffic symbols and their purposes. Furthermore, it will clarify the importance of civil engineering in road planning.
            </p>
          </p>
        </div>
 
      </div>

      {/* Module 1 */}
            <div>
            <p className='moduleSection' style={{ color: "#485cd9"}}>
                <img src={civilicon} alt=""style={{width:"2em",height:"2em"}} />
                Civil Engineering Intermediate Course (Suitable for Grade 9 to 10)
            </p>
          </div>

          <div className="modules">
            {/* round box */}
            <div className="round-box round-box1 ">
              <p style={{ marginTop: "5vh", color: "#d9defb" }}>
                {" "}
                Module
                <br />
                1
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box small-box1" style={{ color: "#485cd9" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  4
                </p>
              </p>

              <div
                className="small-box small-box1 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   3
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box large-box1" style={{ color: "#485cd9" }}>
              <p style={{ margin: "auto" }}>
              Structures
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                 This module will help the students to understand different forces that exist in a structure. The project of ETABS software will help them to visualize the  impact of forces on critical parts of structure. Furthermore, this module is designed to cover the domain of structures of Civil Engineering.
                </p>
              </p>
            </div>
          </div>

          {/* Module 2 */}
          <div className="modules my-3">
            {/* round box */}
            <div className="round-box round-box1 ">
              <p style={{ marginTop: "5vh", color: "#d9defb" }}>
                {" "}
                Module
                <br />
                2
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box small-box1" style={{ color: "#485cd9" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  4
                </p>
              </p>

              <div
                className="small-box small-box1 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                    3
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box large-box1" style={{ color: "#485cd9" }}>
              <p style={{ margin: "auto" }}>
              AUTOCAD
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                 This module introduces the students to AUTOCAD software and will enable them to develop house plans with medium difficulty level. 
                 </p>
              </p>
            </div>
          </div>

          {/* Module 3 */}
          <div className="modules my-3">
            {/* round box */}
            <div className="round-box round-box1 ">
              <p style={{ marginTop: "5vh", color: "#d9defb" }}>
                {" "}
                Module
                <br />
                3
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box small-box1" style={{ color: "#485cd9" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  4
                </p>
              </p>

              <div
                className="small-box small-box1 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                    3
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box large-box1" style={{ color: "#485cd9" }}>
              <p style={{ margin: "auto" }}>
              Construction Equipments and Machinery
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                 Students will learn about different types of machinery and equipment used in the construction of small and large projects. This module will also throw light on types of construction projects. 
                </p>
              </p>
            </div>
          </div>

          {/* Module 4 */}
          <div className="modules my-3">
        {/* round box */}
        <div className="round-box round-box1 ">
          <p style={{ marginTop: "5vh", color: "#d9defb" }}>
            {" "}
            Module
            <br />
            4
          </p>
        </div>
        {/* small boxes */}
        <div className="small-box small-box1" style={{ color: "#485cd9" }}>
          <p style={{ margin: "auto" }}>
            Activities
            <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
              4
            </p>
          </p>

          <div
            className="small-box small-box1 sm-box"
          >
            <p style={{ margin: "auto" }}>
              No.of Lessons
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                3
              </p>
            </p>
          </div>
        </div>

        <div className="large-box large-box1" style={{ color: "#485cd9" }}>
          <p style={{ margin: "auto" }}>
          Construction Management
            <p
              style={{
                fontWeight: "lighter",
                fontSize: "15px",
                marginBottom: "0px",
              }}
            >
            In this module, students will learn about different processes involved in the construction phase and methods to manage them. Furthermore, they would be introduced to Primavera P6 software where they would schedule the construction phase of a house as part of their project.
            </p>
          </p>
        </div>
          </div>

          {/* Module 5 */}
          <div className="modules my-3">
            {/* round box */}
            <div className="round-box round-box1 ">
              <p style={{ marginTop: "5vh", color: "#d9defb" }}>
                {" "}
                Module
                <br />
                5
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box small-box1" style={{ color: "#485cd9" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  3
                </p>
              </p>

              <div
                className="small-box small-box1 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                    2
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box large-box1" style={{ color: "#485cd9" }}>
              <p style={{ margin: "auto" }}>
              Road Planning and Traffic Symbols
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                This module will help in creating awareness about different traffic symbols, their purposes and their role in road planning .Furthermore, it will clarify the importance of civil engineering in road planning. A Project of real life scenario would create a sense of developing a road plan with an engineer’s vision.
                </p>
              </p>
            </div>
          </div>

          {/* Module one */}
            <div>
            <p className='moduleSection' style={{ color: "#999924" }}>
                <img src={civilicon} alt=""style={{width:"2em",height:"2em"}} />
                Civil Engineering Advance Course (Suitable for Grade 11 to 12)
            </p>
          </div>

          <div className="modules">
            {/* round box */}
            <div className="round-box RB1 ">
              <p style={{ marginTop: "5vh", color: "#f8f8da" }}>
                {" "}
                Module
                <br />
                1
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box SB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  4
                </p>
              </p>

              <div
                className="small-box SB1 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   3
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box LB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
              Structures
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                This module will help the students to understand different forces that exist in a structure. The project of ETABS software will help them to visualize the  impact of forces on critical parts of structure. Furthermore, this module is designed to cover the domain of structures of Civil Engineering.
                </p>
              </p>
            </div>
          </div>

          {/* Module 2 */}
          <div className="modules">
            {/* round box */}
            <div className="round-box RB1 ">
              <p style={{ marginTop: "5vh", color: "#f8f8da" }}>
                {" "}
                Module
                <br />
                2
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box SB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  4
                </p>
              </p>

              <div
                className="small-box SB1 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   3
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box LB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
              AUTOCAD
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
              This module introduces the students to AUTOCAD software and will enable them to develop advance level house plans with introduction to 3D modeling. 
                </p>
              </p>
            </div>
          </div>

           {/* Module 3 */}
           <div className="modules">
            {/* round box */}
            <div className="round-box RB1 ">
              <p style={{ marginTop: "5vh", color: "#f8f8da" }}>
                {" "}
                Module
                <br />
                3
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box SB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  4
                </p>
              </p>

              <div
                className="small-box SB1 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   3
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box LB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
              Construction Equipments and Machinery
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
              Students will learn about different types of machinery and equipment used in the construction of small and large projects. This module will also throw light on types of construction projects. 
                </p>
              </p>
            </div>
          </div>

           {/* Module 4*/}
           <div className="modules">
            {/* round box */}
            <div className="round-box RB1 ">
              <p style={{ marginTop: "5vh", color: "#f8f8da" }}>
                {" "}
                Module
                <br />
                4
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box SB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  4
                </p>
              </p>

              <div
                className="small-box SB1 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   3
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box LB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
              Construction Management
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
              In this module, students will learn about different processes involved in the construction phase and methods to manage them. Furthermore, they would be introduced to Primavera P6 software where they would schedule the construction phase of a large scale project as part of their project.
                </p>
              </p>
            </div>
          </div>

          {/* Module 5*/}
          <div className="modules">
            {/* round box */}
            <div className="round-box RB1 ">
              <p style={{ marginTop: "5vh", color: "#f8f8da" }}>
                {" "}
                Module
                <br />
                5
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box SB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  3
                </p>
              </p>

              <div
                className="small-box SB1 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   2
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box LB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
              Road Planning and Traffic Symbols
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
              This module will help in creating awareness about different traffic symbols, their purposes and their role in road planning .Furthermore, it will clarify the importance of civil engineering in road planning. A Project of real life scenario would create a sense of developing a road plan with an engineer’s vision.
                </p>
              </p>
            </div>
          </div>
       
          {/* Module 6*/}
          <div className="modules">
            {/* round box */}
            <div className="round-box RB1 ">
              <p style={{ marginTop: "5vh", color: "#f8f8da" }}>
                {" "}
                Module
                <br />
                6
              </p>
            </div>
            {/* small boxes */}
            <div className="small-box SB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
                Activities
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                  3
                </p>
              </p>

              <div
                className="small-box SB1 sm-box"
              >
                <p style={{ margin: "auto" }}>
                  No.of Lessons
                  <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   2
                  </p>
                </p>
              </div>
            </div>

            <div className="large-box LB1" style={{ color: "#999924" }}>
              <p style={{ margin: "auto" }}>
              Soil Mechanics
                <p
                  style={{
                    fontWeight: "lighter",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
              
              This module focuses on imparting knowledge about types of soil, their classification, techniques to improve soil and their suitability for different structures. Based on this particular knowledge students would be given the project of a real life scenario. 
                </p>
              </p>
            </div>
          </div>

      <h6 style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
        <b>Learn Civil Engineering with 60+ Fun Activities</b>
      </h6>

      <div className="activityImagesContainer">
        <div className="row">
          <div class="col-lg mx-3 civilActivitties">
            <img src={pic1} alt="" className='ActivityImages'/>
            <p className="mx-3 ActivityLabels">IceCream Sticks Bridge</p>
          </div>

          <div class="col-lg mx-3 civilActivitties">
            <img src={pic4} alt="" className='ActivityImages'/>
            <p className="mx-3 ActivityLabels">Primavera Project Plan</p>
          </div>

          <div class="col-lg mx-3 civilActivitties">
            <img src={pic2} alt="" className='ActivityImages'/>
            <p className="mx-3 ActivityLabels">AutoCad Floor Plan</p>
          </div>

          <div class="col-lg mx-3 civilActivitties">
            <img src={pic3} alt="" className='ActivityImages'/>
            <p className="mx-3 ActivityLabels">Construction & equipment Machinery</p>
          </div>
        </div>
      </div>
      
    </section>

    {/* Fee Structure */}
    <FeeStructure subject="Engineering"/>

    {/* Testimonals */}
    <div class="  background" id="testimonal">
      <div class="row" style={{ width: "100%" }}>
        <div class="col-lg-8 mx-auto">
          <h2 className="Testimonal_Heading">Testimonials</h2>
          <div id="myCarousel" class="carousel slide" data-ride="carousel">
            {/* <!-- Carousel indicators --> */}
            <ol class="carousel-indicators">
              <li
                data-target="#myCarousel"
                data-slide-to="0"
                class="active"
              ></li>
              <li data-target="#myCarousel" data-slide-to="1"></li>
            </ol>
            {/* <!-- Wrapper for carousel items --> */}
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="img-box">
                  <div className=" img-box1"></div>
                </div>
                <p
                  class="testimonial"
                  style={{ color: "black", fontSize: "3vh" }}
                >
                  I enjoyed the learning experience with Polymath-Kids-Abacus.
                  The teacher was very friendly. I highly recommend
                  Polymath-Kids-Abacus.
                </p>
                <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                  <b>Mujtaba Khawaja Sahaf</b>
                </p>
              </div>

              <div class="carousel-item">
                <div class="img-box">
                  <div className="img-box2 "></div>
                </div>
                <p
                  class="testimonial"
                  style={{ color: "black", fontSize: "3vh" }}
                >
                  I feel so proud when my class fellows call me ’BRAINIAC ’,
                  because I can effortlessly do mental calculations much
                  faster and accurate than my class mates. Thank you
                  Polymath-Kids.
                </p>
                <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                  <b>Wahaab Omer</b>, Age 9 Years
                </p>
              </div>
            </div>
            {/* <!-- Carousel controls --> */}
            <a
              class="carousel-control-prev"
              href="#myCarousel"
              data-slide="prev"
            >
              <i class="fa fa-angle-left"></i>
            </a>
            <a
              class="carousel-control-next"
              href="#myCarousel"
              data-slide="next"
            >
              <i class="fa fa-angle-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    {/* Faqs */}
    <div style={{ maxWidth: "100%", backgroundColor: "#edffff" }} id="Faq">
      <MDBContainer className="mt-0" style={{ maxWidth: "180vh" }}>
        <MDBAccordion alwaysOpen initialActive={1}>
          <div className="text-center PageHead" style={{ color: "#8b0194" }}>
            {" "}
            FAQs
          </div>        
         <MDBAccordionItem
            collapseId={3}
            headerTitle="Q: What is Civil Engineering?"
          >
            <strong>A: </strong>
            Civil engineering is a professional engineering discipline that deals with the design, construction, and maintenance of the physical and naturally built environment, including public works such as roads, bridges, canals, dams, airports, sewage systems, pipelines, structural components of buildings, and railways.
          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={3}
            headerTitle="Q: Which age group is suitable to learn Civil Engineering?"
          >
            <strong>A: </strong>
            Polymath-Kids has developed separate customized and age appropriate Civil Engineering curriculum for three age groups i.e. grade 7 to 8, grade 9 to 10 and grade 11 to 12.
          </MDBAccordionItem>
       </MDBAccordion>
      </MDBContainer>
    </div>
  </div>
  
{/* Footer */}
<Footer/>
    </>
  )
}

export default CivilEngineering
