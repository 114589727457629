import React,{useContext} from 'react'

import StudentNav from '../../Navbars/StudentNav'
import AdminNav from '../../Navbars/AdminNav'
import TeacherNav from '../../Navbars/TeacherNav';
import GuestNav from '../../Navbars/GuestNav';
import { UserContext } from '../../UserContext';
import Footer from '../../Footer'

import { fetchActivitiesImage } from '../fetchActivitiesImage';

// import { useParams } from "react-router-dom";

import { useLocation } from "react-router-dom";

import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';


import './WrittenActivity.css'
import axios from 'axios';

import WrittenActivityImage from './activity.png'
import GoodImage from './good.png'
import CrossImage from './cross.png'
import FinishedImage from './finished.jpeg'
// import AdditionUsingCountingImage from './Addition-using-counting.png'
import { useRef, useState, useEffect } from 'react'
 

const WrittenActivity = () => {

  const selectedCourse = ""
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activityId = searchParams.get("activityId");

  // console.log("activityId:", activityId);

  console.log("Activity ID:", activityId);


 

    const [actualQuestions, setActualQuestions] = useState([]);
    const [currQuestion, setCurrQuestion] = useState([]);

    const [imageData, setImageData] = useState(null);

    const [count, setCount] = useState();
    const countRef = useRef();

    const totalQuestionsRef = useRef(0);

    const progressIncrement = useRef();

    useEffect(() => {
        // Define an async function to fetch the questions from the API
        async function fetchQuestions() {
          try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getActivityQuestions/${activityId}`);
            setActualQuestions(response.data);
            console.log(response.data[0]);
            setCurrQuestion(response.data[0]);

            console.log("..........Calling Fetch Image 01..........")
            console.log("Question Picture:",response.data[0].questionpicture)
            fetchActivitiesImage(setImageData,response.data[0].questionpicture);

            setCount(response.data[0].timealloted);
            countRef.current = response.data[0].timealloted;
            setMarksPerQuestion(response.data[0].marks);

            var total_marks = 0;
            var total_questions = 0;
            response.data.forEach(data => {
                total_marks += data.marks;
                total_questions++;
            });

            setTotalMarks(total_marks);
            setTotalQuestions(total_questions);

            totalQuestionsRef.current = total_questions;

            progressIncrement.current = (100/(totalQuestionsRef.current))*1.3;

          } catch (error) {
            console.error(error);
          }

          try {
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/saveActivity`, {
              Activity_id: activityId,
              Student_id: student_ID,
              MarksObtained: gainedMarksRef.current,
              MarksObtainedPercentage: gainedMarksRef.current / totalMarks * 100,
              Remarks: (gainedMarksRef.current / totalMarks * 100) > 90 ? 'Excellent Work' : (gainedMarksRef.current / totalMarks * 100) > 70 ? "Good Job" : "Try Again",
              SubmitActivityFile: '',
              TimeTaken: totalTimeTakenOfAllQuestions.current,
              TotalMarks: totalMarks,
              TotalTime: totalTimeOfAllQuestions.current < 0 ? 0 : totalTimeOfAllQuestions.current,
              Complete: 0,//completeActivityRef.current,
            });
            console.log(response.data);
          } catch (error) {
            console.error(error);
          }
          try {
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/deleteActivityQuestions`, {
              Lesson_Activity_id: activityId,
              Student_id: student_ID,
    
            });
            console.log(response.data);
          } catch (error) {
            console.error(error);
          }
        }
    
        // Call the async function to fetch the questions
        fetchQuestions();
      }, [activityId]);

      const submitButton = useRef(null);
      const GoodImageRef = useRef(null);
      const CrossImageRef = useRef(null);
      const FinishedImageRef = useRef(null);

      const ProgressRef = useRef(null);
    

    const [items, setItems] = useState([]);
    // const [currentQuestion, setCurrentQuestion] = useState(questions[0]);
    const [gainedMarks, setGainedMarks] = useState(0);
    const [questionNumber, setQuestionNumber] = useState(1);
    const [studentAnswer, setStudentAnswer] = useState("");
    const [totalQuestions, setTotalQuestions] = useState(3);
    const [marksPerQuestion, setMarksPerQuestion] = useState(5);
    const [totalMarks, setTotalMarks] = useState(15);
    const questionNumberRef = useRef(0);

    const progressCount = useRef(0);
    // const progressIncrement = useRef((100/(totalQuestions))*1.3);

    console.log("progress Increment is: ", progressIncrement.current);

    const [student_ID, setStudent_ID] = useState("2");
    const studentCurrAnswerRef = useRef(0);
    const marksObtainedCurrQuestionRef = useRef(0);
    const isCorrectRef = useRef(0);

    const gainedMarksRef = useRef(0);

    var intervalId;
    useEffect(() => {
            intervalId = setInterval(() => {
          countRef.current = countRef.current - 1;
          if (countRef.current === -1) {
            
            studentCurrAnswerRef.current = "";
            marksObtainedCurrQuestionRef.current = marksPerQuestion;
            isCorrectRef.current = 0;

            submitStudentQuestionResponse();
            
            questionNumberRef.current = questionNumberRef.current + 1;
            setQuestionNumber(questionNumber => questionNumber + 1);

            setCurrQuestion(actualQuestions[questionNumberRef.current]);
            console.log("..........Calling Fetch Image..........")
            fetchActivitiesImage(setImageData,actualQuestions[questionNumberRef.current].questionpicture);
            setMarksPerQuestion(actualQuestions[questionNumberRef.current].marks);
            setCount(actualQuestions[questionNumberRef.current].timealloted);
            setItems([...items, 0]);
            countRef.current = actualQuestions[questionNumberRef.current].timealloted;

            GoodImageRef.current.style.display = 'none';
            CrossImageRef.current.style.display = "inline-block";

            progressCount.current += progressIncrement.current;
            ProgressRef.current.style.width = `${progressCount.current}px`;


          } else {
            setCount(countRef.current);
          }
        }, 1000);
    
        // Return a cleanup function to clear the interval
        return () => clearInterval(intervalId);
      }, [questionNumber, count]);
    
      useEffect(() => {
        if (questionNumber == totalQuestions && count == 0) {

            studentCurrAnswerRef.current = "";
            marksObtainedCurrQuestionRef.current = marksPerQuestion;
            isCorrectRef.current = 0;
            submitStudentQuestionResponse();

            progressCount.current += progressIncrement.current;
            ProgressRef.current.style.width = `${progressCount.current}px`;


            setItems([...items, 0]);
          clearInterval(intervalId);

          GoodImageRef.current.style.display = 'none';
        CrossImageRef.current.style.display = "inline-block";
          
          submitButton.current.style.display = "none";
          FinishedImageRef.current.style.display = "inline-block";
        }
      }, [questionNumber, count]);

      const handleStudentAnswer = (event) =>{
        setStudentAnswer(event.target.value);
      }



      const totalTimeTakenOfAllQuestions = useRef(0);
      const totalTimeOfAllQuestions = useRef(0);

      const submitStudentQuestionResponse = async () => {
        // event.preventDefault();

        totalTimeTakenOfAllQuestions.current += currQuestion.timealloted - countRef.current - 1;
        totalTimeOfAllQuestions.current += currQuestion.timealloted;

        try {
            // console.log("I am in submitting response...")
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/saveActivityQuestion`, {
                Question_id: currQuestion.Question_id,
                Student_id: student_ID,
                timetaken: currQuestion.timealloted - countRef.current - 1,
                StudentAnswer: studentCurrAnswerRef.current,
                MarksObtained: marksObtainedCurrQuestionRef.current,
                TotalTime: currQuestion.timealloted,
                correct: isCorrectRef.current
            });
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }

        try {
          console.log("I am in submitting  response...")
          const response = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/saveActivity`, {
            Activity_id: activityId,
            Student_id: student_ID,
            MarksObtained: gainedMarksRef.current,
            MarksObtainedPercentage: gainedMarksRef.current / totalMarks * 100,
            Remarks: (gainedMarksRef.current / totalMarks * 100) > 90 ? 'Excellent Work' : (gainedMarksRef.current / totalMarks * 100) > 70 ? "Good Job" : "Try Again",
            SubmitActivityFile: '',
            TimeTaken: totalTimeTakenOfAllQuestions.current,
            TotalMarks: totalMarks,
            TotalTime: totalTimeOfAllQuestions.current < 0 ? 0 : totalTimeOfAllQuestions.current,
            Complete: 0,//completeActivityRef.current,
          });
          console.log(response.data);
        } catch (error) {
          console.error(error);
        }
    };

    const completeActivityRef = useRef();

    const submitCompleteActivityResponse = async () => {
        // e.preventDefault();
    
        try {
            console.log("I am in submitting Complete response...")
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/saveActivity`, {
                Activity_id: activityId,
                Student_id: student_ID,
                MarksObtained: gainedMarksRef.current,
                MarksObtainedPercentage: gainedMarksRef.current / totalMarks * 100,
                Remarks: 'Good Efforts',
                SubmitActivityFile: '',
                TimeTaken: totalTimeTakenOfAllQuestions.current,
                TotalMarks: totalMarks,
                TotalTime: totalTimeOfAllQuestions.current,
                Complete: completeActivityRef.current,
            });
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
      };




      const handleSubmit = (event) => {
        event.preventDefault();
        console.log(studentAnswer);

        

        if(questionNumber === totalQuestions){
            
            if(studentAnswer === currQuestion.correctanswer){
                setItems([...items, 1]);
                gainedMarksRef.current = gainedMarksRef.current + marksPerQuestion;
                setGainedMarks(gainedMarks => gainedMarks + marksPerQuestion);
                CrossImageRef.current.style.display = 'none';
                GoodImageRef.current.style.display = "inline-block";

                studentCurrAnswerRef.current = studentAnswer;
                marksObtainedCurrQuestionRef.current = marksPerQuestion;
                isCorrectRef.current = 1;


                submitStudentQuestionResponse();
            }
            else{
                setItems([...items, 0]);
                GoodImageRef.current.style.display = 'none';
                CrossImageRef.current.style.display = "inline-block";

                studentCurrAnswerRef.current = studentAnswer;
                marksObtainedCurrQuestionRef.current = 0;
                isCorrectRef.current = 0;

                submitStudentQuestionResponse();
            }
            clearInterval(intervalId);
            submitButton.current.style.display = "none";
            FinishedImageRef.current.style.display = "inline-block";

            progressCount.current += progressIncrement.current;
            ProgressRef.current.style.width = `${progressCount.current}px`;

            completeActivityRef.current = 1;
            submitCompleteActivityResponse();

        }
        else{
            if(studentAnswer === currQuestion.correctanswer){
                items.push(1);
                gainedMarksRef.current = gainedMarksRef.current + marksPerQuestion;
                setGainedMarks(gainedMarks => gainedMarks + marksPerQuestion);
                CrossImageRef.current.style.display = 'none';
                GoodImageRef.current.style.display = "inline-block";


                studentCurrAnswerRef.current = studentAnswer;
                marksObtainedCurrQuestionRef.current = marksPerQuestion;
                isCorrectRef.current = 1;


                submitStudentQuestionResponse();
            }
            else{
                items.push(0);
                GoodImageRef.current.style.display = 'none';
                CrossImageRef.current.style.display = "inline-block";

                studentCurrAnswerRef.current = studentAnswer;
                marksObtainedCurrQuestionRef.current = 0;
                isCorrectRef.current = 0;

                submitStudentQuestionResponse();
            }

            questionNumberRef.current = questionNumberRef.current + 1;
            setQuestionNumber(questionNumber => questionNumber + 1);
            // setCurrentQuestion(questions[questionNumberRef.current]);
            console.log("..........Calling Fetch Image..........")
            fetchActivitiesImage(setImageData,actualQuestions[questionNumberRef.current].questionpicture);
            setCurrQuestion(actualQuestions[questionNumberRef.current])
            setMarksPerQuestion(actualQuestions[questionNumberRef.current].marks);

            setCount(actualQuestions[questionNumberRef.current].timealloted);
            countRef.current = actualQuestions[questionNumberRef.current].timealloted;

            console.log("progress Increment is: ", progressIncrement.current);

            progressCount.current += progressIncrement.current;
            ProgressRef.current.style.width = `${progressCount.current}px`;
        }
        setStudentAnswer('')
      }
           

    //   const { isAdmin, isTeacher, isStudent, isGuest, setUserRole } = useContext(UserContext);
    //   const determineUserRole = (email, password) => {
    //     // console.log('Email:', email);
    //     // console.log('Password:', password); 
    
    //         return "teacher";
    //   };
    //   const onLogin = (email, password) => {
    //     const userRole = determineUserRole(email, password);
    //     setUserRole(userRole);
    //   };  







    const handleCourseSelect = (course) => {
        console.log("Selected Course in Home:", course);
        selectedCourse(course);
      };
    
     const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
     
        useEffect(() => {
          const fetchData = async () => {
           await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
              credentials: 'include',
            }).then(response => {
                console.log("Response:",response);
              response.json().then(userInfo => {
                console.log("userinfo:",userInfo);
                setUserInfo(userInfo);
    
                if(userInfo?.usertype)
                {
                  const uType=userInfo?.usertype;
                  setUserRole(uType);
                }
              const uname = userInfo?.username;
              const avatar = userInfo?.avatar; // Store the avatar in a separate variable
              console.log("Uname: ", uname);
              console.log("Avatar: ", avatar);
    
              })
              .catch(err=> console.log("Error in useeffect:",err))
            });
          }
          fetchData();
        }, [isAdmin, isTeacher, isStudent, isGuest]);
      const determineUserRole = (usertype) => {
            console.log("In home.js:",usertype)
            if(usertype === 'Student')
              return "Student";
            else if(usertype === 'Teacher')
              return "Teacher";
            else if(usertype === 'Admin')
              return "Admin";
            else
              return "guest";
      };
      
      
     const navigate=useNavigate();
      const onLogin = (usertype) => {
        const userRole = determineUserRole(usertype);
        console.log("Calling setUserRole.")
        setUserRole(userRole);
      };
    
      function onLogout() {
          console.log("inside Onlogout.");
          fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
            credentials: 'include',
            method: 'POST',
          });
          setUserInfo(null);
          setUserRole('guest');
          navigate("/");

        }
          
        const username = userInfo?.username;
        const _avatar_ = userInfo?.avatar;
        const usertype = userInfo?.usertype;    


        function formatTime(seconds) {
          const minutes = Math.floor(seconds / 60);
          const remainingSeconds = seconds % 60;
          return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
      }

  return (


    <div>

{username && (
        <>
             {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
            {isTeacher && <TeacherNav  onCourseSelect={handleCourseSelect}  username={username} avatar={_avatar_} onLogout={onLogout}/>}
            {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
         </>
         )}  
         {!username && (
           <>
           {isGuest && <GuestNav onLogin={onLogin} />}
  
           </>
         )}

           {/* Navbar */}
            {/* {isGuest && <GuestNav onLogin={onLogin} />}

            {isAdmin && <AdminNav />}
            {isTeacher && <TeacherNav />}
            {isStudent && <StudentNav />} */}

         <div class="MAheading">
            <div class="MAactivity-image">
                <img src={WrittenActivityImage} alt=""/>
            </div>

            <div class="MAactivity-text">
                {/* <h3>Scratch M4 L1 Activity 1</h3> */}
                <h3>{currQuestion.ActivityName}</h3>
            </div>
        </div>

    <div class="MAcontainer">
        <div class="MAhead">

            <div class="MAquestions">

                <div class="MAquestions-numbers">
                    <p>Question <span id="MAcurrent-question">{questionNumber}</span> of <span id="MAtotal-questions">{totalQuestions}</span></p>
                </div>

                <div class="MAprogress-bar">
                    <div id="MAprogress" ref={ProgressRef}></div>
                </div>
                

            </div>

            <div class="MAscore">

                <div class="MAscore-text">
                    <h4>Score</h4>
                </div>

                <div class="MAnumbered-score">
                    <h2> <span id="MAcurrent-score">{gainedMarks}</span> / <span id="MAtotal-score">{totalMarks}</span></h2>
                </div>

            </div>

        </div>


        <div class="MAbody">

            <div class="MAremaining-body">

                <div class="MAquestion">
                    <h5 class="question-text">{currQuestion.question}</h5>
                </div>
    
                <div class="MApicture">

                    {imageData ? (
                        <img
                          src={imageData}
                          alt="Question Image"
                          // className="MApicture"
                        />
                      ) : (
                        // <img src={imageData} alt="Question Image" /> 
                        <p></p>
                      )}

                    {/* <img src={AdditionUsingCountingImage} alt=""/> */}
                </div>

                <div class="MAtime-marks">
                    <div class="MAtime">
                        <div class="MAtime-text">
                            <h4>Time</h4>
                        </div>
                        <div class="MAtimer">
                            <h4 id="MAtime-counter">{formatTime(count)}</h4>
                        </div>
                    </div>

                    <div class="MAmarks">
                        <div class="MAmarks-text">
                            <h4>Marks</h4>
                        </div>
                        <div class="MAmarker">
                            <h4><span id="MAmarks-per-question">{marksPerQuestion}</span></h4>
                        </div>
                    </div>

                </div>

                <div className="MAMCQinstructions">
                    <select className="MAMCQinstructions-dropdown" name="role">
                        {/* <option value="Instructions">Instructions</option> */}
                        <option value="Instruction-1">{currQuestion.instructions}</option>
                        {/* <option value="Instruction-2">Do not use calculator</option> */}
                    </select>
                </div>

                <div class="MAanswer-submit">
                    <input id="MAstudent-answer" class="MAanswer" type="text" placeholder="Type Answer here..." value={studentAnswer} onChange={handleStudentAnswer}
                      onKeyDown={(e) => {
                        console.log("e.key",e.key)
                        if (e.key === 'Enter' && questionNumber != totalQuestions && count != 0) {
                            handleSubmit(e);
                        }
                      }}
                    />
                    <input id="MAsubmission" type="submit" ref={submitButton} onClick={handleSubmit}/>
                </div>
            </div>

            <div id="MAmarks-color">
                {
                    items.map((item, index)=>(
                        <div className="MAbox">
                            <div className="MAbox1">
                                <h3>{index + 1}</h3>
                            </div>
                            <div className={`MAbox2${item}`}>

                            </div>
                        </div>
                    ))
                }


                {/* <div className="MAbox">
                    <div className="MAbox1">
                        <h3>1</h3>
                    </div>
                    <div className="MAbox2">

                    </div>
                </div> */}

            </div>

        </div>
    </div>

    <div class="MAremarks">
        <div class="MAcorrect-wrong-picture">
            <img id="MAremarksId" src={GoodImage} ref={GoodImageRef} alt="" style={{ width: '100px', display: 'none'}}/>
            <img id="remarksId" src={CrossImage} ref={CrossImageRef} alt="" style={{ width: '100px', display: 'none'}}/>
        </div>

        <div class="MAfinished-picture">
            <img id="finishedId" src={FinishedImage} ref={FinishedImageRef} alt="" style={{ width: '100px', display: 'none'}}/>
        </div>
    </div>
    <Footer/>

    </div>
  )
}

export default WrittenActivity;
