import React, { useEffect, useState } from 'react';
import axios from 'axios';

import {Link} from 'react-router-dom'
import Logo from '../CourseImages/Logo.png'
import './Nav.css'
import Select from 'react-select';

const AttendClassModal = ({ isOpen, onClose, classes, selectedCourse }) => {
  
  // console.log('classes:',classes);
  // console.log('selectedCourse:',selectedCourse);
    const selectedCourseData = classes.find((course) => course.Course_id === selectedCourse) || {};
    const [selectedCourseDataById,setSelectedCourseDataById] =useState({});

    // const classes1 = selectedCourseData.classes;
    // console.log('classes1:',classes1)
    
// const combinedClasses = classes1.reduce((acc, curr) => {
//   const existingClass = acc.find(item => item.Class_id === curr.Class_id);
//   if (existingClass) {
//     // If class already exists, update the day and time format
//     existingClass.ClassDay += ` - ${curr.ClassDay}`;
//     existingClass.ClassTime += ` - ${curr.ClassTime}`;
//     // Add classSchedule field dynamically
//     // existingClass.classSchedule = `${existingClass.ClassDay} ${existingClass.ClassTime}`;
//   } else {
//     // If class doesn't exist, add it to the accumulator with classSchedule field
//     const newClass = { ...curr };
//     // Add classSchedule field dynamically
//     newClass.classSchedule = `${curr.ClassDay} ${curr.ClassTime}`;
//     acc.push(newClass);
//   }
//   return acc;
// }, []);

// // // Update selectedCourseData with the combined classes
// selectedCourseData.classes = combinedClasses;
//     console.log('selectedCourseData:',selectedCourseData)

    const [selectedClass, setSelectedClass] = useState('');  
    const [selectedClassLink, setSelectedClassLink] = useState('');

    const handleClassSelection = (classId) => {
      const selectedClassItem = selectedCourseData.classes.find((classItem) => classItem.Class_id == classId);
      setSelectedCourseDataById(selectedClassItem?.Class_id)
      if (selectedClassItem) {setSelectedClassLink(selectedClassItem.ClassLink);}
      console.log("classId: ",classId)
      setSelectedClass(classId);
    };
  
    const handleAttendClass = () => {
    // console.log("selectedCourseData:",selectedCourseData)
      onClose();
    };
    // Deduplicate classes and generate options for the dropdown
  const options = [...new Map(
    (selectedCourseData.classes || []).map(classItem => [
      classItem.Class_id, // Use Class_id as the key to ensure uniqueness
      {
        value: classItem.Class_id,
        label: `${selectedCourseData.coursename} - ${classItem.ClassID} - ${classItem.ClassDay} ${classItem.ClassTime}`,
      },
    ])
  ).values()];
  
    // const options = selectedCourseData.classes.map((classItem) => ({
    //   value: classItem.Class_id,
    //   label: `${selectedCourseData.coursename} - ${classItem.ClassID} - ${classItem.ClassDay} ${classItem.ClassTime}`,
    // }));
    const customStyles = {
      container: (provided) => ({
        ...provided,
        width: '100%', // Adjust the container width as needed
      }),
      control: (provided) => ({
        ...provided,
        width: '100%', // Adjust the control width as needed
      }),
      menu: (provided) => ({
        ...provided,
        width: 'auto', // Set the dropdown menu width to auto
        minWidth: '300px', // Minimum width for the dropdown menu
        maxWidth: '600px', // Maximum width for the dropdown menu
      }),
      option: (provided) => ({
        ...provided,
        whiteSpace: 'wrap', // Prevent text wrapping
        overflow: 'hidden', // Hide overflowing text
        textOverflow: 'ellipsis', // Show ellipsis for overflowing text
      }),
    };
    return (
      <div className={`modal ${isOpen ? 'open' : ''}`}>
        <div className="modal-overlay" onClick={onClose}></div>
        <div className="modal-content">
          <h3>{selectedCourseData.coursename}</h3>
          {/* <select
            value={selectedClass}
            onChange={(e) => handleClassSelection(e.target.value)}
            className='AttendClassDropdown'
          >
            <option value="" disabled>
              Select a class
            </option>
            {selectedCourseData.classes &&
              selectedCourseData.classes.map((classItem) => (
                <option key={classItem.Class_id} value={classItem.Class_id} style={{color:"black",fontSize:"2vh"}}>
                  {selectedCourseData.coursename} - {classItem.ClassID} - {classItem.ClassDay}  {classItem.ClassTime}
                </option>
              ))}
          </select> */}
          <Select
            styles={customStyles}
            value={options.find(option => option.value === selectedClass)}
            onChange={(selectedOption) => handleClassSelection(selectedOption.value)}
            options={options}
            className='AttendClassDropdown'
          />
          <Link
            to={{
              pathname: "/TeacherAttendClass",
              search: `?selectedCourseData=${encodeURIComponent(JSON.stringify(selectedCourseData))}&selectedClass=${selectedClassLink}&selectedClassId=${selectedCourseDataById}`,
            }}
            onClick={handleAttendClass}
            // disabled={!selectedClass}
            style={{pointerEvents: selectedClass ? '' : 'none'}}
            className='AttendClassButton'
          >
            Attend Class
          </Link>
        </div>
      </div>
    );
  };
  
export default AttendClassModal
